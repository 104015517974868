import { useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CloudApi from 'src/api/CloudApi'
import { MediaFile } from 'src/api/CloudApi/types'
import { DeleteIcon, DownloadIcon, PlayIcon } from 'src/assets/Icons'
import ViewFileModal from 'src/components/ViewFileModal'
import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { UserRecordingSessionContext } from 'src/types/Context'
import { hasPermission, Permission } from 'src/utils/permission'
import {
    createAnalyticsTrackingKey,
    ProductAnalyticsProperties,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from 'src/utils/ProductAnalytics'
import { formattedToastMessage } from 'src/utils/toast'

interface MediaFileListItemProps {
    mediaFile: MediaFile
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties

    openConfirmationDialog: Function
    refreshRecordingSession: Function
}

const PLAYABLE_MIME_TYPES = ['video/quicktime', 'video/mp4']
const DOWNLOADABLE_MIME_TYPES = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/webp',
    'application/zip',
    'application/x-ipynb+json',
]

export function MediaFileListItem(props: MediaFileListItemProps) {
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(false)
    const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false)

    const [showViewFileModal, setShowViewFileModal] = useState<boolean>(false)
    const fileToView = useRef<MediaFile>()

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.userRecordingSessionContext.currentUser,
        billableUnit: props.userRecordingSessionContext.currentBillableUnit,
    } as ProductAnalyticsProps)

    const AnalyticsActions = {
        UPLOAD_MEDIA_FILE: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'UploadMediaFiles'
        ),
        DOWNLOAD_MEDIA_FILE: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'DownloadMediaFiles'
        ),
        DELETE_MEDIA_FILE: createAnalyticsTrackingKey(
            props.productAnalyticsProperties.productAnalyticsContext,
            'DeleteMediaFiles'
        ),
    }

    const openMediaFile = async (mediaFile: MediaFile) => {
        try {
            setIsLoadingDownload(true)
            const { downloadUrl } = (
                await CloudApi.getMediaFile(
                    props.userRecordingSessionContext.currentProject.uid,
                    props.userRecordingSessionContext.currentRecordingSession.sessionId,
                    mediaFile
                )
            ).data
            window.open(downloadUrl, '_blank')
        } catch (err) {
            console.error(err)
            toast.error(
                formattedToastMessage(
                    'File error',
                    `Unexpected file error when trying to download the media file ${mediaFile.displayName}.`
                )
            )
        }
        setIsLoadingDownload(false)
    }

    const deleteMediaFile = async (mediaFile: MediaFile) => {
        try {
            setIsDeleting(true)
            await CloudApi.deleteMediaFile(
                props.userRecordingSessionContext.currentProject.uid,
                props.userRecordingSessionContext.currentRecordingSession.sessionId,
                mediaFile
            )
            await props.refreshRecordingSession() // Fetch recording session again with the updated details
            toast.success(`Successfully deleted media file ${mediaFile.displayName}`)
        } catch (err) {
            toast.error(`Failed to delete ${mediaFile.displayName}`)
        }
        setIsDeleting(false)
    }

    const playMediaFile = async (mediaFile: MediaFile) => {
        try {
            setIsLoadingPreview(true)
            const file = (
                await CloudApi.getMediaFile(
                    props.userRecordingSessionContext.currentProject.uid,
                    props.userRecordingSessionContext.currentRecordingSession.sessionId,
                    mediaFile
                )
            ).data
            fileToView.current = file
            setShowViewFileModal(true)
        } catch (err) {
            toast.error(`Failed to download and play ${mediaFile.displayName}`)
        }
        setIsLoadingPreview(false)
    }

    const downloadMediaFile = async (mediaFile: MediaFile) => {
        try {
            setIsLoadingDownload(true)
            const { downloadUrl } = (
                await CloudApi.getMediaFile(
                    props.userRecordingSessionContext.currentProject.uid,
                    props.userRecordingSessionContext.currentRecordingSession.sessionId,
                    mediaFile
                )
            ).data
            window.open(downloadUrl, '_blank')
        } catch (err) {
            toast.error(`Failed to download ${mediaFile.displayName}`)
        }
        setIsLoadingDownload(false)
    }

    const component = () => {
        return (
            <>
                <div className="d-flex rounded remotive-primary-10-background m-1">
                    <div className="col-7 px-2 pt-1 pb-1">
                        <div className="border-end h-100">
                            <p className="m-0 remotive-font-md text-truncate">{props.mediaFile.displayName}</p>
                        </div>
                    </div>
                    <div className="col-3 col-md-2 px-2 pt-1 pb-1">
                        <div className="border-end h-100">
                            <p className="m-0 remotive-font-md text-truncate">File size</p>
                            <p className="m-0 text-secondary remotive-font-sm text-truncate">{`${
                                Math.round((props.mediaFile.sizeInBytes / 1000 + Number.EPSILON) * 10) / 10
                            } KB`}</p>
                        </div>
                    </div>
                    <div className="col-3 col-md-2 d-none d-md-inline-block px-2 pt-1 pb-1">
                        <div className="border-end h-100">
                            <p className="m-0 remotive-font-md">Media type</p>
                            <p className="m-0 text-secondary remotive-font-sm">{props.mediaFile.mimeType}</p>
                        </div>
                    </div>
                    <div className="col-2 col-md-1 d-flex justify-content-center align-items-center p-1 remotive-primary-60-color">
                        {PLAYABLE_MIME_TYPES.includes(props.mediaFile.mimeType) ? (
                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_RECORDING,
                                        props.userRecordingSessionContext.currentBillableUnit,
                                        props.userRecordingSessionContext.currentProject
                                    )
                                }
                                onClick={() => playMediaFile(props.mediaFile)}
                            >
                                {isLoadingPreview ? (
                                    <>
                                        <Spinner size="sm" style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }} />
                                    </>
                                ) : (
                                    <>
                                        <PlayIcon sx={{ fontSize: 20 }} />
                                    </>
                                )}
                            </button>
                        ) : (
                            <></>
                        )}
                        {DOWNLOADABLE_MIME_TYPES.includes(props.mediaFile.mimeType) ? (
                            <button
                                className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_RECORDING,
                                        props.userRecordingSessionContext.currentBillableUnit,
                                        props.userRecordingSessionContext.currentProject
                                    )
                                }
                                onClick={() => {
                                    if (props.mediaFile.preferDownload) {
                                        productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_MEDIA_FILE)
                                        downloadMediaFile(props.mediaFile)
                                    } else {
                                        productAnalyticsClient.track(AnalyticsActions.DOWNLOAD_MEDIA_FILE)
                                        openMediaFile(props.mediaFile)
                                    }
                                }}
                            >
                                {isLoadingDownload ? (
                                    <>
                                        <Spinner size="sm" style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }} />
                                    </>
                                ) : (
                                    <>
                                        <DownloadIcon sx={{ fontSize: 20 }} />
                                    </>
                                )}
                            </button>
                        ) : (
                            <></>
                        )}
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 px-0 m-0'}
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.userRecordingSessionContext.currentBillableUnit,
                                    props.userRecordingSessionContext.currentProject
                                )
                            }
                            onClick={() =>
                                props.openConfirmationDialog({
                                    dialogTitle: 'Are you sure?',
                                    bodyText: (
                                        <>
                                            Are you sure you want to delete the media file{' '}
                                            <b>{props.mediaFile.displayName}?</b>
                                        </>
                                    ),
                                    bodySubtitle: 'You can not undo this action.',
                                    confirmButtonText: 'Yes, delete it',
                                    cancelButtonText: 'No, cancel',
                                    handleCancelFunction: () => console.log,
                                    handleConfirmFunction: () => {
                                        productAnalyticsClient.track(AnalyticsActions.DELETE_MEDIA_FILE)
                                        deleteMediaFile(props.mediaFile)
                                    },
                                } as ConfirmDialogProperties)
                            }
                        >
                            {isDeleting ? (
                                <>
                                    <Spinner size="sm" style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }} />
                                </>
                            ) : (
                                <>
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </>
                            )}
                        </button>
                    </div>
                </div>
                <ViewFileModal
                    onVideoLoadedCallback={() => setIsLoadingPreview(false)}
                    show={showViewFileModal}
                    handleCloseFunction={() => setShowViewFileModal(false)}
                    fileToView={fileToView.current}
                />
            </>
        )
    }

    return component()
}
