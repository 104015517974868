import { ChangeEventHandler } from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { SearchIcon } from '../assets/Icons'

interface SearchInputProps {
    searchKey?: string | undefined
    searchOnChange: ChangeEventHandler<HTMLInputElement>
    searchStatus?: string
    className?: string
    size?: 'sm' | 'lg'
    placeholder?: string
    position?: 'start' | 'center'
    style?: Object
}

export default function SearchInput(props: SearchInputProps) {
    return (
        <div
            className={`text-start d-flex justify-content-${props.position === 'start' ? 'start' : 'center'} ${
                props.size === 'sm' ? 'my-1' : 'my-3 '
            } ${props.className}`}
        >
            <div className="d-flex flex-column w-100" style={{ maxWidth: 700, ...props.style }}>
                <div>
                    <InputGroup className="">
                        <div
                            className="d-flex flex-row justify-content-center w-100"
                            style={{ height: props.size === 'sm' ? 25 : 36 }}
                        >
                            <Form.Control
                                style={{ zIndex: 1 }}
                                className={`rounded-start-5 rounded-0 px-3 border-1 border-end-0 shadow-sm remotive-white-background remotive-dark-color dark-and-light-placeholder ${
                                    props.size === 'sm' ? 'remotive-font-sm' : ''
                                }`}
                                placeholder={props.placeholder ?? "Search..."}
                                value={props.searchKey}
                                aria-label="Small"
                                aria-describedby="inputGroup-sizing-sm"
                                onChange={props.searchOnChange}
                            />
                            <InputGroup.Text
                                style={{ zIndex: 0 }}
                                className="remotive-white-background rounded-end-5 rounded-0 border-1 border-start-0 shadow-sm remotive-primary-100-color"
                            >
                                <SearchIcon className="" />
                            </InputGroup.Text>
                        </div>
                    </InputGroup>
                </div>
                <div className="ms-3">
                    <p className={`m-0 remotive-font-xs remotive-secondary-color`}>
                        {props.searchStatus && <>{props.searchStatus}</>}
                    </p>
                </div>
            </div>
        </div>
    )
}
