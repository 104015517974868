import { useEffect, useState } from 'react'
import { Dropdown, Form, InputGroup, Modal, Spinner } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { Project, UserBillableUnitInfo, UserInfoWithRoles } from '../../api/CloudApi/types'
import { ErrorIcon } from '../../assets/Icons'
import { toast } from 'react-toastify'
import { NavLink } from 'react-router-dom'
import { OrganisationRoutes } from '../../Routes'
import { hasPermission, Permission } from '../../utils/permission'
import ProjectUsersComboBox from './ProjectUsersComboBox'
import { formattedToastMessage } from 'src/utils/toast'

interface CreateProjectUserModalProps {
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    currentBuUsers: Array<UserInfoWithRoles>
    currentProjectUsers: Array<UserInfoWithRoles>
    show: boolean
    handleCloseFunction: () => void
    reloadUsersList: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-modal-header-bg'
const MODAL_THEME_COLOR = 'text-light'

const OWNER_ROLE = 'project/admin'
const EDITOR_ROLE = 'project/user'
const VIEWER_ROLE = 'project/viewer'

export default function CreateProjectUserModal(props: CreateProjectUserModalProps) {
    const [selectedRole, setSelectedRole] = useState<'project/admin' | 'project/user' | 'project/viewer'>(VIEWER_ROLE)
    const [selectedUser, setSelectedUser] = useState<UserInfoWithRoles>()

    const [userCreationState, setUserCreationState] = useState<'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'>(
        'NOT_STARTED'
    )

    useEffect(() => {
        console.debug('Mounted edit project user modal!')
    }, [])

    useEffect(() => {
        if (userCreationState === 'DONE') {
            closeModal()
            props.reloadUsersList()
        }
    }, [userCreationState])

    const validateInput = (): boolean => {
        if (selectedUser === undefined) {
            toast.error(formattedToastMessage('Input error', 'You must enter a valid email for the user to add.'))
            return false
        }
        if (selectedRole === undefined) {
            toast.error(formattedToastMessage('Input error', 'You must select a role for the user.'))
            return false
        }
        return true
    }

    const resetState = () => {
        setSelectedUser(undefined)
        setSelectedRole(VIEWER_ROLE)
        setUserCreationState('NOT_STARTED')
    }

    const createProjectUser = async () => {
        const projectUid = props.currentProject?.uid
        if (validateInput() && selectedUser && selectedRole && projectUid) {
            setUserCreationState('IN_PROGRESS')
            const userInfo = { user: selectedUser.user, roles: [selectedRole] } as UserInfoWithRoles
            try {
                await CloudApi.editProjectUser(projectUid, userInfo)
                setUserCreationState('DONE')
            } catch (err) {
                setUserCreationState('ERROR')
                toast.error('Failed to save user')
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const rolesList = () => {
        return (
            <>
                <div>
                    <Form.Check
                        type="radio"
                        checked={selectedRole === OWNER_ROLE}
                        className="remotive-font-md"
                        label="Owner"
                        onChange={() => setSelectedRole(OWNER_ROLE)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm remotive-secondary-color mb-2">
                        {' '}
                        Full project access, user management access and can delete projects. Note that the user need to
                        have the role as "Project Admin" in this organisation to be able to be owner of a project.
                    </p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === EDITOR_ROLE}
                        label="Editor"
                        onChange={() => setSelectedRole(EDITOR_ROLE)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm remotive-secondary-color mb-2">Full access to manage project resources</p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === VIEWER_ROLE}
                        label="Viewer"
                        onChange={() => setSelectedRole(VIEWER_ROLE)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm remotive-secondary-color mb-2">
                        Can start/stop brokers, view all resources but not edit or delete any resources
                    </p>
                </div>
            </>
        )
    }

    const renderUserInfoText = () => {
        return (
            <>
                {' '}
                To add a user to a project the user must belong to your organisation.&nbsp;
                {hasPermission(Permission.ORG_EDITOR_USER, props.currentBillableUnit, props.currentProject) && (
                    <>
                        Go&nbsp;
                        <NavLink to={OrganisationRoutes.toUsers(props.currentBillableUnit?.organisation?.uid)}>
                            here
                        </NavLink>
                        &nbsp;to add a user to your organisation.
                    </>
                )}
                {!hasPermission(Permission.ORG_EDITOR_USER, props.currentBillableUnit, props.currentProject) && (
                    <>Ask an organisation administrator if the user does not already exist.</>
                )}
                <hr className={'mb-1'} />
            </>
        )
    }

    const getModalContent = () => {
        const title = 'Add user to project'
        switch (userCreationState) {
            case 'IN_PROGRESS':
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Adding user...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case 'ERROR':
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue adding the user...</p>
                                <p className="remotive-font-sm remotive-secondary-color" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-4">
                                <p className="remotive-font-sm p-2">{renderUserInfoText()}</p>
                                <ProjectUsersComboBox
                                    selectedUser={selectedUser}
                                    setSelectedUser={setSelectedUser}
                                    currentProjectUsers={props.currentProjectUsers}
                                    currentBuUsers={props.currentBuUsers}
                                />
                            </div>
                            <div className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        <p className="h-100">Role</p>
                                    </InputGroup.Text>
                                    <div className="w-75">{rolesList()}</div>
                                </InputGroup>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn remotive-btn remotive-btn-success align-center"
                                    onClick={() => createProjectUser()}
                                >
                                    <div className="d-flex align-items-center text-light">
                                        <p className="text-light m-0">Save</p>
                                    </div>
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
