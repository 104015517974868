import { useState } from 'react'
import {
    AuthenticatedUser,
    Project,
    RecordingFile,
    RecordingSession,
    SignalDatabase,
    SourceRecordingFile,
    UserBillableUnitInfo,
} from '../../../api/CloudApi/types'
import CloudApi from '../../../api/CloudApi'
import { toast } from 'react-toastify'
import { PageState } from '../../../types/PageState'
import { DeleteIcon, DownloadIcon } from '../../../assets/Icons'
import { hasPermission, Permission } from '../../../utils/permission'
import { Card } from 'react-bootstrap'
import CliHintContainer, { CliHint } from '../../../components/CliHintContainer'
import EditRecordingConfigurationModal from './RecordingFilesSection/EditRecordingConfigurationModal'
import RecordingFileListItem from '../../../components/RecordingFileListItem'
import { ConfirmDialogProperties } from '../../../types/ConfirmDialogProperties'
import { ProductAnalyticsContext, ProductAnalyticsProperties } from '../../../utils/ProductAnalytics'
import MediaFilesSection from './MediaFilesSection'
import { TransformationFilesSection } from './TransformationFilesSection'
import { UserRecordingSessionContext } from 'src/types/Context'
import SourceRecordingFilesSection from './SourceRecordingFilesSection'
import RecordingFilesSection from './RecordingFilesSection'

interface FilesTabProps {
    currentRecording: RecordingSession | undefined
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentUser: AuthenticatedUser | undefined
    setPageState: (pageState: PageState) => void
    refreshRecordingSession: Function
    setLoadingPercent: (percent: number) => void
    availableSignalDatabases: Array<SignalDatabase>
    allNamespaces: Array<string>
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
    recordingRequiresConfiguration: boolean
}

export function FilesTab(props: FilesTabProps) {
    const recordingFilesSection = () => {
        return (
            <RecordingFilesSection
                userRecordingSessionContext={
                    {
                        currentUser: props.currentUser,
                        currentBillableUnit: props.currentBillableUnit,
                        currentProject: props.currentProject,
                        currentRecordingSession: props.currentRecording,
                    } as UserRecordingSessionContext
                }
                availableSignalDatabases={props.availableSignalDatabases}
                allNamespaces={props.allNamespaces}
                refreshRecordingSession={props.refreshRecordingSession}
                openConfirmationDialog={props.openConfirmationDialog}
            />
        )
    }

    const sourceFilesSection = () => {
        return (
            <SourceRecordingFilesSection
                productAnalyticsProperties={
                    {
                        productAnalyticsContext: ProductAnalyticsContext.RD_FILES_TAB,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    } as ProductAnalyticsProperties
                }
                userRecordingSessionContext={
                    {
                        currentUser: props.currentUser,
                        currentBillableUnit: props.currentBillableUnit,
                        currentProject: props.currentProject,
                        currentRecordingSession: props.currentRecording,
                    } as UserRecordingSessionContext
                }
                refreshRecordingSession={props.refreshRecordingSession}
                openConfirmationDialog={props.openConfirmationDialog}
            />
        )
    }

    const cliHints = () => {
        const hints: Array<CliHint> = []
        //remotive cloud recordings download-configuration
        const downloadRecordingFileHints: Array<CliHint> =
            props.currentRecording?.recordings.map((recordingFile) => {
                return {
                    title: `Download recording file ${recordingFile.fileName}`,
                    command: `remotive cloud recordings download-recording-file '${recordingFile.fileName}'  --recording-session ${props.currentRecording?.sessionId} --project ${props.currentProject?.uid}`,
                }
            }) || []

        const downloadConfigHints: Array<CliHint> =
            props.currentRecording?.brokerConfigurations.map((folder) => {
                return {
                    title: `Download transformation ${folder.name} as zip`,
                    command: `remotive cloud recordings download-broker-configuration '${folder.name}'  --recording-session ${props.currentRecording?.sessionId} --project ${props.currentProject?.uid}`,
                }
            }) || []

        hints.push(...downloadRecordingFileHints)
        hints.push(...downloadConfigHints)
        return hints
    }

    const transformationFilesSection = () => {
        return (
            <TransformationFilesSection
                productAnalyticsProperties={
                    {
                        productAnalyticsContext: ProductAnalyticsContext.RD_FILES_TAB,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    } as ProductAnalyticsProperties
                }
                userRecordingSessionContext={
                    {
                        currentUser: props.currentUser,
                        currentBillableUnit: props.currentBillableUnit,
                        currentProject: props.currentProject,
                        currentRecordingSession: props.currentRecording,
                    } as UserRecordingSessionContext
                }
                refreshRecordingSession={props.refreshRecordingSession}
                openConfirmationDialog={props.openConfirmationDialog}
                recordingRequiresFurtherConfiguration={props.recordingRequiresConfiguration}
            />
        )
    }

    const mediaFilesSection = () => {
        return (
            <MediaFilesSection
                productAnalyticsProperties={
                    {
                        productAnalyticsContext: ProductAnalyticsContext.RD_FILES_TAB,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    } as ProductAnalyticsProperties
                }
                userRecordingSessionContext={
                    {
                        currentUser: props.currentUser,
                        currentBillableUnit: props.currentBillableUnit,
                        currentProject: props.currentProject,
                        currentRecordingSession: props.currentRecording,
                    } as UserRecordingSessionContext
                }
                refreshRecordingSession={props.refreshRecordingSession}
                openConfirmationDialog={props.openConfirmationDialog}
            />
        )
    }

    const filesContainer = () => {
        if (
            props.currentRecording !== undefined &&
            props.currentProject !== undefined &&
            props.currentBillableUnit !== undefined &&
            props.currentUser !== undefined
        ) {
            return (
                <>
                    <div className="mt-3 mb-3">
                        <div className="px-2 mb-5">{recordingFilesSection()}</div>
                        {props.currentRecording.sourceRecordingFiles.length > 0 && (
                            <>
                                <div className="px-2 mb-5">{sourceFilesSection()}</div>
                            </>
                        )}
                        <div className="px-2 mb-5">{transformationFilesSection()}</div>
                        <div className="px-2">{mediaFilesSection()}</div>
                    </div>
                </>
            )
        }
    }

    return (
        <>
            <Card className="shadow-sm rounded-4 remotive-white-background border-0 text-start mb-1" style={{ minHeight: '300px' }}>
                <Card.Body className="px-1 py-0">{filesContainer()}</Card.Body>
            </Card>
            <CliHintContainer
                productAnalyticsProperties={{
                    currentBillableUnit: props.currentBillableUnit,
                    currentUser: props.currentUser,
                    productAnalyticsContext: ProductAnalyticsContext.RD_FILES_TAB,
                }}
                hints={cliHints()}
            />
        </>
    )
}
