import { UserRecordingSessionContext } from 'src/types/Context'
import SourceRecordingFileListItem from './SourceRecordingFileListItem'
import { ProductAnalyticsProperties } from 'src/utils/ProductAnalytics'

interface SourceRecordingFilesSectionProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties
    openConfirmationDialog: Function
    refreshRecordingSession: Function
}

export default function SourceRecordingFilesSection(props: SourceRecordingFilesSectionProps) {
    const sourceRecordingFileList = () => {
        return props.userRecordingSessionContext.currentRecordingSession.sourceRecordingFiles.map(
            (sourceRecordingFile) => {
                return (
                    <div key={sourceRecordingFile.fileName}>
                        <SourceRecordingFileListItem
                            sourceRecordingFile={sourceRecordingFile}
                            userRecordingSessionContext={props.userRecordingSessionContext}
                            openConfirmationDialog={props.openConfirmationDialog}
                            refreshRecordingSession={props.refreshRecordingSession}
                        />
                    </div>
                )
            }
        )
    }

    const component = () => {
        return (
            <>
                <p className="fs-6 mb-0">
                    <b>Source recording files</b>
                </p>
                <p className="mb-0 remotive-font-sm remotive-secondary-color">
                    These files are the source recording files that have been uploaded to this recording session. Only
                    non-RemotiveBroker recording files will show up in this list.
                </p>
                {sourceRecordingFileList()}
            </>
        )
    }

    return component()
}
