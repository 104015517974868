import { useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import CloudApi from 'src/api/CloudApi'
import { SourceRecordingFile } from 'src/api/CloudApi/types'
import { DeleteIcon, DownloadIcon } from 'src/assets/Icons'
import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { UserRecordingSessionContext } from 'src/types/Context'
import { hasPermission, Permission } from 'src/utils/permission'

interface SourceRecordingFileListItemProps {
    sourceRecordingFile: SourceRecordingFile
    userRecordingSessionContext: UserRecordingSessionContext
    openConfirmationDialog: Function
    refreshRecordingSession: Function
}

export default function SourceRecordingFileListItem(props: SourceRecordingFileListItemProps) {
    const [isDeleting, setIsDeleting] = useState<boolean>(false)
    const [isPreparingToDownload, setIsPreparingToDownload] = useState<boolean>(false)

    const deleteSourceRecordingFile = async (mediaFile: SourceRecordingFile) => {
        try {
            setIsDeleting(true)
            await CloudApi.deleteSourceRecordingFile(
                props.userRecordingSessionContext.currentProject.uid,
                props.userRecordingSessionContext.currentRecordingSession.sessionId,
                mediaFile
            )
            await props.refreshRecordingSession() // Fetch recording session again with the updated details
            toast.success(`Successfully deleted source recording file ${mediaFile.displayName}`)
        } catch (err) {
            toast.error(`Failed to delete ${mediaFile.displayName}`)
        }
        setIsDeleting(false)
    }

    const downloadSourceRecordingFile = async (sourceRecordingFile: SourceRecordingFile) => {
        try {
            setIsPreparingToDownload(true)
            const { downloadUrl } = (
                await CloudApi.getSourceRecordingFile(
                    props.userRecordingSessionContext.currentProject.uid,
                    props.userRecordingSessionContext.currentRecordingSession.sessionId,
                    sourceRecordingFile
                )
            ).data
            window.open(downloadUrl, '_blank')
        } catch (err) {
            toast.error(`Failed to download ${sourceRecordingFile.displayName}`)
        }
        setIsPreparingToDownload(false)
    }

    const component = () => {
        return (
            <>
                <div className="d-flex rounded remotive-primary-10-background m-1">
                    <div className="col-8 px-2 pt-1 pb-1">
                        <div className="border-end h-100">
                            <p className="m-0 remotive-font-md text-truncate">
                                {props.sourceRecordingFile.displayName}
                            </p>
                        </div>
                    </div>
                    <div className="col-2 col-md-3 px-2 pt-1 pb-1 flex-truncate">
                        <div className="border-end h-100 flex-truncate">
                            <p className="m-0 remotive-font-md text-truncate">File size</p>
                            <p className="m-0 me-1 remotive-secondary-color remotive-font-sm text-truncate">{`${
                                Math.round((props.sourceRecordingFile.sizeInBytes / 1000 + Number.EPSILON) * 10) / 10
                            } KB`}</p>
                        </div>
                    </div>
                    <div className="col-2 col-md-1 d-flex justify-content-center align-items-center p-1 remotive-primary-60-color">
                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_VIEWER_RECORDING,
                                    props.userRecordingSessionContext.currentBillableUnit,
                                    props.userRecordingSessionContext.currentProject
                                )
                            }
                            onClick={() => {
                                downloadSourceRecordingFile(props.sourceRecordingFile)
                            }}
                        >
                            {isPreparingToDownload ? (
                                <>
                                    <Spinner
                                        size="sm"
                                        style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }}
                                    />
                                </>
                            ) : (
                                <>
                                    <DownloadIcon sx={{ fontSize: 20 }} />
                                </>
                            )}
                        </button>

                        <button
                            className={'btn remotive-btn-sm remotive-btn-no-bg p-0 py-1 m-0'}
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.userRecordingSessionContext.currentBillableUnit,
                                    props.userRecordingSessionContext.currentProject
                                )
                            }
                            onClick={() =>
                                props.openConfirmationDialog({
                                    dialogTitle: 'Are you sure?',
                                    bodyText: (
                                        <>
                                            Are you sure you want to delete the source recording file{' '}
                                            <b>{props.sourceRecordingFile.displayName}?</b>
                                        </>
                                    ),
                                    bodySubtitle: 'You can not undo this action.',
                                    confirmButtonText: 'Yes, delete it',
                                    cancelButtonText: 'No, cancel',
                                    handleCancelFunction: () => console.log,
                                    handleConfirmFunction: () => deleteSourceRecordingFile(props.sourceRecordingFile),
                                } as ConfirmDialogProperties)
                            }
                        >
                            {isDeleting ? (
                                <>
                                    <Spinner
                                        size="sm"
                                        style={{ height: 13, width: 13, marginRight: 4, marginLeft: 3 }}
                                    />
                                </>
                            ) : (
                                <>
                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                </>
                            )}
                        </button>
                    </div>
                </div>
            </>
        )
    }

    return component()
}
