import { NavLink } from 'react-router-dom'
import { ProjectRoutes } from '../Routes'
import { ExternalLinkIcon } from '../assets/Icons'
import NotFoundContaner from './NotFoundContainer'

type EmptyRecentSignalDatabaseContainerProps = {
    projectUid: string
}
export default function EmptyRecentSignalDatabaseContainer(props: EmptyRecentSignalDatabaseContainerProps) {
    return (
        <div className="mb-3">
            <NotFoundContaner
                infoText="You have not viewed any signal databases."
                secondaryText="Recently viewed history is stored in this browser only."
                margin='my-3'
            />
            <div className="d-flex justify-content-center">
                <button className="btn remotive-btn remotive-btn-md remotive-btn-primary me-2">
                    <NavLink
                        to={ProjectRoutes.toSignalDatabases(props.projectUid)}
                        className={'text-light text-decoration-none'}
                    >
                        <div className="d-flex align-items-center">
                            <p className="m-0">View Signal Databases</p>
                        </div>
                    </NavLink>
                </button>
                <button className="btn remotive-btn remotive-btn-md remotive-btn-primary">
                    <NavLink
                        to="https://docs.remotivelabs.com/docs/cloud/Project/SignalDatabases"
                        target="_blank"
                        className={'text-light text-decoration-none'}
                    >
                        <div className="d-flex align-items-center">
                            <p className="m-0 me-2">View Documentation</p>
                            <ExternalLinkIcon sx={{ fontSize: 15 }} />
                        </div>
                    </NavLink>
                </button>
            </div>
        </div>
    )
}
