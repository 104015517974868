import {
    CloseRounded,
    DeleteRounded,
} from '@mui/icons-material'
import { FileObject } from 'src/api/CloudApi/types'
import { DownloadIcon } from 'src/assets/Icons'

interface ActionBarProps {
    selectedFiles: Set<FileObject>
    onSelectAll: () => void
    onReset: Function
    onDeleteSelected: Function
    onDownloadSelected: Function
}

export function ActionBar(props: ActionBarProps) {
    const toolbarButton = (icon: JSX.Element, text: string, onClick: Function) => {
        return (
            <button
                className="btn remotive-btn-sm remotive-btn-no-bg border-0 d-flex align-items-center"
                disabled={props.selectedFiles.size <= 0}
                onClick={() => onClick()}
            >
                <div>{icon}</div>
                <p className="m-0 ms-1">{text}</p>
            </button>
        )
    }

    const component = () => {
        return (
            <>
                <div className="rounded-3 remotive-primary-10-background px-2" style={{paddingBottom: 1}}>
                    <div className="d-flex flex-row align-items-center">
                    {props.selectedFiles.size > 0 && (
                            <button
                                className="btn remotive-btn-sm remotive-btn-no-bg border-0 d-flex align-items-center me-2 p-0"
                                onClick={() => props.onReset()}
                            >
                                <div>
                                    <CloseRounded sx={{ fontSize: 20 }} />
                                </div>
                            </button>
                        )}
                        <button
                            className="btn remotive-btn-sm remotive-btn-primary border-0 d-flex align-items-center py-1 me-4 ms-0"
                            onClick={() => props.onSelectAll()}
                        >
                            <p className="m-0 text-nowrap">Select all</p>
                        </button>

                        {props.selectedFiles.size > 0 && (
                            <p className="m-0 remotive-font-sm text-nowrap">{props.selectedFiles.size} selected</p>
                        )}

                        {toolbarButton(
                            <>
                                <DeleteRounded sx={{ fontSize: 18 }} />
                            </>,
                            'Delete',
                            props.onDeleteSelected
                        )}
                    </div>
                </div>
            </>
        )
    }

    return component()
}
