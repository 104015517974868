import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Modal, InputGroup, Spinner, Dropdown } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { AnnouncementIcon, ExploreIcon, ExternalLinkIcon, SuccessIcon } from '../../assets/Icons'
import { isDemo } from '../../utils/demo'
import {
    AuthenticatedUser,
    BrokerConfigurationFolder,
    BrokerContainer,
    Project,
    RecordingFile,
    RecordingSession,
} from '../../api/CloudApi/types'
import CloudApi from '../../api/CloudApi'
import { deletePlaybackBrokerUrl, setPlaybackBrokerUrl } from '../../utils/broker'
import { formattedToastMessage } from 'src/utils/toast'

interface ApplyToBrokerModalProps {
    allowSelectBroker: boolean
    brokers: Array<BrokerContainer> | undefined
    refreshBrokers: () => void
    currentProject: Project | undefined
    recordingSession: RecordingSession | undefined
    recordingFile: RecordingFile | undefined
    brokerConfigFolder: BrokerConfigurationFolder | undefined
    currentUser: AuthenticatedUser | undefined
    show: boolean
    handleCloseFunction: (brokerContainer?: BrokerContainer) => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

enum RequestState {
    NOT_STARTED,
    IN_PROGRESS,
    DONE,
}

export default function ApplyToBrokerModal(props: ApplyToBrokerModalProps) {
    const [selectedBroker, setSelectedBroker] = useState<BrokerContainer>()
    const brokerToApplyTo = useRef<BrokerContainer>()
    const [isBrokerBeingCreated, setIsBrokerBeingCreated] = useState(false)
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NOT_STARTED)
    const [brokers, setBrokers] = useState<Array<BrokerContainer>>([])
    const isShowing = useRef(false)

    useEffect(() => {
        const setBroker = async () => {
            if (isShowing.current !== props.show && props.show && props.brokers && props.recordingSession) {
                if (props.allowSelectBroker) {
                    const arbitraryBroker = props.brokers[0]
                    if (arbitraryBroker !== undefined) {
                        setSelectedBroker(arbitraryBroker)
                    } else {
                        try {
                            await createOrSetPersonalBroker()
                        } catch (error: any) {
                            closeModal()
                            toast.error('Something went wrong when trying to play this recording.')
                        }
                    }
                } else {
                    try {
                        setRequestState(RequestState.IN_PROGRESS)
                        await createOrSetPersonalBroker()
                        await applyToBroker()
                    } catch (error: any) {
                        closeModal()
                        toast.error('Something went wrong when trying to play this recording.')
                    }
                }
            }
        }
        setBroker()
        isShowing.current = props.show
    }, [props.show, props.brokers, props.allowSelectBroker])

    useEffect(() => {
        setBrokers(props.brokers || [])
    }, [props.brokers])

    useEffect(() => {
        brokerToApplyTo.current = selectedBroker
    }, [selectedBroker])

    const createPersonalBroker = async () => {
        if (props.currentProject?.uid && props.currentUser) {
            console.log('Creating personal broker...')
            const broker = await CloudApi.createPersonalBroker(props.currentProject.uid, undefined, undefined)
            return broker
        }
        return undefined
    }

    const validateInput = () => {
        if (brokerToApplyTo === undefined) {
            if (props.recordingFile !== undefined && props.recordingSession !== undefined) {
                toast.error('You must select a broker to prepare your recording session')
                return false
            }
            if (props.recordingSession !== undefined) {
                toast.error('You must select a broker to prepare your recording file')
                return false
            }
        }
        return true
    }

    const createOrSetPersonalBroker = async () => {
        const projectId = props.currentProject?.uid
        const user = props.currentUser
        if (projectId && user) {
            const personalBroker = await CloudApi.getPersonalBroker(projectId, user)
            // If no personal broker exists, we should (in most cases) create one for the user
            if (personalBroker === undefined) {
                // In demo we only allow 1 broker, so we must do this to make sure that a personal broker is not created if the user already created one
                if (isDemo() && props.brokers && props.brokers.length > 0) {
                    brokerToApplyTo.current = props.brokers[0]
                    setSelectedBroker(brokerToApplyTo.current)
                } else {
                    setIsBrokerBeingCreated(true)
                    const createdBroker = await createPersonalBroker()
                    brokerToApplyTo.current = createdBroker?.data
                    setSelectedBroker(brokerToApplyTo.current)
                    setIsBrokerBeingCreated(false)
                    props.refreshBrokers()
                }
            } else {
                brokerToApplyTo.current = personalBroker
                setSelectedBroker(brokerToApplyTo.current)
            }
            return Promise.resolve()
        }
    }

    const applyToBroker = async () => {
        if (props.currentProject?.uid) {
            setRequestState(RequestState.IN_PROGRESS)
            if (validateInput() && brokerToApplyTo.current) {
                try {
                    if (props.recordingSession !== undefined && props.recordingFile !== undefined) {
                        await CloudApi.applyRecordingFileToBroker(
                            props.currentProject.uid,
                            props.recordingSession.sessionId,
                            props.recordingFile.fileName,
                            brokerToApplyTo.current.name
                        )
                    }
                    if (props.recordingSession !== undefined) {
                        if (props.recordingSession.recordings.length <= 10) {
                            await CloudApi.applyRecordingSessionToBroker(
                                props.currentProject.uid,
                                props.recordingSession,
                                brokerToApplyTo.current.name,
                                props.brokerConfigFolder?.name === 'default' ? undefined : props.brokerConfigFolder
                            )
                            setPlaybackBrokerUrl(brokerToApplyTo.current.url)
                            closeModal(brokerToApplyTo.current)
                        } else {
                            toast.error(
                                formattedToastMessage(
                                    'Playback error',
                                    `Too many recording files to start playback, the limit is currently ${10} files. Reach out to support@remotivelabs.com for further assistance.`
                                )
                            )
                        }
                    }
                } catch (err: any) {
                    console.error(err)
                    // TODO - improve to separate even upload file and start errors from broker
                    // At least this covers client errors
                    if (err.response.status === 404) {
                        // We should always be able to use server message here
                        closeModal()
                        toast.error(
                            err.response.data ||
                                'Either the broker or the recording you are trying to play does not exist.'
                        )
                    } else if (err.response.status === 400) {
                        closeModal()
                        toast.error(
                            err.response.data ||
                                'There seems to be an error in your configuration, please check that it is valid and try again.'
                        )
                    } else {
                        closeModal()
                        toast.error('We encountered an unknown error when preparing this recording.')
                    }
                    setRequestState(RequestState.NOT_STARTED)
                }
            }
        }
        closeModal()
    }

    const closeModal = async (brokerContainer?: BrokerContainer) => {
        props.handleCloseFunction(brokerContainer)
        setSelectedBroker(undefined)
        brokerToApplyTo.current = undefined
        await new Promise((r) => setTimeout(r, 500))
        setRequestState(RequestState.NOT_STARTED)
    }

    const brokerDropdownItems = () => {
        return brokers.map((broker) => {
            return (
                <Dropdown.Item
                    key={broker.url}
                    className="text-truncate ps-2 text-truncate w-100"
                    onClick={() => setSelectedBroker(broker)}
                >
                    {broker.shortName}
                </Dropdown.Item>
            )
        })
    }

    const informationText = () => {
        if (props.recordingFile !== undefined && props.recordingSession !== undefined) {
            return (
                <>
                    <div className="d-flex mx-2 justify-content-start mb-4 rounded remotive-success-10-background p-3">
                        <div className="d-inline  me-3 h-100">
                            <AnnouncementIcon className="remotive-success-60-color" sx={{ fontSize: 35 }} />
                        </div>
                        <div className="d-inline">
                            <p className="mb-1 remotive-dark-color remotive-font-md">
                                You are about to play the recording file <b>{props.recordingFile.fileName}</b> from the
                                recording session <b>{props.recordingSession.displayName}</b>
                            </p>
                        </div>
                    </div>
                </>
            )
        }
        if (props.recordingSession !== undefined) {
            return (
                <>
                    <div className="d-flex mx-2 justify-content-start mb-4 rounded remotive-success-10-background p-3">
                        <div className="d-inline  me-3 h-100">
                            <AnnouncementIcon className="remotive-success-60-color" sx={{ fontSize: 35 }} />
                        </div>
                        <div className="d-inline overflow-y-auto" style={{maxHeight: "30vh"}}>
                            <p className="mb-1 remotive-dark-color remotive-font-md text-break">
                                You are about to play {props.recordingSession.recordings.length} recording file(s)
                                <b>
                                    {` (${props.recordingSession.recordings.map((recordingFile, index) => {
                                        if (recordingFile instanceof Object) {
                                            return index === 0
                                                ? recordingFile.displayName
                                                : ` ${recordingFile.displayName}`
                                        }
                                        return index === 0 ? recordingFile : ` ${recordingFile}`
                                    })})`}
                                </b>{' '}
                                from the recording session <b>{props.recordingSession.displayName}</b>
                            </p>
                        </div>
                    </div>
                </>
            )
        }
    }

    const brokerCreationInProgressMessage = () => {
        if (isBrokerBeingCreated) {
            return (
                <>
                    <div className={'m-2'}>
                        <div className="text-center">
                            <Spinner />
                            <p className="mb-0 remotive-font-lg">Creating personal broker...</p>
                            <p className="remotive-secondary-color remotive-font-sm">This could take a few seconds</p>
                        </div>
                    </div>
                </>
            )
        }
        return <></>
    }

    const fetchingBrokersMessage = () => {
        if (!isBrokerBeingCreated && selectedBroker?.shortName === undefined) {
            return (
                <>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <Spinner size="sm" />
                        <p className="remotive-font-sm mt-2 remotive-secondary-color">Fetching brokers...</p>
                    </div>
                </>
            )
        }
        return <></>
    }

    const brokersDropdownList = () => {
        if (selectedBroker?.shortName !== undefined && !isBrokerBeingCreated && brokers.length > 0) {
            return (
                <>
                    <InputGroup size="sm">
                        <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                            Broker
                        </InputGroup.Text>
                        <Dropdown className="remotive-font-md w-75">
                            <Dropdown.Toggle className="remotive-font-md bg-transparent remotive-dark-color border-light-gray text-truncate px-2 rounded-1 w-75 text-start">
                                {selectedBroker?.shortName}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="remotive-font-md pb-1 pt-1 w-75">
                                {brokerDropdownItems()}
                            </Dropdown.Menu>
                        </Dropdown>
                    </InputGroup>
                </>
            )
        }
        return <></>
    }

    const getModalContent = () => {
        switch (requestState) {
            case RequestState.IN_PROGRESS:
                return (
                    <>
                        <Modal.Body className="lexend-regular pt-5 p-3">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-0">Starting playback...</p>
                                <p className="remotive-font-sm remotive-secondary-color m-1">
                                    This can take up to a minute, hang on!
                                </p>
                                <Spinner className="remotive-primary-50-color my-4" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case RequestState.NOT_STARTED:
            default:
                return (
                    <>
                        <Modal.Body className="lexend-regular pt-4">
                            <div>{informationText()}</div>
                            <div className="mb-4">
                                {fetchingBrokersMessage()}
                                {brokersDropdownList()}
                                {brokerCreationInProgressMessage()}
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn remotive-btn remotive-btn-primary align-center me-1"
                                    disabled={isBrokerBeingCreated}
                                    onClick={() => closeModal()}
                                >
                                    <div className="d-flex align-items-center text-light">
                                        <p className="text-light m-0">Cancel</p>
                                    </div>
                                </button>
                                <button
                                    disabled={!selectedBroker}
                                    className="btn remotive-btn remotive-btn-success align-center"
                                    onClick={() => applyToBroker()}
                                >
                                    <div className="d-flex align-items-center text-light">
                                        <p className="text-light m-0">Play</p>
                                    </div>
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal centered keyboard={false} backdrop="static" show={props.show} onHide={() => closeModal()}>
                {getModalContent() ?? <></>}
            </Modal>
        </>
    )
}
