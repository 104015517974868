import CloudApi from '../api/CloudApi'
import { SignalDatabase, Project, UserBillableUnitInfo } from '../api/CloudApi/types'

export type RecentSignalDatabase = {
    signalDatabase: SignalDatabase
    project: Project
    billableUnit: UserBillableUnitInfo
}

const LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES = 'LS_RECENT_SIGNAL_DATABASES'
const MAX_RECENT_SIGNAL_DATABASES_TO_STORE = 15

const filterNonExistingSignalDatabases = async (signalDatabases: RecentSignalDatabase[], project: Project) => {
    const signalDatabaseNames = (await CloudApi.listSignalDatabases(project.uid)).data.map(it => it.name)

    signalDatabases.forEach((signalDatabase) => {

        if (signalDatabase.project.uid === project.uid) {
            const exists = signalDatabaseNames.some((name) => name === signalDatabase.signalDatabase.name)
            if (!exists) {
                removeRecentSignalDatabase(signalDatabase)
            }
        }
    })
}

export const getRecentSignalDatabases = async (projects: Array<Project>) => {
    try {
        const recentSignalDatabasesAsString = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES)
        if (recentSignalDatabasesAsString !== null) {
            const projectUids = projects.map((it) => it.uid)
            const recentSignalDatabases = (JSON.parse(recentSignalDatabasesAsString) as Array<RecentSignalDatabase>).filter(
                (it) => projectUids.includes(it.project.uid)
            )
            await Promise.all(projects.map(it => filterNonExistingSignalDatabases(recentSignalDatabases, it)))
            // At the moment we just want to show 3 items in the list
            return recentSignalDatabases.slice(0, 3)
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent signal databases, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES)
    }
    return undefined
}

export const storeRecentSignalDatabase = (signalDatabase: SignalDatabase, project: Project, billableUnit: UserBillableUnitInfo) => {
    try {
        const recentSignalDatabasesAsText = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES)
        const thisSignalDatabase = {
            signalDatabase: signalDatabase,
            project: project,
            billableUnit: billableUnit,
        } as RecentSignalDatabase

        if (recentSignalDatabasesAsText !== null) {
            const recentSignalDatabases = JSON.parse(recentSignalDatabasesAsText) as Array<RecentSignalDatabase>
            const signalDatabaseIsNotAlreadyInList =
                recentSignalDatabases.find(
                    (it) =>
                        it.billableUnit.organisation.uid === billableUnit.organisation.uid &&
                        it.project.uid === project.uid &&
                        it.signalDatabase.name === signalDatabase.name
                ) === undefined
            if (signalDatabaseIsNotAlreadyInList) {
                recentSignalDatabases.unshift(thisSignalDatabase)
                localStorage.setItem(
                    LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES,
                    JSON.stringify(recentSignalDatabases.slice(0, MAX_RECENT_SIGNAL_DATABASES_TO_STORE))
                )
            }

        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES, JSON.stringify([thisSignalDatabase]))
        }

    } catch (e: any) {
        console.error('Failed to parse stored recent signal databases, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES)
    }
}

const removeRecentSignalDatabase = (signalDatabase: RecentSignalDatabase) => {
    try {
        const recentSignalDatabasesAsJSon = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES)
        if (recentSignalDatabasesAsJSon !== null) {
            const recentSignalDatabases = JSON.parse(recentSignalDatabasesAsJSon) as Array<RecentSignalDatabase>
            const indexInList = recentSignalDatabases.findIndex(
                (it) =>
                    it.billableUnit.organisation.uid === signalDatabase.billableUnit.organisation.uid &&
                    it.project.uid === signalDatabase.project.uid &&
                    it.signalDatabase.name === signalDatabase.signalDatabase.name
            )

            if (indexInList >= 0) {
                recentSignalDatabases.splice(indexInList, 1)
                localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES, JSON.stringify(recentSignalDatabases))
            }
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent signal databases, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_SIGNAL_DATABASES)
    }
}
