import Highcharts from 'highcharts/highstock'
import HighchartsBoost from 'highcharts/modules/boost'
import Exporting from 'highcharts/modules/exporting'
import Accessibility from 'highcharts/modules/accessibility'
import AnnotationsModule from 'highcharts/modules/annotations'
import CloudApi from '../../../../../api/CloudApi'
import { ReactElement, useEffect, useRef, useState } from 'react'
import {
    AuthenticatedUser,
    FrameEntry,
    Project,
    RecordingAnnotation,
    RecordingSession,
    SignalEntry,
    SignalIdentifier,
} from '../../../../../api/CloudApi/types'
import { toast } from 'react-toastify'
import LoadingContainer from '../../../../../components/LoadingContainer'
import ErrorContainer from '../../../../../components/ErrorContainer'
import CloseRounded from '@mui/icons-material/CloseRounded'
import RuleRounded from '@mui/icons-material/RuleRounded'
import SpeakerNotesRounded from '@mui/icons-material/SpeakerNotesRounded'
import UpgradeRounded from '@mui/icons-material/UpgradeRounded'
import { GraphSettings, PanelKey, PlottableSignalEntry, SharedExtremes, TimeSeriesPanel } from '../../Types'
import { Accordion, Form, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { formattedToastMessage } from '../../../../../utils/toast'
import SelectSignalsModal from '../../SelectSignalsModal'
import SignalInChartLabel from './SignalInChartLabel'
import { isLocalOrDevEnvironment, isRemotiveUser } from '../../../../../utils/featureToggle'
import AnnotationComponent from '../../AnnotationContainer'
import { CloseIcon, SettingsIcon } from '../../../../../assets/Icons'
import AddAnnotationModal from '../../AnnotationContainer/AddAnnotationModal'
import TimeSeriesChart from './TimeSeriesChart'
import { AddRounded, RemoveRounded } from '@mui/icons-material'
import { isEqual } from 'lodash'

HighchartsBoost(Highcharts)

interface TimeSeriesContainerProps {
    updatePanel: (panel: TimeSeriesPanel) => void
    removeThisPanelFunction: () => void
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    recordingSession: RecordingSession | undefined
    panel: TimeSeriesPanel
    availableFrameEntries: Array<FrameEntry>
    availableAnnotations: Array<RecordingAnnotation> | undefined
    safeFetchAndSetAvailableAnnotations: () => Promise<Array<RecordingAnnotation>>
    sharedExtremes: SharedExtremes
    setSharedExtremes: (newExtremes: SharedExtremes) => void
    plottableSignalEntries: Array<PlottableSignalEntry> | undefined
    selfContained: boolean
}

const TIMESERIES_VISUALIZATION_HEIGHT = 430

export default function TimeSeriesContainer(props: TimeSeriesContainerProps) {
    Exporting(Highcharts)
    Accessibility(Highcharts)
    AnnotationsModule(Highcharts)

    const [componentState, setComponentState] = useState(ComponentState.LOADING)

    const [isExportingToCsv, setIsExportingToCsv] = useState(false)

    // Settings
    const [showSettingsPopover, setShowSettingsPopover] = useState<boolean>(false)
    const [panelState, setPanelState] = useState<TimeSeriesPanel>(props.panel)
    const settingShowYAxisOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.showYAxis ?? true
    }
    const settingShowXAxisOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.showXAxis ?? true
    }
    const settingShowAllAnnotationsOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.showAllAnnotations ?? true
    }
    const settingShowOnlyMyAnnotationsOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.showOnlyMyAnnotations ?? false
    }
    const settingUseSuggestedMinMaxOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.useSuggestedMinMax ?? false
    }
    const settingStackedChartsOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.useStackedCharts ?? true
    }
    const settingStackedChartHeightOrDefault = (panel: TimeSeriesPanel) => {
        return panel.graphSettings?.stackedChartHeight ?? 100
    }

    // Signals
    const [selectableSignals, setSelectableSignals] = useState<Array<FrameEntry>>(props.availableFrameEntries)
    const [showSelectSignalsModal, setShowSelectSignalsModal] = useState<boolean>(
        panelState.selectedSignals.length === 0
    )
    const [plottableSignalEntries, setPlottableSignalEntries] = useState<Array<PlottableSignalEntry> | undefined>()

    // Annotations
    const [lastClickedAnnotationTimestamp, setLastClickedAnnotationTimestamp] = useState<number | undefined>(undefined)
    const [lastClickedAnnotationTimestampEnd, setLastClickedAnnotationTimestampEnd] = useState<number | undefined>(
        undefined
    )
    const [selectedAnnotation, setSelectedAnnotation] = useState<RecordingAnnotation | undefined>()
    const [isAddAnnotationActive, setIsAddAnnotationActive] = useState<boolean>(false)
    const [showAddAnnotationModal, setShowAddAnnotationModal] = useState<boolean>(false)

    useEffect(() => {
        if (!props.selfContained) {
            setPlottableSignalEntries(props.plottableSignalEntries)
        }
    }, [props.plottableSignalEntries])

    useEffect(() => {
        console.log('Mounting TimeSeriesContainer!')
    }, [])

    useEffect(() => {
        if (showAddAnnotationModal) {
            setIsAddAnnotationActive(false)
        }
    }, [showAddAnnotationModal])

    // Sync to localStorage when panelState changes
    useEffect(() => {
        if (!isEqual(panelState, props.panel)) {
            props.updatePanel(panelState)
        }
    }, [panelState, props.panel])

    useEffect(() => {
        if (!props.selfContained) {
            setPanelState({ ...panelState, selectedSignals: props.panel.selectedSignals })
        }
    }, [props.selfContained, props.panel.selectedSignals])

    // Update panel state and sync changes back
    const updateSetting = (setting: Partial<GraphSettings>) => {
        setPanelState((prev) => {
            const updatedPanel = {
                ...prev,
                graphSettings: {
                    ...prev.graphSettings,
                    showYAxis: setting.showYAxis ?? settingShowYAxisOrDefault(prev),
                    showXAxis: setting.showXAxis ?? settingShowXAxisOrDefault(prev),
                    showAllAnnotations: setting.showAllAnnotations ?? settingShowAllAnnotationsOrDefault(prev),
                    showOnlyMyAnnotations: setting.showOnlyMyAnnotations ?? settingShowOnlyMyAnnotationsOrDefault(prev),
                    useSuggestedMinMax: setting.useSuggestedMinMax ?? settingUseSuggestedMinMaxOrDefault(prev),
                    useStackedCharts: setting.useStackedCharts ?? settingStackedChartsOrDefault(prev),
                    stackedChartHeight: setting.stackedChartHeight ?? settingStackedChartHeightOrDefault(prev),
                },
            }
            return updatedPanel
        })
    }

    useEffect(() => {
        if (panelState.graphSettings?.showAllAnnotations) {
            updateSetting({ showOnlyMyAnnotations: false })
        }
    }, [panelState.graphSettings?.showAllAnnotations])

    useEffect(() => {
        if (panelState.graphSettings?.showOnlyMyAnnotations) {
            updateSetting({ showAllAnnotations: false })
        }
    }, [panelState.graphSettings?.showOnlyMyAnnotations])

    useEffect(() => {
        setSelectableSignals(props.availableFrameEntries)
        setComponentState(ComponentState.DONE)
    }, [props.availableFrameEntries])

    useEffect(() => {
        if (panelState.selectedSignals.length > 0) {
            if (props.selfContained) {
                loadSignalData()
            }
            setPanelState({
                ...panelState,
                hiddenSignals: panelState.hiddenSignals.filter((it) =>
                    panelState.selectedSignals
                        .map((frame) => constructSignalNameKey(frame.name, frame.frameName, frame.namespace))
                        .includes(constructSignalNameKeyFromSignalEntry(it))
                ),
            } as TimeSeriesPanel)
        } else {
            setPlottableSignalEntries(undefined)
        }
    }, [panelState.selectedSignals])

    const onError = (err: any) => {
        console.error(err)
        setComponentState(ComponentState.ERROR)
    }

    const loadSignalData = async () => {
        setComponentState(ComponentState.LOADING)
        setPlottableSignalEntries(undefined)
        if (panelState.selectedSignals.length > 0) {
            const signalNames: Array<SignalIdentifier> = panelState.selectedSignals?.map((s) => ({
                namespace: s.namespace,
                frameName: s.frameName,
                signalName: s.name,
            }))
            CloudApi.getSignalsTimeseries(props.currentProject!.uid, props.recordingSession!.sessionId, signalNames)
                .then((res) => {
                    setComponentState(ComponentState.DONE)
                    const plottableSignalEntries = res.data.signals
                        .map((it) => {
                            const selectedSignal = panelState.selectedSignals.find(
                                (selectedSignal) => `${selectedSignal.frameName}.${selectedSignal.name}` === it.name
                            )
                            if (selectedSignal === undefined) {
                                console.warn(`Could not find signal metadata for ${it.name}`)
                                return undefined
                            }
                            return {
                                signalData: it.signals,
                                signalEntry: selectedSignal,
                            } as PlottableSignalEntry
                        })
                        .filter((it) => it !== undefined) as Array<PlottableSignalEntry>
                    setPlottableSignalEntries(plottableSignalEntries)
                    if (res.data.signals.length === 0) {
                        toast.warn(
                            formattedToastMessage(
                                'Graph issue',
                                "We couldn't create a graph using the currently selected signals, there are no data points available."
                            ),
                            { autoClose: 20_000 }
                        )
                    } else if (res.data.signals.length !== panelState.selectedSignals.length) {
                        toast.warn(
                            formattedToastMessage(
                                'Graph issue',
                                "We couldn't find data points for all your currently selected signals. The signals without data points have been disabled."
                            ),
                            { autoClose: 20_000 }
                        )
                    }
                })
                .catch(onError)
        } else {
            setComponentState(ComponentState.DONE)
        }
    }

    const exportSignalData = async () => {
        if (panelState.selectedSignals.length > 0) {
            const signalNames: Array<SignalIdentifier> = panelState.selectedSignals?.map((s) => ({
                namespace: s.namespace,
                frameName: s.frameName,
                signalName: s.name,
            }))

            try {
                setIsExportingToCsv(true)
                const res = await CloudApi.exportTimeseries(
                    props.currentProject!.uid,
                    props.recordingSession!.sessionId,
                    signalNames
                )
                window.open(`/p/${props.currentProject?.uid}/files/?path=${res.data}`, '_blank')
            } catch (err: any) {
                toast.error(
                    formattedToastMessage(
                        'Error',
                        err?.response?.data ||
                            'Something went wrong when exporting signal data. Please refresh the application and try again.'
                    )
                )
            }
            setIsExportingToCsv(false)
        } else {
            toast.error(
                formattedToastMessage(
                    'No data',
                    'No signal data to export, please select some signals to export and try again.'
                )
            )
        }
    }

    const constructSignalNameKeyFromSignalEntry = (signalEntry: SignalEntry) => {
        return constructSignalNameKey(signalEntry.name, signalEntry.frameName, signalEntry.namespace)
    }

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }

    const hexColorFromHighchartsColor = (
        color: undefined | string | Highcharts.GradientColorObject | Highcharts.PatternObject,
        isSignalInGraph: boolean
    ) => {
        if (!isSignalInGraph || color === undefined) {
            return '#dde1e6' // Appears hidden on disabled element
        }
        return color.toString()
    }

    const currentlySelectedSignals = () => {
        return panelState.selectedSignals.map((signal, index) => {
            const chartColor = Highcharts.getOptions().colors![index]
            const isSignalInGraph =
                plottableSignalEntries !== undefined &&
                plottableSignalEntries.map((it) => it.signalEntry).includes(signal)
            const isSignalHidden =
                panelState.hiddenSignals.find(
                    (it) => constructSignalNameKeyFromSignalEntry(it) === constructSignalNameKeyFromSignalEntry(signal)
                ) === undefined
            return (
                <div key={constructSignalNameKeyFromSignalEntry(signal)} className="m-1">
                    <SignalInChartLabel
                        signal={signal}
                        isSignalHidden={isSignalHidden}
                        isSignalInGraph={isSignalInGraph}
                        hiddenSignals={panelState.hiddenSignals}
                        constructSignalNameKeyFromSignalEntry={constructSignalNameKeyFromSignalEntry}
                        setHiddenSignals={(hiddenSignals: Array<SignalEntry>) =>
                            setPanelState({
                                ...panelState,
                                hiddenSignals,
                            } as TimeSeriesPanel)
                        }
                        htmlColor={hexColorFromHighchartsColor(chartColor, isSignalInGraph)}
                    />
                </div>
            )
        })
    }

    const closeButton = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
                    onClick={() => props.removeThisPanelFunction()}
                    className="btn p-0 remotive-btn-no-bg"
                >
                    <div className="d-flex align-items-center" title="Close entire panel">
                        <CloseRounded sx={{ fontSize: 22 }} />
                    </div>
                </button>
            </div>
        )
    }

    const settingsButton = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    disabled={panelState.selectedSignals.length === 0}
                    className={`btn remotive-btn-no-bg remotive-btn p-0 m-0`}
                >
                    <div className="d-flex align-items-center mx-1" title="Settings for this signal time series panel">
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            show={showSettingsPopover}
                            onToggle={(newState: boolean) => setShowSettingsPopover(newState)}
                            placement="left"
                            overlay={settingsPopover}
                        >
                            <div className="d-flex align-items-center">
                                <SettingsIcon sx={{ fontSize: 20 }} />
                            </div>
                        </OverlayTrigger>
                    </div>
                </button>
            </div>
        )
    }

    const toolbar = () => {
        const ICON_SIZE = 14
        return (
            <div style={{ marginTop: -2 }} className=" px-1">
                <div className="d-flex align-items-start">
                    {props.selfContained && (
                        <div className="">
                            <button
                                disabled={componentState === ComponentState.LOADING}
                                onClick={() => setShowSelectSignalsModal(true)}
                                title="Select signals to visualize in this panel"
                                className="btn remotive-btn-no-bg p-0 px-3 m-0"
                            >
                                <div className=" d-flex flex-column align-items-center">
                                    <RuleRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                    <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Select signals</p>
                                </div>
                            </button>
                        </div>
                    )}
                    <div className="ms-2">
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            title="Add an annotation to the graph"
                            onClick={() => setIsAddAnnotationActive(!isAddAnnotationActive)}
                            className="btn remotive-btn-no-bg p-0 m-0 px-2"
                        >
                            <div
                                className={`d-flex flex-column align-items-center ${
                                    isAddAnnotationActive ? 'remotive-success-60-color' : ''
                                }`}
                            >
                                <SpeakerNotesRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Annotate</p>
                            </div>
                        </button>
                    </div>

                    {/* This features is not ready yet!
                    <div className="">
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            title="Measure time difference between signals"
                            className="btn remotive-btn-no-bg p-0 px-2 m-0"
                        >
                            <div className="d-flex flex-column align-items-center">
                                <StraightenRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                <p className="remotive-font-xxs m-0">Measure</p>
                            </div>
                        </button>
                    </div> 
                    */}
                    {isRemotiveUser(props.currentUser) && (
                        <div className="">
                            <button
                                onClick={() => exportSignalData()}
                                disabled={componentState === ComponentState.LOADING || isExportingToCsv}
                                title="Export signal data"
                                className="btn remotive-btn-no-bg p-0 px-2 m-0"
                            >
                                <div className="d-flex flex-column align-items-center justify-content-center">
                                    {isExportingToCsv ? (
                                        <>
                                            <Spinner size="sm" style={{ marginBottom: 3, height: 11, width: 11 }} />
                                            <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Export</p>
                                        </>
                                    ) : (
                                        <>
                                            <UpgradeRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                            <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Export</p>
                                        </>
                                    )}
                                </div>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const selectSignalsButton = (size: 'sm' | 'lg') => {
        const isSmall = size === 'sm'
        return (
            <div className="d-flex flex-column align-items-center ms-2">
                <button
                    disabled={componentState === ComponentState.LOADING}
                    onClick={() => setShowSelectSignalsModal(true)}
                    className={`btn remotive-btn-primary ${isSmall ? 'remotive-btn-sm' : 'remotive-btn'} my-0`}
                >
                    <div className="d-flex align-items-center mx-1" title="Select signals to visualize in this panel">
                        {showSelectSignalsModal ? (
                            <>
                                <Spinner size="sm" />
                            </>
                        ) : (
                            <>
                                <RuleRounded sx={{ fontSize: isSmall ? 17 : 24 }} className="me-2" />
                                <p className="m-0">Select signals</p>
                            </>
                        )}
                    </div>
                </button>
                <>
                    {!isSmall && (
                        <p className="m-0 mt-1 remotive-font-sm remotive-secondary-color">
                            Select some signals to visualize a time series graph
                        </p>
                    )}
                </>
            </div>
        )
    }

    const popoverSettingsItem = (title: string, actionElement: ReactElement) => {
        return (
            <div className="d-flex justify-content-between align-items-center rounded px-2 py-1 m-1 remotive-primary-20-background">
                <p className="m-0 remotive-font-sm lexend-bold">{title}</p>
                <div className="d-flex justify-content-center align-items-center flex-row align-items-center">
                    {actionElement}
                </div>
            </div>
        )
    }

    const settingsPopover = (
        <Popover
            id="popover-basic"
            className="border-2 remotive-primary-20-border remotive-primary-0-background shadow rounded-4"
            style={{ maxWidth: 400 }}
        >
            <Popover.Body style={{ zIndex: '0 !important', width: 300 }} className="p-3 lexend-regular">
                <div>
                    <div>
                        {popoverSettingsItem(
                            'Show Y-axis',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={settingShowYAxisOrDefault(panelState)}
                                        disabled={
                                            componentState === ComponentState.LOADING ||
                                            panelState.selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => updateSetting({ showYAxis: e.target.checked })}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Show X-axis',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={settingShowXAxisOrDefault(panelState)}
                                        disabled={
                                            componentState === ComponentState.LOADING ||
                                            panelState.selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => updateSetting({ showXAxis: e.target.checked })}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        {popoverSettingsItem(
                            'Show all annotations',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={panelState.graphSettings?.showAllAnnotations ?? true}
                                        disabled={
                                            componentState === ComponentState.LOADING ||
                                            panelState.selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => {
                                            setSelectedAnnotation(undefined)
                                            updateSetting({ showAllAnnotations: e.target.checked })
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div>
                        {popoverSettingsItem(
                            'Show only my annotations',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={settingShowOnlyMyAnnotationsOrDefault(panelState)}
                                        disabled={
                                            componentState === ComponentState.LOADING ||
                                            panelState.selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => {
                                            setSelectedAnnotation(undefined)
                                            updateSetting({ showOnlyMyAnnotations: e.target.checked })
                                        }}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Use suggested min/max',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={settingUseSuggestedMinMaxOrDefault(panelState)}
                                        disabled={
                                            componentState === ComponentState.LOADING ||
                                            panelState.selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => updateSetting({ useSuggestedMinMax: e.target.checked })}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Use stacked charts',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={settingStackedChartsOrDefault(panelState)}
                                        disabled={
                                            componentState === ComponentState.LOADING ||
                                            panelState.selectedSignals.length < 1
                                        }
                                        onChange={(e: any) => updateSetting({ useStackedCharts: e.target.checked })}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                    <div>
                        {popoverSettingsItem(
                            'Stacked chart height',
                            <div className="d-flex align-items-center">
                                <button
                                    style={{ marginTop: -3 }}
                                    className="btn bg-transparent remotive-accessibility px-1 py-0 border-0 remotive-primary-80-color"
                                    title="Decrease update frequency"
                                    onClick={() => {
                                        if (settingStackedChartHeightOrDefault(panelState) > 20) {
                                            updateSetting({
                                                stackedChartHeight: settingStackedChartHeightOrDefault(panelState) - 5,
                                            })
                                        } else {
                                            toast.error("You can't make this chart smaller")
                                        }
                                    }}
                                >
                                    <RemoveRounded sx={{ fontSize: 14 }} />
                                </button>
                                <p className="mb-0 px-0 remotive-font-sm lexend-regular">
                                    {settingStackedChartHeightOrDefault(panelState)}
                                </p>
                                <button
                                    style={{ marginTop: -3 }}
                                    className="btn bg-transparent remotive-accessibility px-1 py-0 border-0 remotive-primary-80-color"
                                    title="Increase update frequency"
                                    onClick={() =>
                                        updateSetting({
                                            stackedChartHeight: settingStackedChartHeightOrDefault(panelState) + 5,
                                        })
                                    }
                                >
                                    <AddRounded sx={{ fontSize: 14 }} />
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    const addAnnotationModeBanner = () => {
        return (
            <div className="d-flex justify-content-center" style={{ marginTop: 2, marginBottom: 1 }}>
                <div className="rounded-3 remotive-success-10-background p-2">
                    <div className="d-flex align-items-center ps-3 pe-1">
                        <SpeakerNotesRounded sx={{ fontSize: 17 }} className="remotive-success-60-color me-3" />
                        <p className="mb-0 remotive-font-md remotive-success-100-color">
                            You are in annotation mode, click or drag in the graph to create an annotation.
                        </p>
                        <button
                            onClick={() => {
                                setIsAddAnnotationActive(false)
                            }}
                            className="btn remotive-btn-no-bg p-0 m-0 ms-3 me-1 remotive-dark-color d-flex align-items-center"
                        >
                            <CloseIcon className="p-0 m-0" sx={{ fontSize: 17 }} />
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    const plottableSignalEntriesWithoutHiddenSignals =
        (plottableSignalEntries ?? []).filter(
            (it) =>
                !panelState.hiddenSignals.find(
                    (signal) =>
                        constructSignalNameKey(signal.name, signal.frameName, signal.namespace) ===
                        constructSignalNameKey(it.signalEntry.name, it.signalEntry.frameName, it.signalEntry.namespace)
                )
        ) ?? []

    return (
        <>
            {selectableSignals && selectableSignals.length > 0 && (
                <div className="p-2 h-100 w-100 pb-1">
                    <>
                        <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex align-items-start">
                                {props.selfContained && (
                                    <div className="d-flex align-items-center border-end">
                                        <div className="me-2">{closeButton()}</div>
                                        <div className="me-3">
                                            <p className="remotive-font-md lh-sm remotive-dark-color m-0">
                                                Signal Time Series
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className="">{panelState.selectedSignals.length > 0 && toolbar()}</div>
                            </div>

                            <div className="d-flex">{settingsButton()}</div>
                        </div>
                        {isAddAnnotationActive ? (
                            addAnnotationModeBanner()
                        ) : (
                            <div className="d-flex justify-content-start flex-row flex-wrap">
                                {currentlySelectedSignals()}
                            </div>
                        )}
                    </>

                    {componentState === ComponentState.LOADING && <LoadingContainer spinnerSize="sm" />}
                    {componentState === ComponentState.ERROR && (
                        <ErrorContainer
                            errorSubText={'We encountered a problem while fetching signal data.'}
                            errorText={'Signal data error'}
                        />
                    )}
                    {componentState === ComponentState.DONE && (
                        <div className="container-fluid px-0">
                            {panelState.selectedSignals.length <= 0 && (
                                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                                    {selectSignalsButton('lg')}
                                </div>
                            )}
                            <div className="row">
                                <TimeSeriesChart
                                    currentUser={props.currentUser}
                                    maxHeight={TIMESERIES_VISUALIZATION_HEIGHT}
                                    panelKey={props.panel.panelKey}
                                    plottableSignalEntriesWithoutHiddenSignals={
                                        plottableSignalEntriesWithoutHiddenSignals
                                    }
                                    plottableSignalEntries={plottableSignalEntries}
                                    availableAnnotations={props.availableAnnotations}
                                    selectedSignals={panelState.selectedSignals}
                                    hiddenSignals={panelState.hiddenSignals}
                                    graphSettings={{
                                        showYAxis: settingShowYAxisOrDefault(panelState),
                                        showXAxis: settingShowXAxisOrDefault(panelState),
                                        showAllAnnotations: settingShowAllAnnotationsOrDefault(panelState),
                                        showOnlyMyAnnotations: settingShowOnlyMyAnnotationsOrDefault(panelState),
                                        useSuggestedMinMax: settingUseSuggestedMinMaxOrDefault(panelState),
                                        useStackedCharts: settingStackedChartsOrDefault(panelState),
                                        stackedChartHeight: settingStackedChartHeightOrDefault(panelState),
                                    }}
                                    setSelectedAnnotation={setSelectedAnnotation}
                                    selectedAnnotation={selectedAnnotation}
                                    isAddAnnotationActive={isAddAnnotationActive}
                                    setShowAddAnnotationModal={setShowAddAnnotationModal}
                                    setLastClickedAnnotationTimestamp={setLastClickedAnnotationTimestamp}
                                    setLastClickedAnnotationTimestampEnd={setLastClickedAnnotationTimestampEnd}
                                    sharedExtremes={props.sharedExtremes}
                                    setSharedExtrems={props.setSharedExtremes}
                                />
                                <AnnotationComponent
                                    className={`${
                                        selectedAnnotation === undefined ? '' : 'col-6 col-md-4 col-xxl-3 ps-1'
                                    }`}
                                    refreshAnnotation={async () => {
                                        const annotations = await props.safeFetchAndSetAvailableAnnotations()
                                        setSelectedAnnotation(
                                            annotations.find((it) => it.timestamp === selectedAnnotation?.timestamp)
                                        )
                                    }}
                                    currentUser={props.currentUser}
                                    currentProject={props.currentProject}
                                    currentRecordingSession={props.recordingSession}
                                    annotation={selectedAnnotation}
                                    maxHeight={
                                        document.getElementById(`${props.panel.panelKey.key}-graph`)?.clientHeight ??
                                        100
                                    }
                                    onClose={() => setSelectedAnnotation(undefined)}
                                    onDelete={async () => {
                                        await props.safeFetchAndSetAvailableAnnotations()
                                        setSelectedAnnotation(undefined)
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            )}

            <AddAnnotationModal
                show={showAddAnnotationModal}
                currentProject={props.currentProject}
                currentRecordingSession={props.recordingSession}
                annotationTimestamp={lastClickedAnnotationTimestamp}
                annotationTimestampEnd={lastClickedAnnotationTimestampEnd}
                handleCloseFunction={async () => {
                    setLastClickedAnnotationTimestamp(undefined)
                    setLastClickedAnnotationTimestampEnd(undefined)
                    setShowAddAnnotationModal(false)
                }}
                getAllAnnotations={() => props.safeFetchAndSetAvailableAnnotations()}
            />
            <SelectSignalsModal
                isFramesSelectable={false}
                show={showSelectSignalsModal}
                selectableSignalsWithParentFrame={selectableSignals}
                selectedSignals={panelState.selectedSignals}
                handleCloseFunction={(numberOfSelectedSignals: number) => {
                    if (numberOfSelectedSignals <= 0) {
                        props.removeThisPanelFunction()
                    }
                    setShowSelectSignalsModal(false)
                }}
                selectSignalsFunction={(signals: Array<SignalEntry>) =>
                    setPanelState({
                        ...panelState,
                        selectedSignals: signals,
                    } as TimeSeriesPanel)
                }
            />
        </>
    )
}

enum ComponentState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
}
