import { Accordion, Card, Container, ListGroup } from "react-bootstrap"

import NavigationBar from "../components/navigation/NavigationBar"
import { AuthenticatedUser, Project, UserBillableUnitInfo } from "../api/CloudApi/types"
import { BreadCrumb, BreadCrumbs } from "../types/BreadCrumbs"
import { OrganisationRoutes } from "../Routes"
import { RecordingsIcon, SignalDatabasesIcon } from "../assets/Icons"
import { ReactElement, ReactNode, useEffect, useState } from "react"
import { getRecentRecordingsForProject, RecentRecording } from "../types/RecentRecording"
import { getRecentSignalDatabases, RecentSignalDatabase } from "../types/RecentSignalDatabase"
import EditableLabel from "../components/EditableLabel"
import RecordingListItem from "../components/RecordingSessionListItem"
import { ProductAnalyticsContext, ProductAnalyticsProperties } from "../utils/ProductAnalytics"
import { isDemo } from "../utils/demo"
import SignalDatabaseListItem from "src/components/SignalDatabaseListItem"
import LoadingContainer from "src/components/LoadingContainer"
import EmptyRecentRecordingsContainer from "src/components/EmptyRecentRecordingsContainer"
import EmptyRecentSignalDatabaseContainer from "src/components/EmptyRecentSignalDatabasesContainer"

interface ProjectHomeProps {
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    onProjectDisplayNameChange: (displayName: string) => void
    onProjectDescriptionChange: (description: string) => void
}

interface CardItemProps {
    icon: ReactElement
    title: string
    children?: ReactNode
}

function CardItem(props: CardItemProps) {

    return (
        <Card className="shadow-sm rounded-4 border-0 mt-2 p-2 text-start" style={{ width: '100%' }}>
            <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                    <Accordion.Header className="px-1">
                        <Card.Header className="px-0 d-flex border-0 align-items-center bg-transparent">
                            {props.icon}
                            <p className="m-0 remotive-font remotive-primary-100-color">{props.title}</p>
                        </Card.Header>
                    </Accordion.Header>
                    <Accordion.Body className="p-1">
                        <ListGroup variant="flush" className="border-0">
                            {props.children}
                        </ListGroup>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </Card>
    )
}


export default function ProjectHome(props: ProjectHomeProps) {

    const { currentProject } = props
    const [recentRecordings, setRecentRecordings] = useState<RecentRecording[]>()
    const [recentSignalDatabases, setRecentSignalDatabases] = useState<RecentSignalDatabase[]>()

    useEffect(() => {
        setRecentRecordings(undefined)
        const fetchRecentRecordings = async (project: Project) => {
            getRecentRecordingsForProject(project).then(it => setRecentRecordings(it))
            getRecentSignalDatabases([project]).then(it => setRecentSignalDatabases(it))
        }

        if (currentProject) {
            fetchRecentRecordings(currentProject)
        }
    }, [currentProject])

    if (!currentProject && !props.currentBillableUnit) {
        return <div />
    }

    const project = currentProject!

    const pageHeader = () => {

        return (
            <div className="d-flex flex-column">
                <EditableLabel
                    value={project.displayName}
                    placeholder="Project name"
                    onSave={props.onProjectDisplayNameChange}
                />

                <EditableLabel
                    value={project.description}
                    placeholder="Project description"
                    secondary
                    onSave={props.onProjectDescriptionChange}
                />
            </div>
        )
    }

    const recentRecordingsList = () => {
        const items = recentRecordings?.map((it) => {
            return (
                <div
                    key={`${it.billableUnit.organisation.uid}${it.project.uid}${it.recordingSession.sessionId}`}
                    className={`position-relative rounded remotive-primary-10-background m-1 mx-0`}
                >
                    <div className="px-1">
                        <RecordingListItem
                            isRecentlyUploaded={false}
                            recording={it.recordingSession}
                            project={it.project}
                            billableUnit={it.billableUnit}
                            productAnalyticsProperties={
                                {
                                    currentBillableUnit: props.currentBillableUnit,
                                    currentUser: props.currentUser,
                                    productAnalyticsContext: ProductAnalyticsContext.ORGANISATION_HOME,
                                } as ProductAnalyticsProperties
                            }
                            isLocked={isDemo()}
                            useProjectNameInsteadOfFilesLength={true}
                        />
                    </div>
                </div>
            )
        })

        function getContent() {
            if (items) {
                return items.length > 0 ? items : <EmptyRecentRecordingsContainer projectUid={project.uid} buttons />
            }

            return <LoadingContainer spinnerSize="sm" />
        }

        return (
            <CardItem
                icon={<RecordingsIcon sx={{ fontSize: 20 }} className="remotive-primary-50-color me-2" />}
                title="Recently viewed recording sessions"
            >
                {
                    getContent()
                }
            </CardItem>
        )
    }

    const recentSignalDatabasesList = () => {

        const items = recentSignalDatabases?.map((it) => {
            return (
                <div
                    key={`${it.billableUnit.organisation.uid}${it.project.uid}${it.signalDatabase.name}`}
                    className={`position-relative rounded remotive-primary-10-background m-1 mx-0`}
                >
                    <div className="px-1">
                        <SignalDatabaseListItem
                            sdb={it.signalDatabase}
                            project={props.currentProject}
                            billableUnit={props.currentBillableUnit}
                        />
                    </div>
                </div>
            )
        })

        const getContent = () => {
            if (items) {
                return items.length > 0 ? items : <EmptyRecentSignalDatabaseContainer projectUid={project.uid} />
            }

            return <LoadingContainer spinnerSize="sm" />
        }

        return (
            <CardItem
                icon={<SignalDatabasesIcon sx={{ fontSize: 20 }} className="remotive-primary-50-color me-2" />}
                title="Recently viewed signal databases"
            >
                {getContent()}
            </CardItem>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [{
                title: props.currentBillableUnit?.organisation.displayName + ' /',
                route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
            }
            ],
            current: {
                title: props.currentProject?.displayName ?? ''
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                isDemoGuideActive={false}
                openConfirmationDialog={() => { }}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column flex-grow-0">
                {pageHeader()}
                <div>
                    {recentRecordingsList()}
                    {recentSignalDatabasesList()}
                </div>
            </Container>
        </div>
    )
}
