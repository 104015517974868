import { NotFoundIcon } from '../assets/Icons'

type MarginValue = "0" | "1" | "2" | "3" | "4" | "5"
type MarginClass = `mx-${MarginValue}` | `my-${MarginValue}` | `mb-${MarginValue}` | `mt-${MarginValue}`
type MarginClasses = `${MarginClass}` | `${MarginClass} ${MarginClass}`

interface NotFoundContanerProps {
    infoText?: string
    secondaryText?: string
    margin?: MarginClasses
}

const DEFAULT_MARGIN = "my-5"

export default function NotFoundContaner(props: NotFoundContanerProps) {
    return (
        <>
            <div className={`text-center ${props.margin ?? DEFAULT_MARGIN}`}>
                <NotFoundIcon className="remotive-primary-60-color" sx={{ fontSize: 35 }} />
                <p className="fs-5 m-0 p-0 remotive-dark-color">{props.infoText || "We couldn't find what you were looking for..."}</p>
                <p className="remotive-secondary-color remotive-font-md">{props.secondaryText || ''}</p>
            </div>
        </>
    )
}
