import { useState, useEffect } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import { Project } from '../../api/CloudApi/types'
import CloudApi from '../../api/CloudApi'
import { Card, Spinner } from 'react-bootstrap'
import { CircleIcon } from '../../assets/Icons'
import { getFrontendUrlProtocol } from '../../utils/network'
import { isDemo } from '../../utils/demo'

interface BrokerLogStreamCardProps {
    currentProject: Project | undefined
    brokerName: string | undefined
}

const DEFAULT_WS_URL_DONT_CONNECT = 'wss://does.not.exist'

export const BrokerLogStreamCard = (props: BrokerLogStreamCardProps) => {
    //Public API that will echo messages sent to it back to the client
    const [socketUrl, setSocketUrl] = useState<string>(DEFAULT_WS_URL_DONT_CONNECT)
    const [shouldConnect, setShouldConnect] = useState(false)
    const [messageHistory, setMessageHistory] = useState<Array<MessageEvent>>([])

    const { lastMessage, readyState } = useWebSocket(
        socketUrl,
        {
            // We try to reconnect a maximum of 5 times, exponenitally backing off after each attempt
            shouldReconnect: (closeEvent) => true,
            reconnectInterval: (lastAttemptNumber: number) => Math.pow(lastAttemptNumber, 2) * 1000,
            reconnectAttempts: 5,
        },
        socketUrl !== DEFAULT_WS_URL_DONT_CONNECT && shouldConnect
    )

    useEffect(() => {
        if (props.brokerName !== undefined && props.currentProject !== undefined) {
            const frontendProtocol = getFrontendUrlProtocol()
            const websocketProtocol = frontendProtocol === 'http:' ? 'ws:' : 'wss:'
            const brokerLogsWebsocketPath = `/api/project/${props.currentProject.uid}/brokers/${props.brokerName}/logs?tail=true`
            const backendHost = CloudApi.getBackendURL().replaceAll('https://', '').replaceAll('http://', '')

            const fullWebsocketUrl = `${websocketProtocol}${backendHost}${brokerLogsWebsocketPath}`
            console.log(`Setting broker log websocket URL to ${fullWebsocketUrl}`)
            setSocketUrl(fullWebsocketUrl)
        }
    }, [props.brokerName, props.currentProject])

    useEffect(() => {
        if (lastMessage !== null) {
            setMessageHistory([lastMessage, ...messageHistory])
        }
    }, [lastMessage, setMessageHistory])

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Not initialized',
    }[readyState]

    const getLogStreamBody = () => {
        if (readyState === ReadyState.CONNECTING) {
            return (
                <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <Spinner size="sm" />
                    <p className="mt-1 m-0 remotive-font-sm">Connecting to broker...</p>
                </div>
            )
        }
        return (
            <>
                {messageHistory.map((message, idx) => (
                    <div className="row" key={idx}>
                        <p className="m-0 remotive-font-sm">{message ? message.data : null}</p>
                    </div>
                ))}
            </>
        )
    }

    const connectToStreamButton = () => {
        const isButtonDisabled = isDemo()
        return (
            <>
                <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
                    <button
                        disabled={isButtonDisabled}
                        onClick={() => setShouldConnect(true)}
                        className="btn remotive-btn-md remotive-btn-primary"
                    >
                        View logs
                    </button>
                    {isButtonDisabled ? (
                        <p className="m-0 mt-1 remotive-secondary-color remotive-font-xxs">Logs are not available in demo</p>
                    ) : (
                        <p className="m-0 mt-1 remotive-secondary-color remotive-font-xxs">
                            Logs will be streamed from the broker with a few seconds delay
                        </p>
                    )}
                </div>
            </>
        )
    }

    const connectionStatusIndicator = () => {
        const INDICATOR_SIZE = 15
        switch (connectionStatus) {
            case 'Open':
                return <CircleIcon sx={{ fontSize: INDICATOR_SIZE }} className="remotive-success-60-color" />

            case 'Connecting':
                return <CircleIcon sx={{ fontSize: INDICATOR_SIZE }} className="remotive-warning-50-color" />

            case 'Closed':
            default:
                return <CircleIcon sx={{ fontSize: INDICATOR_SIZE }} className="text-danger" />
        }
    }

    return (
        <div>
            <Card className="text-start border-0 remotive-white-background shadow w-100 rounded-0 rounded-top-4">
                <div className="w-100 m-0 p-0 border-0 position-sticky">
                    <div className="d-flex flex-row align-items-center justify-content-between p-0 pt-1 px-2">
                        <div className="d-flex flex-column">
                            <p className="m-0">
                                <b>Logs</b>
                            </p>
                            <p className="m-0 remotive-font-xs remotive-secondary-color">
                                Broker connection is {connectionStatus.toLowerCase()}
                            </p>
                        </div>
                        {connectionStatusIndicator()}
                    </div>
                </div>
            </Card>
            <Card
                className="text-start border-0 shadow remotive-white-background w-100 rounded-0 rounded-bottom-4"
                style={{ height: '290px', overflowY: 'auto', overflowX: 'hidden' }}
            >
                <Card.Body className="py-1 px-2">
                    {shouldConnect === true ? getLogStreamBody() : connectToStreamButton()}
                </Card.Body>
            </Card>
        </div>
    )
}
