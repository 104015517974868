import { Accordion, Card, Container, Form, InputGroup, Modal, ProgressBar, Spinner } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import {
    AuthenticatedUser,
    ProcessingUploadFile,
    ProcessingUploadFileStatus,
    ProcessingUploadFileStep,
    Project,
    RecordingFile,
    RecordingSession,
    SignalDatabase,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import NavigationBar from '../components/navigation/NavigationBar'
import CloudApi from '../api/CloudApi'
import {
    ArrowEastIcon,
    ArrowNorthIcon,
    ArrowSouthIcon,
    ClickableArrowIcon,
    CloudUploadIcon,
    CopyIcon,
    DeleteIcon,
    ErrorIcon,
    RecordingsIcon,
    RefreshIcon,
    ImportExportIcon,
    UploadIcon,
    UnlockIcon,
    LockedIcon,
    SearchIcon,
    CheckIcon,
    SuccessIcon,
} from '../assets/Icons'
import { NavLink } from 'react-router-dom'
import LoadingContainer from '../components/LoadingContainer'
import ErrorContainer from '../components/ErrorContainer'
import NotFoundContaner from '../components/NotFoundContainer'
import ProcessingUploadsAccordion from '../components/ProcessingUploadsAccordion'
import { toast } from 'react-toastify'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { formatAsDatetime } from '../utils/datetime'
import HelpOverlayModalModal from '../components/modals/HelpOverlayModal'
import HelpTextBox from '../components/cards/DemoHelpCard'
import { isDemo, isGuidedDemo } from '../utils/demo'
import { hasPermission, Permission } from '../utils/permission'
import CopyRecordingSessionModal from './CopyRecordingSessionModal'
import ImportRecordingSessionModal from './ImportRecordingSessionModal'
import { OrganisationRoutes, ProjectRoutes } from '../Routes'
import { BreadCrumb, BreadCrumbs, buAndProjectTrail } from '../types/BreadCrumbs'
import CliHintContainer from '../components/CliHintContainer'
import RecordingSessionListItem from '../components/RecordingSessionListItem'
import { PageDetails } from '../utils/pageDetails'
import useInterval from '../hooks/useInterval'
import { maxWidth, minWidth } from '@mui/system'
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext'
import { formattedToastMessage } from '../utils/toast'
import ProcessingUploadListItem from '../components/ProcessingUploadListItem'
import RecordingDropzone from '../components/RecordingDropzone'
import randomString from '../utils/randomString'
import ConfirmDialog from '../components/modals/ConfirmDialog'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'
import {
    ProductAnalyticsContext,
    ProductAnalyticsProperties,
    createAnalyticsTrackingKey,
    ProductAnalyticsProps,
    useProductAnalyticsClient,
} from '../utils/ProductAnalytics'
import { ALLOWED_RECORDING_FILE_NAME_ENDINGS } from '../utils/files'
import useWindowDimensions from '../hooks/useWindowDimensions'
import NavbarAwareContainer from './NavbarAwareContainer'
import SearchInput from 'src/components/SearchInput'

enum PageState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
    UPLOAD_ERROR,
    DELETE_ERROR,
}

const DEMO_RECORDING_WHITELIST = ['13303517729834103000'] // Turning Torso drivecycle session id

const AnalyticsActions = {
    UPLOAD_RECORDING: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'UploadRecordingFromButton'),
    COPY_RECORDING: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'CopyRecording'),
    DELETE_RECORDING: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'DeleteRecording'),
    IMPORT_SAMPLE_RECORDING: createAnalyticsTrackingKey(ProductAnalyticsContext.RD_FILES_TAB, 'ImportSampleRecording'),
}

interface RecordingsListProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    projects: Array<Project>
    currentUser: AuthenticatedUser | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export default function RecordingsList(props: RecordingsListProps) {
    const [processingAccordionKey, setProcessingAccordionKey] = useState<string>('processingRecording')
    const dropzone = useRef<DropzoneRef | null>(null)

    const [recordings, setRecordings] = useState<Array<RecordingSession>>()
    const [filteredRecordings, setFilteredRecordings] = useState<Array<RecordingSession>>()

    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())
    const [showCopyRecordingModal, setShowCopyRecordingModal] = useState(false)
    const [showImportSampleRecordingModal, setShowImportSampleRecordingModal] = useState(false)
    const [recordingSessionToCopy, setRecordingSessionToCopy] = useState<string>()
    const [searchKey, setSearchKey] = useState<string>()

    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.currentBillableUnit,
    } as ProductAnalyticsProps)

    useEffect(() => {
        if (props.currentProject?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Recordings - ${props.currentProject?.displayName}`,
                productAnalyticsEventName: 'Project Recordings',
            })
        }
    }, [props.currentProject])

    const highlightObject = (isHighlightActive: boolean) => {
        return isHighlightActive ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    }

    useEffect(() => {
        console.debug('Mounted recordings list page!')
    }, [])

    useEffect(() => {
        if (props.currentProject) {
            setRecordingsState()
        }
    }, [props.currentProject])

    useEffect(() => {
        if (recordings && filteredRecordings) {
            setFilteredRecordings(recordings)
        }
    }, [recordings])

    useEffect(() => {
        if (recordingSessionToCopy) {
            setShowCopyRecordingModal(true)
        }
    }, [recordingSessionToCopy])

    useEffect(() => {
        if (searchKey === undefined) {
            setFilteredRecordings(recordings ?? [])
        }
        if (searchKey && searchKey.length >= 1) {
            const filteredRecordings: Array<RecordingSession> = (recordings ?? [])
                .map((recording) => {
                    if (
                        recording.displayName.toLowerCase().includes(searchKey.toLowerCase()) ||
                        recording.sessionId.toLowerCase().includes(searchKey.toLowerCase())
                    ) {
                        return recording
                    }
                    // No match
                    return undefined
                })
                .filter((signal) => signal !== undefined) as Array<RecordingSession>
            setFilteredRecordings(filteredRecordings)
        }
    }, [searchKey])

    const fetchRecordings = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            const finished = await CloudApi.listRecordingSessions(projectUid)
            setRecordings(finished.data)
        }
    }

    const getProcessingRecordings = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            const response = await CloudApi.listProcessingRecordingFiles(projectUid)
            return response.data
        }
    }

    const deleteProcessingRecording = async (processing: ProcessingUploadFile) => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            await CloudApi.deleteProcessingRecordingFile(projectUid, processing)
            await fetchRecordings()
        }
    }

    const setRecordingsState = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            const listAvailableSignalDatabases = async () => {
                const signDbs = await CloudApi.listSignalDatabases(projectUid)
                return signDbs.data
                    .filter((db: SignalDatabase) => db.type === 'candb')
                    .map((db: SignalDatabase) => db.name)
            }

            try {
                setPageState(PageState.LOADING)
                const [recordingsResponse, processingRecordingsResponse] = await Promise.all([
                    CloudApi.listRecordingSessions(projectUid),
                    CloudApi.listProcessingRecordingFiles(projectUid),
                    listAvailableSignalDatabases(),
                ])
                setRecordings(recordingsResponse.data)
                setFilteredRecordings(recordingsResponse.data)
                setPageState(PageState.DONE)
            } catch (err) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const uploadRecording = () => {
        console.log(dropzone)
        if (dropzone.current) {
            dropzone.current.open()
        }
    }

    const uploadSamplesButton = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_RECORDING, props.currentBillableUnit, props.currentProject)) {
            return (
                <button
                    className="btn remotive-btn remotive-btn-primary m-2 d-flex align-items-center"
                    onClick={() => setShowImportSampleRecordingModal(true)}
                >
                    <ImportExportIcon className="me-2" />
                    <p className="m-0">Import sample recordings</p>
                </button>
            )
        }
    }

    const handleListSampleRecordings = async () => {
        try {
            const recordings = await CloudApi.listSampleRecordingSessions()
            recordings.data.forEach((r) => console.log(r))
        } catch (e: any) {
            console.log(e)
        }
    }

    const listFinishedRecordings = () => {
        if (
            recordings !== undefined &&
            filteredRecordings !== undefined &&
            props.currentProject !== undefined &&
            props.currentBillableUnit !== undefined
        ) {
            return (
                <>
                    {helpTextRecordingDetails()}
                    {demoTextRecordingDetails()}
                    {filteredRecordings
                        .sort((a, b) =>
                            isDemo()
                                ? // Will put unlocked recordings on top
                                DEMO_RECORDING_WHITELIST.includes(a.sessionId)
                                    ? -1
                                    : 0
                                : Date.parse(b.uploaded) - Date.parse(a.uploaded)
                        )
                        .map((recording, index) => {
                            const isLocked = isDemo() ? !DEMO_RECORDING_WHITELIST.includes(recording.sessionId) : false
                            return (
                                <div
                                    key={recording.sessionId}
                                    style={index === 0 ? { ...highlightObject(showHelpModal || showDemoModal) } : {}}
                                    className={`position-relative rounded remotive-primary-10-background m-1 mx-0`}
                                >
                                    {isLocked && (
                                        <div className="position-absolute w-100 h-100 rounded remotive-neutral-50-background d-flex align-items-center opacity-50" />
                                    )}
                                    <div className="px-1">
                                        <RecordingSessionListItem
                                            isRecentlyUploaded={
                                                Date.now() - Date.parse(recording.uploaded) < 1_000 * 60 * 5
                                            }
                                            productAnalyticsProperties={
                                                {
                                                    productAnalyticsContext: ProductAnalyticsContext.RECORDINGS_LIST,
                                                    currentUser: props.currentUser,
                                                    currentBillableUnit: props.currentBillableUnit,
                                                } as ProductAnalyticsProperties
                                            }
                                            recording={recording}
                                            isLocked={isLocked}
                                            project={props.currentProject!}
                                            billableUnit={props.currentBillableUnit!}
                                            pageStateFunction={setPageState}
                                            recordingStateFunction={async () => {
                                                await fetchRecordings()
                                                setPageState(PageState.DONE)
                                            }}
                                            recordingSessionToCopyFunction={setRecordingSessionToCopy}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                </>
            )
        }
        return <></>
    }

    const searchOnChange = (event: any) => {
        const searchKey = event.target.value
        if (searchKey === '') {
            setSearchKey(undefined)
        } else {
            setSearchKey(searchKey)
        }
    }

    const searchSection = () => {
        return (
            <SearchInput
                searchKey={searchKey}
                searchOnChange={searchOnChange}
                searchStatus={`Showing ${filteredRecordings?.length ?? 0} of ${recordings?.length ?? 0}`}
            />
        )
    }

    const recordingsSection = () => {
        return (
            <>
                {listFinishedRecordings()}
                {recordingDropzone()}
            </>
        )
    }

    const recordingDropzone = () => {
        if (props.currentProject === undefined) {
            return <></>
        }
        return (
            <RecordingDropzone
                hasPermissionToUpload={hasPermission(
                    Permission.PROJECT_EDITOR_RECORDING,
                    props.currentBillableUnit,
                    props.currentProject
                )}
                productAnalyticsProperties={
                    {
                        productAnalyticsContext: ProductAnalyticsContext.RECORDINGS_LIST,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    } as ProductAnalyticsProperties
                }
                project={props.currentProject}
                ref={dropzone}
                type="recordingSession"
                helpTextElement={helpTextUploadRecordingDropzone()}
                highlightHelpText={() => highlightObject(showHelpModal)}
                onUploadComplete={() => {
                    setProcessingAccordionKey(randomString())
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                }}
            />
        )
    }

    const recordingsListBody = () => {
        if (recordings === undefined || props.currentProject === undefined || props.currentBillableUnit === undefined) {
            return <LoadingContainer spinnerSize="sm" />
        }
        const hasRecordings = recordings.length > 0
        {
            return (
                <>
                    <ProcessingUploadsAccordion
                        componentKey={processingAccordionKey}
                        project={props.currentProject}
                        billableUnit={props.currentBillableUnit}
                        onProcessingFinishedCallback={() => fetchRecordings()}
                        getUploads={getProcessingRecordings}
                        delete={deleteProcessingRecording}
                    />
                    {hasRecordings && <div>{recordingsSection()}</div>}
                    {!hasRecordings && (
                        <div className="text-center">
                            <NotFoundContaner
                                infoText="We couldn't find any recordings..."
                                secondaryText="Ready to upload your latest drive?"
                            />
                            {recordingDropzone()}
                        </div>
                    )}
                </>
            )
        }
    }

    const helpTextRecordingDetails = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${showHelpModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginTop: -90, marginLeft: 130 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-light text-start m-0">
                            Click here to play or to view <br /> details about this recording
                        </p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-2" />
                    </div>
                </div>
            </>
        )
    }

    const demoTextRecordingDetails = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${showDemoModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginTop: -95, marginLeft: 130 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-light text-start m-0 fs-6">
                            1. Click here to view details about <br />
                            this recording or to play it on a broker
                        </p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-2" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextUploadRecordingButton = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${showHelpModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginLeft: -200 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click this button to <br />
                            upload a recording
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextUploadRecordingDropzone = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex justify-content-center flex-column d-${showHelpModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginTop: 110, marginLeft: 100 }}
                >
                    <div className="d-flex flex-column  align-items-center">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0">
                            You can also click or drag a file <br />
                            here to upload a recording
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const upgradeToRealAccountMessage = () => {
        return (
            <>
                <Card
                    className="remotive-success-60-background rounded-4 border-0 remotive-white-color p-3"
                    style={{ maxWidth: 350 }}
                >
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <UnlockIcon sx={{ fontSize: 50 }} className="me-3" />
                        <div>
                            <p className="m-0 fs-6">
                                <b>Want more?</b>
                            </p>
                            <p className="m-0 remotive-font-md">
                                <a
                                    className="link-light link-offset-1 link-underline-opacity-75 link-underline-opacity-100-hover"
                                    href="https://console.cloud.remotivelabs.com"
                                >
                                    Sign up
                                </a>{' '}
                                for a free account to access all recordings and upload your own!
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        )
    }

    const demoMessage = () => {
        return (
            <HelpTextBox
                title={
                    <div className="text-start">
                        <p className="remotive-font-md mb-1 fw-bold">Here are some of our recorded drivecycles</p>
                        <p className="remotive-font-md mb-1 ">
                            Simply click a recording to replay it or to see more information about it.
                        </p>
                    </div>
                }
                bodyContent={
                    <div className="mt-1">
                        <p className={`text-start remotive-font-md m-0`}>1. Click an available recording</p>
                    </div>
                }
            />
        )
    }

    const getBody = () => {
        switch (pageState) {
            case PageState.LOADING:
                return <LoadingContainer spinnerSize="sm" />

            case PageState.UPLOAD_ERROR:
                return <ErrorContainer errorText="We couldn't upload your recording..." />

            case PageState.DELETE_ERROR:
                return <ErrorContainer errorText="We couldn't delete your recording..." />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch your recordings..." />

            case PageState.UPLOADING:
            case PageState.DELETING:
            case PageState.DONE:
                return (
                    <>
                        {isDemo() && demoMessage()}
                        {recordingsListBody()}
                        {isDemo() && (
                            <div className="d-flex justify-content-center mt-4">{upgradeToRealAccountMessage()}</div>
                        )}
                    </>
                )

            default:
                return <LoadingContainer spinnerSize="sm" />
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="d-flex align-items-center remotive-primary-100-color">
                        <RecordingsIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 mb-0 lexend-light text-truncate remotive-primary-100-color">Recordings</p>
                    </div>
                    <div>
                        {helpTextUploadRecordingButton()}
                        <button
                            title="Upload a recording"
                            style={highlightObject(showHelpModal)}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1 thin-white-border-for-dark-mode"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.UPLOAD_RECORDING)
                                uploadRecording()
                            }}
                        >
                            <UploadIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            title="Refresh list of recordings"
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1 thin-white-border-for-dark-mode"
                            onClick={() => {
                                setRecordings(undefined)
                                setRecordingsState()
                            }}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            title="Import sample recordings"
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary thin-white-border-for-dark-mode"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => {
                                productAnalyticsClient.track(AnalyticsActions.IMPORT_SAMPLE_RECORDING)
                                setShowImportSampleRecordingModal(true)
                            }}
                        >
                            <ImportExportIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const pageContent = () => {
        return (
            <>
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    {searchSection()}
                    <Card
                        className="shadow-sm remotive-white-background border-0 rounded-4 text-start mb-4"
                        style={{ minHeight: '400px' }}
                    >
                        <Card.Body className="pb-3 pt-3">{getBody()}</Card.Body>
                    </Card>
                </div>
                <CliHintContainer
                    productAnalyticsProperties={{
                        productAnalyticsContext: ProductAnalyticsContext.RECORDINGS_LIST,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    }}
                    hints={[
                        {
                            title: 'List recordings',
                            command: `remotive cloud recordings list --project ${props.currentProject?.uid}`,
                        },
                        {
                            title: 'List recordings but print only recording id and display name',
                            command: `remotive cloud recordings list --project ${props.currentProject?.uid} | jq -r '.[] | "\\(.sessionId) - \\(.displayName)"'`,
                        },
                        {
                            title: 'Upload a recording file',
                            subtitle: `We currently support ${ALLOWED_RECORDING_FILE_NAME_ENDINGS.join(', ')}.`,
                            command: `remotive cloud recordings upload ./my-candump_file.log --project ${props.currentProject?.uid}`,
                        },
                    ]}
                />
            </>
        )
    }

    const breadCrumbs = () => {
        const organisation = props.currentBillableUnit?.organisation
        const project = props.currentProject
        const trail = buAndProjectTrail(organisation, project)
        return {
            trail: trail,
            current: {
                title: 'Recordings',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                isDemoGuideActive={showDemoModal}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <NavbarAwareContainer content={pageContent()} />
            <HelpOverlayModalModal
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
                show={showHelpModal || showDemoModal}
            />
            <CopyRecordingSessionModal
                onModalClose={() => {
                    setShowCopyRecordingModal(false)
                    setRecordingSessionToCopy(undefined)
                }}
                show={showCopyRecordingModal}
                currentProject={props.currentProject}
                recordingSession={recordingSessionToCopy}
                projects={props.projects}
            />
            <ImportRecordingSessionModal
                show={showImportSampleRecordingModal}
                onModalClose={(imported: boolean) => {
                    setShowImportSampleRecordingModal(false)
                    if (imported) {
                        setRecordingsState()
                    }
                }}
                currentProject={props.currentProject}
                recordingsInProject={recordings}
                onRecordingImported={async () => setRecordingsState()}
            />
        </div>
    )
}
