import { OrganisationRoutes, ProjectRoutes } from "src/Routes"
import { Organisation, Project } from "../api/CloudApi/types"

export type BreadCrumbs = {
    trail: Array<BreadCrumb>
    current: BreadCrumb
}

export type BreadCrumb = {
    title: string
    route: string | undefined
}

export function buAndProjectTrail(organisation: Organisation | undefined, project: Project | undefined): BreadCrumb[] {
    if (organisation === undefined && project === undefined) {
        return []
    }
    const trail = [
        {
            title: organisation!.displayName + " /",
            route: OrganisationRoutes.toHome(organisation!.uid)
        } as BreadCrumb,
        {
            title: project!.displayName + " /",
            route: ProjectRoutes.toHome(project!.uid)
        } as BreadCrumb
    ]
    return trail
}
