import { Card, Container, Spinner } from 'react-bootstrap'

import {
    AuthenticatedUser,
    ProcessingUploadFile,
    Project,
    SignalDatabase,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import NavigationBar from '../components/navigation/NavigationBar'
import { useCallback, useEffect, useState } from 'react'
import CloudApi from '../api/CloudApi'
import {
    ArrowEastIcon,
    ArrowSouthIcon,
    ArrowWestIcon,
    ClickableArrowIcon,
    CloudUploadIcon,
    DeleteIcon,
    DownloadIcon,
    RefreshIcon,
    SignalDatabasesIcon,
    UploadIcon,
} from '../assets/Icons'
import { NavLink } from 'react-router-dom'
import LoadingContainer from '../components/LoadingContainer'
import ErrorContainer from '../components/ErrorContainer'
import { useRef } from 'react'
import NotFoundContaner from '../components/NotFoundContainer'
import { toast } from 'react-toastify'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { formatAsDatetime } from '../utils/datetime'
import HelpOverlayModalModal from '../components/modals/HelpOverlayModal'
import { isDemo, isGuidedDemo } from '../utils/demo'
import { hasPermission, Permission } from '../utils/permission'
import HelpTextBox from '../components/cards/DemoHelpCard'
import { OrganisationRoutes, ProjectSignalRoutes } from '../Routes'
import { BreadCrumb, BreadCrumbs, buAndProjectTrail } from '../types/BreadCrumbs'
import CliHintContainer from '../components/CliHintContainer'
import { PageDetails } from '../utils/pageDetails'
import { ConfirmDialogProperties } from '../types/ConfirmDialogProperties'
import { formattedToastMessage } from '../utils/toast'
import ProcessingUploadsAccordion from '../components/ProcessingUploadsAccordion'
import { ProductAnalyticsContext } from '../utils/ProductAnalytics'
import { storeRecentSignalDatabase } from '../types/RecentSignalDatabase'
import SignalDatabaseListItem from 'src/components/SignalDatabaseListItem'

interface SignalDatabaseListProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    billableUnits: Array<UserBillableUnitInfo>
    currentUser: AuthenticatedUser | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

enum PageState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
    UPLOAD_ERROR,
    DELETE_ERROR,
}

export default function SignalDatabaseList(props: SignalDatabaseListProps) {
    const dropzone = useRef<DropzoneRef | null>(null)
    const [processingAccordionKey, setProcessingAccordionKey] = useState<string>('processingSigdb')
    const [signalDatabases, setSignalDatabases] = useState<Array<SignalDatabase>>()
    const [requestState, setRequestState] = useState<PageState>(PageState.LOADING)
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())

    const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    useEffect(() => {
        if (props.currentProject?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Signal Databases - ${props.currentProject?.displayName || 'Unknown Project'}`,
                productAnalyticsEventName: 'Project Signal Databases',
            })
        }
    }, [props.currentProject])

    useEffect(() => {
        console.debug('Mounted signal database list page!')
    }, [])

    useEffect(() => {
        if (props.currentProject) {
            setSignalDatabasesState()
        }
    }, [props.currentProject])

    const setSignalDatabasesState = async () => {
        if (props.currentProject?.uid) {
            try {
                setRequestState(PageState.LOADING)
                const signalDatabases = (await CloudApi.listSignalDatabases(props.currentProject.uid)).data
                setSignalDatabases(signalDatabases)
                setRequestState(PageState.DONE)
            } catch (err) {
                setRequestState(PageState.ERROR)
            }
        }
    }

    const deleteProcessingRecording = async (processing: ProcessingUploadFile) => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            await CloudApi.deleteProcessingSigdbFile(projectUid, processing)
            await setSignalDatabasesState()
        }
    }

    const fetchProcessingSigsb = async () => {
        if (props.currentProject) {
            const processing = await CloudApi.listProcessingSigdbFiles(props.currentProject.uid)
            return processing.data
        }
    }

    const setSignalDatabasesStateWithoutLoading = async () => {
        if (props.currentProject?.uid) {
            const sigdbs = (await CloudApi.listSignalDatabases(props.currentProject.uid)).data
            setSignalDatabases(sigdbs)
        }
    }

    const handleSignalDatabaseUpload = async (fileToUpload: File | undefined) => {
        const file = fileToUpload
        const projectUid = props.currentProject?.uid
        if (file && projectUid) {
            try {
                setRequestState(PageState.UPLOADING)
                await CloudApi.uploadSignalDatabase(projectUid, file)
                setSignalDatabasesState()
            } catch (err) {
                setRequestState(PageState.UPLOAD_ERROR)
            }
        }
    }

    const deleteSignalDatabase = async (fileName: string) => {
        if (props.currentProject?.uid) {
            try {
                setRequestState(PageState.DELETING)
                await CloudApi.deleteSignalDatabase(props.currentProject.uid, fileName)
                toast.success(formattedToastMessage('Success', `Successfully deleted the Signal database ${fileName}`))
                setSignalDatabasesState()
            } catch (err) {
                setRequestState(PageState.DELETE_ERROR)
                toast.error(formattedToastMessage('Error', 'Failed to delete signal database'))
            }
        }
    }

    const uploadSignalDatabase = () => {
        if (dropzone.current) {
            dropzone.current.open()
        }
    }

    const downloadSignalDatabase = async (fileName: string) => {
        if (props.currentProject?.uid) {
            try {
                const downloadUrl = (await CloudApi.downloadSignalDatabase(props.currentProject.uid, fileName)).data
                window.location.replace(downloadUrl)
            } catch (err: any) {
                toast.error('Failed to download signal database')
            }
        }
    }

    const signalDatabaseDropzone = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_CONFIG, props.currentBillableUnit, props.currentProject)) {
            return (
                <div className="d-flex" style={{ height: '47px !important', ...HIGHLIGHT_HELP_OBJECT_STYLE }}>
                    {helpTextUploadSignalDbDropzone()}
                    <Dropzone
                        ref={dropzone}
                        multiple={false}
                        onDrop={(acceptedFiles) => handleSignalDatabaseUpload(acceptedFiles[0])}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                className="col-12 dropzone p-1 remotive-primary-0-background rounded-2 w-100 "
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                                    <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} /> Drag a file here or click
                                    to <b>upload a signal database</b>
                                </p>
                            </div>
                        )}
                    </Dropzone>
                </div>
            )
        }
        return <></>
    }

    const helpTextUploadSignalDbDropzone = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex justify-content-center flex-column d-${showHelpModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginTop: -90, marginLeft: 100 }}
                >
                    <div className="d-flex flex-column  align-items-center">
                        <p className="text-light text-start m-0">
                            You can also click or drag a file <br />
                            here to upload a signal database
                        </p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="me-3" />
                    </div>
                </div>
            </>
        )
    }


    const signalDatabaseList = () => {
        if (signalDatabases !== undefined) {
            if (signalDatabases.length > 0) {
                return (
                    <>
                        {signalDatabases.map((file) => {
                            return (
                                <SignalDatabaseListItem
                                    key={`signal-database-item-${file.name}`}
                                    sdb={file}
                                    project={props.currentProject}
                                    billableUnit={props.currentBillableUnit}
                                    onDownload={() => { downloadSignalDatabase(file.name) }}
                                    onDelete={() => props.openConfirmationDialog({
                                        dialogTitle: 'Are you sure?',
                                        bodyText: (
                                            <>
                                                Are you sure you want to delete the signal database
                                                file <b>{file.name}</b>?
                                            </>
                                        ),
                                        bodySubtitle: 'You can not undo this action.',
                                        confirmButtonText: 'Yes, delete it',
                                        cancelButtonText: 'No, cancel',
                                        handleCancelFunction: () => console.log,
                                        handleConfirmFunction: () =>
                                            deleteSignalDatabase(file.name),
                                    } as ConfirmDialogProperties)}
                                />
                            )
                        })}
                        {signalDatabaseDropzone()}
                    </>
                )
            }
            return (
                <div className="text-center">
                    <NotFoundContaner
                        infoText="We couldn't find any signal databases..."
                        secondaryText="Be the first to upload a signal database file!"
                    />
                    {signalDatabaseDropzone()}
                </div>
            )
        }
        return <LoadingContainer spinnerSize="sm" />
    }

    const demoTextGoBack = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${showDemoModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginLeft: -10, marginTop: 100 }}
                >
                    <div className="d-flex align-items-center">
                        <ArrowWestIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0 fs-6">1. Go here to choose a recording to play</p>
                    </div>
                </div>
            </>
        )
    }

    const helpTextUploadSignalDbButton = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${showHelpModal ? 'block' : 'none'
                        }`}
                    style={{ zIndex: '9999', marginLeft: -240 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click this button to <br />
                            upload a signal database
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const demoMessage = () => {
        return (
            <HelpTextBox
                title={
                    <div className="text-start">
                        <p className="remotive-font-md mb-1">
                            <b>This is where all the signal databases will end up.</b> To make it possible for our
                            Remotive Broker to replay a recorded drivecycle, you will need to upload the appropriate
                            signal database here.
                        </p>
                        <p className="remotive-font-sm m-0">
                            We have already uploaded the appropriate signal database for the demo recording, you don't
                            have to do anything here.
                        </p>
                    </div>
                }
            />
        )
    }

    const getBody = () => {
        switch (requestState) {
            case PageState.LOADING:
                return <LoadingContainer spinnerSize="sm" />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch your signal databases..." />

            case PageState.UPLOAD_ERROR:
                return <ErrorContainer errorText="We couldn't upload your signal databases..." />

            case PageState.DELETE_ERROR:
                return <ErrorContainer errorText="We couldn't delete your signal databases..." />

            case PageState.DONE:
                return (
                    <>
                        {isDemo() && demoMessage()}
                        <ProcessingUploadsAccordion
                            componentKey={processingAccordionKey}
                            project={props.currentProject!}
                            billableUnit={props.currentBillableUnit!}
                            onProcessingFinishedCallback={() => setSignalDatabasesStateWithoutLoading()}
                            getUploads={fetchProcessingSigsb}
                            delete={deleteProcessingRecording}
                        />
                        {signalDatabaseList()}
                    </>
                )

            case PageState.UPLOADING:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Uploading signal database file...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            case PageState.DELETING:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Deleting signal database file...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            default:
                return <LoadingContainer spinnerSize="sm" />
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="d-flex align-items-center remotive-primary-100-color">
                        <SignalDatabasesIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 lexend-light text-truncate m-0">Signal databases</p>
                    </div>
                    <div>
                        {helpTextUploadSignalDbButton()}
                        <button
                            style={HIGHLIGHT_HELP_OBJECT_STYLE}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1 thin-white-border-for-dark-mode"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_CONFIG,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => {
                                uploadSignalDatabase()
                            }}
                        >
                            <UploadIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary thin-white-border-for-dark-mode"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_VIEWER_CONFIG,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => {
                                setSignalDatabases(undefined)
                                setSignalDatabasesState()
                            }}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        const organisation = props.currentBillableUnit?.organisation
        const project = props.currentProject
        const trail = buAndProjectTrail(organisation, project)
        return {
            trail: trail,
            current: {
                title: 'Signal databases',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                zIndexOnTop={showDemoModal}
                isDemoGuideActive={showDemoModal}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                {demoTextGoBack()}
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <Card
                        className="shadow-sm remotive-white-background border-0 rounded-4 text-start mb-4"
                        style={{ minHeight: '400px' }}
                    >
                        <Card.Body className="pb-3 pt-3">{getBody()}</Card.Body>
                    </Card>
                </div>
                <CliHintContainer
                    productAnalyticsProperties={{
                        productAnalyticsContext: ProductAnalyticsContext.SIGNAL_DATABASE_LIST,
                        currentBillableUnit: props.currentBillableUnit,
                        currentUser: props.currentUser,
                    }}
                    hints={[
                        {
                            title: 'List signal databases',
                            command: `remotive cloud signal-databases list --project ${props.currentProject?.uid}`,
                        },
                    ]}
                />
            </Container>

            {/* Modals below */}
            <HelpOverlayModalModal
                show={showHelpModal || showDemoModal}
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
            />
        </div>
    )
}
