import { FileObject, FileObjectsContainer, FileObjectTypeEnum, Project } from 'src/api/CloudApi/types'
import { LayoutType, SortingType } from '.'
import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { ComponentState } from 'src/types/ComponentState'
import { toast } from 'react-toastify'
import CloudApi from 'src/api/CloudApi'
import { formattedToastMessage } from 'src/utils/toast'
import FileObjectCard from './FileObjectCard'
import { formatBytes } from 'src/utils/formatting'
import Toolbar from './Toolbar'

interface FilesListProps {
    fileObjectsContainer: FileObjectsContainer
    currentLayoutType: LayoutType
    project: Project
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
    selectedFileObjects: Set<FileObject>
    setSelectedFileObjects: React.Dispatch<React.SetStateAction<Set<FileObject>>>
    setComponentState: (state: ComponentState) => void
    listFiles: (path: string) => void
    setShowBanner: (show: boolean) => void
    clickResource: (path: string) => void
    isAllSelected: boolean
    setSearchKey: (key: string | undefined) => void
    searchKey: string | undefined
    setSortingType: (sortingType: SortingType) => void
    sortingType: SortingType
}

export default function FilesList(props: FilesListProps) {
    const ICON_SIZE = props.currentLayoutType ? 16 : 22

    const doDeleteSelectedFiles = (
        fileObjectsContainer: FileObjectsContainer,
        setComponentState: (state: ComponentState) => void,
        listFiles: (path: string) => void
    ) => {
        const pathWhenStarting = fileObjectsContainer.path
        const deleteIt = async () => {
            setComponentState(ComponentState.LOADING)
            try {
                for (const fileObject of Array.from(props.selectedFileObjects)) {
                    const path =
                        fileObject.type === FileObjectTypeEnum.DIRECTORY ? `${fileObject.path}` : fileObject.path
                    const deleteResponse = await CloudApi.deleteStorageFile(props.project, path)
                }
                if (pathWhenStarting === fileObjectsContainer.path) {
                    listFiles(fileObjectsContainer.path)
                }
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to delete selected file, please try again later.')
                )
            }
            setComponentState(ComponentState.DONE)
        }

        deleteIt()
    }

    const deleteFile = (fileObject: FileObject, project: Project) => {
        const deleteIt = async () => {
            props.setComponentState(ComponentState.LOADING)
            try {
                const path = fileObject.path
                const deleteResponse = await CloudApi.deleteStorageFile(project, path)
                if (deleteResponse.status < 300) {
                    toast.success(`Successfully deleted ${fileObject.name}!`)
                    props.listFiles(path.split('/').slice(0, -1).join('/')) // Remove everything after the last '/' to list files in parent
                }
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to delete selected file, please try again later.')
                )
            }
            props.setComponentState(ComponentState.DONE)
        }

        deleteIt()
    }

    const importFileAsRecording = async (fileObject: FileObject) => {
        props.setShowBanner(true)
        try {
            await CloudApi.importStorageFileAsRecording(props.project, fileObject.path)
        } catch (e: any) {
            toast.error(formattedToastMessage('Import failed', 'Failed to import file as recording'))
        }
    }

    const downloadFile = (fileObject: FileObject, project: Project) => {
        const download = async () => {
            try {
                props.setComponentState(ComponentState.LOADING)
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)
                const fileContents = await fetch(downloadUrl.data)
                const blob = await fileContents.blob()
                // Download file by creating a link, faking a click on it, and then removing the link again
                const link = document.createElement('a')
                link.href = URL.createObjectURL(blob)
                link.download = fileObject.name
                link.click()
                link.parentNode?.removeChild(link)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
            props.setComponentState(ComponentState.DONE)
        }

        download()
    }

    const sortFilesByUploaded = (objectA: FileObject, objectB: FileObject, descendingOrder: boolean) => {
        return ((objectA.uploaded || '').localeCompare(objectB.uploaded || '') || 0) * (descendingOrder ? -1 : 1)
    }

    const sortFilesByName = (objectA: FileObject, objectB: FileObject, descendingOrder: boolean) => {
        return (objectA.name.localeCompare(objectB.name || '') || 0) * (descendingOrder ? -1 : 1)
    }

    const sortFilesBySize = (objectA: FileObject, objectB: FileObject, descendingOrder: boolean) => {
        return ((objectA.size || 0) - (objectB.size || 0)) * (descendingOrder ? -1 : 1)
    }

    const shareLink = (fileObject: FileObject, project: Project) => {
        try {
            navigator.clipboard.writeText(
                `${window.location.origin}/p/${project.uid}/files?path=${encodeURI(fileObject.path)}`
            )
            toast.success('Link copied to clipboard')
        } catch (e: any) {
            toast.error('Failed to copy link to clipboard')
        }
    }

    const toggleAllSelected = (
        markAsSelected: boolean,
        fileObjectsContainer: FileObjectsContainer,
        setSelectedFileObjects: (selected: Set<FileObject>) => void
    ) => {
        if (markAsSelected) {
            if (fileObjectsContainer.objects && fileObjectsContainer.objects.length > 0) {
                setSelectedFileObjects(new Set(fileObjectsContainer.objects))
            }
        } else {
            setSelectedFileObjects(new Set())
        }
    }

    const selectedFileObjectSelectionChanged = (
        fileObject: FileObject,
        selectedFileObjects: Set<FileObject>,
        setSelectedFileObjects: React.Dispatch<React.SetStateAction<Set<FileObject>>>
    ) => {
        const isSelected = Array.from(selectedFileObjects).filter((f) => f.path === fileObject.path).length > 0
        if (!isSelected) {
            setSelectedFileObjects((prev) => {
                const newSet = new Set(prev) // Create a new Set from previous state
                newSet.add(fileObject) // Add the new item
                return newSet // Update state with the new Set
            })
        } else {
            setSelectedFileObjects((prev) => {
                const newSet = new Set(prev) // Create a new Set from previous state
                newSet.delete(fileObject) // Add the new item
                return newSet // Update state with the new Set
            })
        }
    }

    const fileCard = (
        fileObject: FileObject,
        project: Project,
        selectedFileObjects: Set<FileObject>,
        setSelectedFileObjects: React.Dispatch<React.SetStateAction<Set<FileObject>>>,
        clickResource: (path: string) => void,
        currentLayoutType: LayoutType
    ) => {
        if (props.project === undefined) {
            return <></>
        }
        return (
            <FileObjectCard
                title={fileObject.name}
                subtitle={formatBytes(fileObject.size ?? 0)}
                uploaded={fileObject.uploaded}
                cardClassName={'remotive-primary-10-background'}
                moreButtonClassName={'remotive-primary-100-color'}
                isDirectory={false}
                onClickFunction={() => clickResource(fileObject.path)}
                onCheckFunction={() =>
                    selectedFileObjectSelectionChanged(fileObject, selectedFileObjects, setSelectedFileObjects)
                }
                isChecked={Array.from(selectedFileObjects).filter((f) => f.path === fileObject.path).length > 0}
                onDownloadFunction={() => downloadFile(fileObject, project)}
                onDeleteFunction={() =>
                    props.openConfirmationDialog({
                        dialogTitle: 'Are you sure?',
                        bodyText: (
                            <>
                                Are you sure you want to delete the file <b>{fileObject.name}?</b>
                            </>
                        ),
                        bodySubtitle: 'You can not undo this action.',
                        confirmButtonText: 'Yes, delete it',
                        cancelButtonText: 'No, cancel',
                        handleCancelFunction: () => console.log,
                        handleConfirmFunction: () => deleteFile(fileObject, project),
                    } as ConfirmDialogProperties)
                }
                onImportFunction={() => importFileAsRecording(fileObject)}
                onCopyLinkFunction={() => shareLink(fileObject, project)}
                fileObject={fileObject}
                project={project}
                currentLayoutType={currentLayoutType}
                ICON_SIZE={ICON_SIZE}
            />
        )
    }

    const unknownFileCard = (fileObject: FileObject, project: Project, currentLayoutType: LayoutType) => {
        return (
            <FileObjectCard
                title={fileObject.name}
                subtitle={'Unknown file'}
                uploaded={undefined}
                cardClassName={'bg-danger-subtle'}
                moreButtonClassName={'text-danger'}
                isDirectory={false}
                onClickFunction={() => unknownFileToast()}
                onCheckFunction={() => unknownFileToast()}
                isChecked={false}
                onDownloadFunction={() => unknownFileToast()}
                onDeleteFunction={() => unknownFileToast()}
                onImportFunction={() => unknownFileToast()}
                onCopyLinkFunction={() => unknownFileToast()}
                fileObject={fileObject}
                project={project}
                currentLayoutType={currentLayoutType}
                ICON_SIZE={ICON_SIZE}
            />
        )
    }

    const unknownFileToast = () => {
        toast.error(
            formattedToastMessage('File error', 'This file is unknown, please reach out to support@remotivelabs.com.')
        )
    }

    const directoryFileCard = (
        fileObject: FileObject,
        project: Project,
        selectedFileObjects: Set<FileObject>,
        setSelectedFileObjects: React.Dispatch<React.SetStateAction<Set<FileObject>>>,
        clickResource: (path: string) => void,
        currentLayoutType: LayoutType
    ) => {
        return (
            <FileObjectCard
                title={fileObject.name}
                subtitle={'Directory'}
                uploaded={undefined}
                cardClassName={'remotive-success-20-background'}
                moreButtonClassName={'remotive-success-100-color'}
                isDirectory={true}
                onClickFunction={() => clickResource(fileObject.path)}
                onCheckFunction={() =>
                    selectedFileObjectSelectionChanged(fileObject, selectedFileObjects, setSelectedFileObjects)
                }
                isChecked={Array.from(selectedFileObjects).filter((f) => f.path === fileObject.path).length > 0}
                onDownloadFunction={() =>
                    toast.error(formattedToastMessage('File error', 'It is not possible to download a directory.'))
                }
                onDeleteFunction={() =>
                    props.openConfirmationDialog({
                        dialogTitle: 'Are you sure?',
                        bodyText: (
                            <>
                                Are you sure you want to delete the directory{' '}
                                <b>{fileObject.name} and all files in it?</b>
                            </>
                        ),
                        bodySubtitle: 'You can not undo this action.',
                        confirmButtonText: 'Yes, delete it',
                        cancelButtonText: 'No, cancel',
                        handleCancelFunction: () => console.log,
                        handleConfirmFunction: () => deleteFile(fileObject, project),
                    } as ConfirmDialogProperties)
                }
                onImportFunction={() =>
                    toast.error(formattedToastMessage('File error', 'It is not possible to import a directory.'))
                }
                onCopyLinkFunction={() => shareLink(fileObject, project)}
                fileObject={fileObject}
                project={project}
                currentLayoutType={currentLayoutType}
                ICON_SIZE={ICON_SIZE}
            />
        )
    }

    const sortBySortingType = (fileA: FileObject, fileB: FileObject, sortingType: SortingType) => {
        switch (sortingType) {
            case SortingType.UPLOADED_ASC:
                return sortFilesByUploaded(fileA, fileB, false)
            case SortingType.UPLOADED_DESC:
                return sortFilesByUploaded(fileA, fileB, true)
            case SortingType.NAME_ASC:
                return sortFilesByName(fileA, fileB, false)
            case SortingType.NAME_DESC:
                return sortFilesByName(fileA, fileB, true)
            case SortingType.SIZE_ASC:
                return sortFilesBySize(fileA, fileB, false)
            case SortingType.SIZE_DESC:
                return sortFilesBySize(fileA, fileB, true)
        }
    }

    const renderFileObjectList = (
        filesToRender: Array<FileObject>,
        currentLayoutType: LayoutType,
        project: Project,
        selectedFileObjects: Set<FileObject>,
        setSelectedFileObjects: React.Dispatch<React.SetStateAction<Set<FileObject>>>,
        clickResource: (path: string) => void
    ) => {
        return (
            <div
                style={{
                    display: currentLayoutType === LayoutType.CARDS ? 'grid' : undefined,
                    gridTemplateColumns:
                        currentLayoutType === LayoutType.CARDS ? 'repeat(auto-fill, minmax(500px, 1fr))' : undefined,
                }}
            >
                {filesToRender.map((it) => (
                    <div key={it.path} style={{ display: 'flex', flexDirection: 'column' }}>
                        {it.type === FileObjectTypeEnum.DIRECTORY
                            ? directoryFileCard(
                                  it,
                                  project,
                                  selectedFileObjects,
                                  setSelectedFileObjects,
                                  clickResource,
                                  currentLayoutType
                              )
                            : it.type === FileObjectTypeEnum.FILE
                            ? fileCard(
                                  it,
                                  project,
                                  selectedFileObjects,
                                  setSelectedFileObjects,
                                  clickResource,
                                  currentLayoutType
                              )
                            : unknownFileCard(it, project, currentLayoutType)}
                    </div>
                ))}
            </div>
        )
    }

    const component = () => {
        if (props.project === undefined) {
            return <></>
        }

        const hasChildren = props.fileObjectsContainer.objects && props.fileObjectsContainer.objects.length > 0
        const allFiles = props.fileObjectsContainer.objects?.filter((file) => file.path !== '//') ?? []
        const filesToRender = hasChildren
            ? allFiles
                  .sort((fileA, fileB) => sortBySortingType(fileA, fileB, props.sortingType))
                  .filter((it) =>
                      props.searchKey !== undefined
                          ? it.name.toLowerCase().includes(props.searchKey.toLowerCase())
                          : true
                  )
            : [props.fileObjectsContainer]
        const searchStatus =
            props.searchKey !== undefined ? `Showing ${filesToRender.length} of ${allFiles.length} results` : undefined
        return (
            <>
                <div
                    className={`text-start rounded-3 ${
                        props.currentLayoutType === LayoutType.LIST ? 'flex-grow-1 px-2 ' : 'px-2 m-1'
                    }`}
                    style={{ marginBottom: 3 }}
                >
                    {' '}
                    <div className="">
                        {props.fileObjectsContainer.objects && props.fileObjectsContainer.objects.length > 0 && (
                            <>
                                <Toolbar
                                    onSelectAll={() =>
                                        toggleAllSelected(
                                            !props.isAllSelected,
                                            props.fileObjectsContainer,
                                            props.setSelectedFileObjects
                                        )
                                    }
                                    onDeleteSelected={() =>
                                        props.openConfirmationDialog({
                                            dialogTitle: 'Are you sure?',
                                            bodyText: (
                                                <>
                                                    Are you sure you want to <b>delete</b> the{' '}
                                                    <b>{props.selectedFileObjects.size} selected</b> files & directories
                                                    and any files within those directories?
                                                </>
                                            ),
                                            bodySubtitle: 'You can not undo this action.',
                                            confirmButtonText: 'Yes, delete',
                                            cancelButtonText: 'No, cancel',
                                            handleCancelFunction: () => console.log,
                                            handleConfirmFunction: () =>
                                                doDeleteSelectedFiles(
                                                    props.fileObjectsContainer,
                                                    props.setComponentState,
                                                    props.listFiles
                                                ),
                                        } as ConfirmDialogProperties)
                                    }
                                    onDownloadSelected={() => console.log('Download')}
                                    onReset={() =>
                                        toggleAllSelected(
                                            false,
                                            props.fileObjectsContainer,
                                            props.setSelectedFileObjects
                                        )
                                    }
                                    selectedFiles={props.selectedFileObjects}
                                    setSortingType={props.setSortingType}
                                    sortingType={props.sortingType}
                                    setSearchKey={props.setSearchKey}
                                    searchKey={props.searchKey}
                                    searchStatus={searchStatus}
                                />
                            </>
                        )}
                    </div>
                </div>
                <div className={`${props.currentLayoutType === LayoutType.LIST ? 'col-12 px-2' : ' mx-2'}  mt-1 mb-2`}>
                    {renderFileObjectList(
                        filesToRender,
                        props.currentLayoutType,
                        props.project,
                        props.selectedFileObjects,
                        props.setSelectedFileObjects,
                        props.clickResource
                    )}
                </div>
            </>
        )
    }

    return component()
}
