import { Form, Spinner } from 'react-bootstrap'
import { SignalEntry } from '../../../../../api/CloudApi/types'
import CircleRounded from '@mui/icons-material/CircleRounded'
import { useState } from 'react'
import { ComponentState } from '../../../../../types/ComponentState'

interface SignalInChartLabelProps {
    isSignalInGraph: boolean
    isSignalHidden: boolean
    htmlColor: string
    constructSignalNameKeyFromSignalEntry: (signalEntry: SignalEntry) => string
    hiddenSignals: Array<SignalEntry>
    setHiddenSignals: (hiddenSignals: Array<SignalEntry>) => void
    signal: SignalEntry
}

export default function SignalInChartLabel(props: SignalInChartLabelProps) {
    const toggleSignalVisibilty = (signalEntry: SignalEntry, isChecked: boolean) => {
        if (isChecked) {
            props.setHiddenSignals(
                props.hiddenSignals.filter(
                    (it) =>
                        props.constructSignalNameKeyFromSignalEntry(it) !==
                        props.constructSignalNameKeyFromSignalEntry(signalEntry)
                )
            )
        } else {
            props.setHiddenSignals([...props.hiddenSignals, signalEntry])
        }
    }

    const signalInChartLabel = () => {
        return (
            <div
                className={`rounded-3 ${
                    props.isSignalInGraph ? 'remotive-primary-20-background' : 'remotive-neutral-40-background'
                } p-0 ps-1`}
            >
                <div className="d-flex align-items-center justify-content-between">
                    <Form.Check
                        disabled={!props.isSignalInGraph}
                        checked={props.isSignalInGraph && props.isSignalHidden}
                        onChange={(e) => toggleSignalVisibilty(props.signal, e.target.checked)}
                    />
                    <div className="d-flex align-items-center ms-1">
                        <div className="d-flex flex-column">
                            <p className="m-0 ms-1 remotive-font-xs ">{props.signal.name}</p>
                            <div className="d-flex flex-row" style={{ marginTop: -3 }}>
                                <p className="remotive-secondary-color ms-1 m-0 remotive-font-xxs">{`${
                                    props.signal.unit || 'unitless'
                                }`}</p>{' '}
                                <p className="remotive-secondary-color ms-1 m-0 remotive-font-xxs">{`${
                                    props.signal.namespace ?? 'N/A'
                                }`}</p>
                            </div>
                        </div>
                        <CircleRounded className="mx-1" sx={{ fontSize: 16 }} htmlColor={props.htmlColor} />
                    </div>
                </div>
                <div></div>
            </div>
        )
    }

    return signalInChartLabel()
}
