import zIndex from '@mui/material/styles/zIndex'
import { ReactElement, useEffect } from 'react'
import { Modal } from 'react-bootstrap'

interface ConfirmDialogProps {
    show: boolean
    body?: ReactElement
    bodyText?: ReactElement
    bodySubtitle?: string
    title?: string
    confirmButtonText?: string
    cancelButtonText?: string
    handleConfirmFunction: Function
    handleCancelFunction: Function
    handleCloseFunction: () => void
}

/**
 * A confirm dialog that will execute either handleConfirmFunction or handleCancelFunction, and finally handleCloseFunction.
 * You shouldn't use both 'body' and 'bodyText' & 'bodySubtitle', it is either or. The ReacElement 'body' is given priority if both are provided.
 * @param props
 * @returns
 */
export default function ConfirmDialog(props: ConfirmDialogProps) {
    useEffect(() => {
        console.debug('Mounted ConfirmDialog!')
    }, [])

    const handleConfirm = () => {
        props.handleConfirmFunction()
        props.handleCloseFunction()
    }

    const handleCancel = () => {
        props.handleCancelFunction()
        props.handleCloseFunction()
    }

    return (
        <>
            <Modal
                className="m-0 p-0 border-0 max-z-index"
                centered={true}
                show={props.show}
                onHide={handleCancel}
                contentClassName="border-0 rounded-4 max-z-index"
                backdropClassName="opacity-75 remotive-primary-90-background max-z-index"
            >
                <Modal.Header className="border-0 lexend-bold remotive-font-xl">
                    {props.title ?? 'Are you sure?'}
                </Modal.Header>
                <Modal.Body className="lexend-regular p-3 mt-2 mb-2 pt-3">
                    <div className="d-flex flex-column align-items-center">
                        <>
                            {props.bodyText && (
                                <>
                                    <div style={{ maxWidth: 420 }}>
                                        <p className="m-0 remotive-font text-start text-break">{props.bodyText}</p>
                                        {props.bodySubtitle && (
                                            <p className="m-0 remotive-font-md remotive-secondary-color text-start">
                                                {props.bodySubtitle}
                                            </p>
                                        )}
                                    </div>
                                </>
                            )}
                        </>
                        <>
                            {!props.bodySubtitle &&
                                !props.bodyText &&
                                (props.body ?? 'Are you sure you want to perform this action?')}
                        </>
                        <div className="mt-5 mb-3 d-flex justify-content-center flex-row flex-wrap">
                            <button onClick={handleCancel} className="btn remotive-btn remotive-btn-primary m-2">
                                {props.cancelButtonText ?? 'No'}
                            </button>
                            <button onClick={handleConfirm} className="btn remotive-btn remotive-btn-success m-2">
                                {props.confirmButtonText ?? 'Yes'}
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
