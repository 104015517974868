import React, { useState } from 'react'
import { formatNumberWithMetricPrefix } from '../../../../utils/formatNumberWithMetricPrefix'
import { AuthenticatedUser, Project, RecordingAnnotation, RecordingSession } from '../../../../api/CloudApi/types'
import {
    addDurationToIso8061Timestamp,
    formatAsDateTimeWithMonthAsWord,
    formatAsHHMMSSmmm,
} from '../../../../utils/datetime'
import ChatBubbleRounded from '@mui/icons-material/ChatBubbleRounded'
import AnnotationComponentAsModal from './AnnotationComponentAsModal'
import './AnnotationsOverview.css' // Custom CSS for this component
import { getAnnotationCreatedByLabel, getAnnotationTimestampRelativeToGraph } from '../AnnotationHelpers'

interface AnnotationsOverviewProps {
    currentUser: AuthenticatedUser | undefined
    currentProject: Project | undefined
    currentRecordingSession: RecordingSession | undefined

    safeFetchAndSetAvailableAnnotations: () => Promise<Array<RecordingAnnotation>>
    availableAnnotations: Array<RecordingAnnotation>
}

export default function AnnotationsOverview(props: AnnotationsOverviewProps) {
    const [showAnnotationComponentModal, setShowAnnotationComponentModal] = useState<boolean>(false)
    const [selectedAnnotation, setSelectedAnnotation] = useState<RecordingAnnotation | undefined>(undefined)

    const annotationOverviewItem = (annotation: RecordingAnnotation) => {
        return (
            <div className="remotive-primary-10-background rounded-3 p-1 ps-2 mx-1 mb-2">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-column align-items-start">
                        <div className="d-flex w-100 justify-content-between">
                            <div className="flex-truncate">
                                <p className="remotive-font-sm mb-0 text-nowrap text-truncate">
                                    <b>{annotation.title}</b>
                                </p>
                                <p className="remotive-font-xs mb-0">{getAnnotationCreatedByLabel(annotation)}</p>
                            </div>
                            <button
                                onClick={() => {
                                    setSelectedAnnotation(annotation)
                                    setShowAnnotationComponentModal(true)
                                }}
                                className="d-flex align-items-center btn remotive-btn-sm remotive-btn-primary ms-5 me-1"
                            >
                                <p className="mb-0 me-2">{annotation.comments.length}</p>
                                <ChatBubbleRounded sx={{ fontSize: 11 }} />
                            </button>
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <p className="m-0 remotive-font-xs remotive-secondary-color">
                            {getAnnotationTimestampRelativeToGraph(annotation)}
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="my-3">
                <div className="ms-1">
                    <p className="mb-2 remotive-font-md lexend-bold">Recording annotations</p>
                </div>
                <div className="d-flex flex-wrap mx-1">
                    {props.availableAnnotations
                        .sort((a, b) => a.created.localeCompare(b.created))
                        .map((it) => (
                            <div key={`${it.id}-${it.title}-${it.timestamp}`} className="overview-annotation-item">
                                {annotationOverviewItem(it)}
                            </div>
                        ))}
                </div>
            </div>

            <AnnotationComponentAsModal
                show={showAnnotationComponentModal}
                closeModal={() => setShowAnnotationComponentModal(false)}
                selectedAnnotation={selectedAnnotation}
                currentUser={props.currentUser}
                currentProject={props.currentProject}
                currentRecordingSession={props.currentRecordingSession}
                refreshAnnotation={async () => {
                    const annotations = await props.safeFetchAndSetAvailableAnnotations()
                    setSelectedAnnotation(annotations.find((it) => it.timestamp === selectedAnnotation?.timestamp))
                }}
                onDelete={async () => {
                    await props.safeFetchAndSetAvailableAnnotations()
                    setShowAnnotationComponentModal(false)
                    setSelectedAnnotation(undefined)
                }}
            />
        </>
    )
}
