import React, { useEffect, useState } from 'react'
import { InputGroup, Dropdown, Form } from 'react-bootstrap'
import { UserInfoWithRoles } from 'src/api/CloudApi/types'

interface ProjectUsersComboBoxProps {
    currentBuUsers: Array<UserInfoWithRoles>
    currentProjectUsers: Array<UserInfoWithRoles>
    selectedUser: UserInfoWithRoles | undefined
    setSelectedUser: Function
}

export default function ProjectUsersComboBox(props: ProjectUsersComboBoxProps) {
    const [searchValue, setSearchValue] = useState('')
    const [showDropdown, setShowDropdown] = useState(false)
    const [usersToSearchAmong, setUsersToSearchAmong] = useState<Array<UserInfoWithRoles>>([])

    useEffect(() => {
        setUsersToSearchAmong(
            props.currentBuUsers
                .filter(
                    // Check if current BU user is already in project
                    (buUser) =>
                        !props.currentProjectUsers.map((projectUser) => projectUser.user.uid).includes(buUser.user.uid)
                )
                .sort((a, b) => a.user.email.localeCompare(b.user.email))
        )
    }, [props.currentBuUsers, props.currentProjectUsers])

    // Filter users based on the search input
    const filteredUsers = usersToSearchAmong.filter((userWithRoles) =>
        userWithRoles.user.email.toLowerCase().includes(searchValue.toLowerCase())
    )

    const handleSelect = (user: UserInfoWithRoles) => {
        props.setSelectedUser(user)
        setShowDropdown(false)
    }

    return (
        <InputGroup size="sm">
            <InputGroup.Text className="remotive-font-md bg-transparent border-0 w-25 h-100">Email</InputGroup.Text>
            <div className="remotive-font-md w-75 position-relative">
                <Form.Control
                    type="text"
                    placeholder="Type to search..."
                    className="remotive-font-md bg-transparent border-light-gray remotive-dark-color rounded-1 dark-and-light-placeholder w-100"
                    value={props.selectedUser?.user.email ?? searchValue}
                    onChange={(e) => {
                        if (props.selectedUser !== undefined){
                            props.setSelectedUser(undefined)
                        }
                        setSearchValue(e.target.value)
                        setShowDropdown(true) // Show dropdown while typing
                    }}
                    onFocus={() => setShowDropdown(true)} // Show dropdown on focus
                    onBlur={() => setTimeout(() => setShowDropdown(false), 200)} // Delay closing for select click
                />
                {showDropdown && (
                    <Dropdown.Menu
                        show
                        className="remotive-font-md pb-1 pt-1 w-100 position-absolute"
                        style={{
                            minWidth: 100,
                            maxWidth: '100%',
                            maxHeight: '30vh',
                            overflowY: 'auto',
                        }}
                    >
                        {filteredUsers.length > 0 ? (
                            filteredUsers.map((userInfoWithRoles) => (
                                <Dropdown.Item
                                    key={userInfoWithRoles.user.email}
                                    onClick={() => handleSelect(userInfoWithRoles)}
                                    className="text-truncate"
                                >
                                    {userInfoWithRoles.user.email}
                                </Dropdown.Item>
                            ))
                        ) : (
                            <Dropdown.Item disabled>No users found</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                )}
            </div>
        </InputGroup>
    )
}
