import Highcharts from 'highcharts/highstock'
import HighchartsBoost from 'highcharts/modules/boost'
import CloudApi from '../../../../../api/CloudApi'
import { ReactElement, useEffect, useState } from 'react'
import {
    Project,
    RecordingSession,
    FrameList,
    RecordingAnnotation,
    FrameEntry,
    SignalEntry,
    Signal,
    AuthenticatedUser,
} from '../../../../../api/CloudApi/types'
import {
    GraphSettings,
    PanelKey,
    PlottableSignalEntry,
    SharedExtremes,
    TimeSeriesPanel,
    TraceWindowPanel,
} from '../../Types'
import { Card, Form, OverlayTrigger, Popover } from 'react-bootstrap'
import SelectSignalsModal from '../../SelectSignalsModal'
import FrameTable from './FrameTable'
import TimeSeriesContainer from '../TimeSeriesContainer'
import { GridRow } from './types'
import {
    CloseRounded,
    KeyboardArrowDownRounded,
    KeyboardArrowRightRounded,
    RuleRounded,
    UnfoldMoreRounded,
} from '@mui/icons-material'
import { SettingsIcon } from 'src/assets/Icons'
import SignalInChartLabel from '../TimeSeriesContainer/SignalInChartLabel'
import LoadingContainer from 'src/components/LoadingContainer'

HighchartsBoost(Highcharts)

interface TraceWindowProps {
    currentProject: Project | undefined
    currentRecording: RecordingSession | undefined
    currentUser: AuthenticatedUser | undefined
    panelKey: PanelKey
    removeThisPanelFunction: () => void
    updatePanel: (panel: TraceWindowPanel) => void
    safeFetchAndSetAvailableAnnotations: () => Promise<Array<RecordingAnnotation>>
    sharedExtremes: SharedExtremes
    selectedSignals: Array<SignalEntry>
    setSharedExtremes: (newExtremes: SharedExtremes) => void
    availableFrameEntries: Array<FrameEntry>
    availableAnnotations: Array<RecordingAnnotation> | undefined
}

export default function TraceWindowContainer(props: TraceWindowProps) {
    const [allFrames, setAllFrames] = useState<FrameList>()
    const [selectedSignals, setSelectedSignals] = useState<Array<SignalEntry>>(props.selectedSignals ?? [])
    const [showSelectSignalsModal, setShowSelectSignalsModal] = useState<boolean>(selectedSignals.length === 0)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(false)
    const [plottableSignals, setPlottableSignals] = useState<Array<PlottableSignalEntry>>([])
    const [selectedSignalsForGraph, setSelectedSignalsForGraph] = useState<Array<SignalEntry>>([])

    const [showSettingsPopover, setShowSettingsPopover] = useState<boolean>(false)
    const [isFrameRowsExpanded, setIsFrameRowsExpanded] = useState<boolean>(false)

    const fetchSignalsAndStorePanel = async (selectedSignals: Array<SignalEntry>) => {
        if (selectedSignals.length > 0) {
            setIsLoadingData(true)
            await fetch()
            setIsLoadingData(false)
            const panelToStore = { panelKey: props.panelKey, selectedSignals } as TraceWindowPanel
            props.updatePanel(panelToStore)
        }
    }

    useEffect(() => {
        fetchSignalsAndStorePanel(selectedSignals)
    }, [selectedSignals])

    const groupByFrameNsId = (array: SignalEntry[]): Record<number, number[]> => {
        return array.reduce((result, current) => {
            const { frameNsId, frameSignalId } = current
            // Initialize the array for the key if it doesn't exist
            if (!result[frameNsId]) {
                result[frameNsId] = []
            }

            // Add the frameSignalId to the corresponding array
            result[frameNsId].push(frameSignalId)

            return result
        }, {} as Record<number, number[]>)
    }

    const fetch = async () => {
        if (props.currentProject && props.currentRecording) {
            console.log(`selectedSignals=${JSON.stringify(selectedSignals)}`)
            if (allFrames) {
                setAllFrames(undefined)
            }
            //const res: Record<number, number[]> = groupByFrameNsId(selectedSignals)
            const res: Record<number, number[]> = { 1: [1] }
            const data = await CloudApi.getFrameTimeseries(
                props.currentProject.uid,
                props.currentRecording.sessionId,
                groupByFrameNsId(selectedSignals)
            )
            setAllFrames(data.data)
        }
    }

    const addSignalToTimeSeries = (row: GridRow) => {
        const signals = allFrames?.signals
            .filter((f) => f.frameNsId === row.frameNsId)
            .flatMap((r) =>
                r.signals!.map((s) => {
                    return { signal: s, timestamp: r.timestamp }
                })
            )
            .filter((s) => s!.signal.frameSignalId! === row.frameSignalId)
        console.log(signals)

        const entry: SignalEntry = selectedSignals.filter(
            (ss) => row!.frameSignalId == ss.frameSignalId && ss.frameNsId === row.frameNsId
        )[0]
        const signalData = signals!.map((s) => [s.timestamp, s.signal.value])

        const p: PlottableSignalEntry = {
            signalData: signalData,
            signalEntry: entry,
        }

        setSelectedSignalsForGraph([...selectedSignalsForGraph, entry])
        setPlottableSignals([...plottableSignals, p])
    }

    const popoverSettingsItem = (title: string, actionElement: ReactElement) => {
        return (
            <div className="d-flex justify-content-between align-items-center rounded px-2 py-1 m-1 remotive-primary-20-background">
                <p className="m-0 remotive-font-sm lexend-bold">{title}</p>
                <div className="d-flex justify-content-center align-items-center flex-row align-items-center">
                    {actionElement}
                </div>
            </div>
        )
    }

    const settingsPopover = (
        <Popover
            id="popover-basic"
            className="border-2 remotive-primary-20-border remotive-primary-0-background shadow rounded-4"
            style={{ maxWidth: 400 }}
        >
            <Popover.Body style={{ zIndex: '0 !important', width: 300 }} className="p-3 lexend-regular">
                <div>
                    <div>
                        {popoverSettingsItem(
                            'Show Y-axis',
                            <>
                                <div className="justify-content-end p-0 ms-2">
                                    <Form.Check // prettier-ignore
                                        className={'remotive-font-sm'}
                                        type="switch"
                                        checked={true}
                                        disabled={false}
                                        onChange={(e: any) => console.log}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    const constructSignalNameKey = (signalName: string, frameName: string, namespace: string) => {
        const name = `${namespace}-${frameName}-${signalName}`.toLowerCase()
        return name
    }

    const constructSignalNameKeyFromSignalEntry = (signalEntry: SignalEntry) => {
        return constructSignalNameKey(signalEntry.name, signalEntry.frameName, signalEntry.namespace)
    }

    const currentlySelectedSignals = () => {
        return selectedSignals.map((signal, index) => {
            const chartColor = Highcharts.getOptions().colors![index]
            const isSignalInGraph = true
            const isSignalHidden = false
            return (
                <div key={constructSignalNameKeyFromSignalEntry(signal)} className="m-1">
                    <SignalInChartLabel
                        signal={signal}
                        isSignalHidden={isSignalHidden}
                        isSignalInGraph={isSignalInGraph}
                        hiddenSignals={[]}
                        constructSignalNameKeyFromSignalEntry={constructSignalNameKeyFromSignalEntry}
                        setHiddenSignals={() => console.log}
                        htmlColor={'#FFF'}
                    />
                </div>
            )
        })
    }

    const settingsButton = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    disabled={true} //{selectedSignals.length === 0}
                    className={`btn remotive-btn-no-bg remotive-btn p-0 m-0`}
                >
                    <div className="d-flex align-items-center mx-1" title="Settings for this signal time series panel">
                        <OverlayTrigger
                            trigger="click"
                            rootClose
                            show={showSettingsPopover}
                            onToggle={(newState: boolean) => setShowSettingsPopover(newState)}
                            placement="left"
                            overlay={settingsPopover}
                        >
                            <div className="d-flex align-items-center">
                                <SettingsIcon sx={{ fontSize: 20 }} />
                            </div>
                        </OverlayTrigger>
                    </div>
                </button>
            </div>
        )
    }

    const closeButton = () => {
        return (
            <div className="d-flex flex-column align-items-center">
                <button
                    style={{ marginTop: 0, marginBottom: 0, marginLeft: 0, marginRight: 0 }}
                    onClick={() => props.removeThisPanelFunction()}
                    className="btn p-0 remotive-btn-no-bg"
                >
                    <div className="d-flex align-items-center" title="Close entire panel">
                        <CloseRounded sx={{ fontSize: 22 }} />
                    </div>
                </button>
            </div>
        )
    }

    const toolbar = () => {
        const ICON_SIZE = 14
        return (
            <div style={{ marginTop: -2 }} className="px-1">
                <div className="d-flex align-items-start">
                    {
                        <>
                            <button
                                onClick={() => setShowSelectSignalsModal(true)}
                                title="Select signals to visualize in this panel"
                                className="btn remotive-btn-no-bg p-0 px-3 m-0"
                            >
                                <div className=" d-flex flex-column align-items-center">
                                    <RuleRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                    <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Select signals</p>
                                </div>
                            </button>
                        </>
                    }
                    {
                        <div className="ms-2">
                            <button
                                title="Add an annotation to the graph"
                                onClick={() => setIsFrameRowsExpanded(!isFrameRowsExpanded)}
                                className="btn remotive-btn-no-bg p-0 m-0 px-2"
                            >
                                <div className={`d-flex flex-column align-items-center`}>
                                    {isFrameRowsExpanded ? (
                                        <KeyboardArrowDownRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                    ) : (
                                        <KeyboardArrowRightRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                    )}
                                    <p className="remotive-font-xxs m-0 d-none d-md-inline-block">
                                        {isFrameRowsExpanded ? 'Expand All' : 'Collapse All'}
                                    </p>
                                </div>
                            </button>
                        </div>
                    }

                    {/* This features is not ready yet!
                    <div className="">
                        <button
                            disabled={componentState === ComponentState.LOADING}
                            title="Measure time difference between signals"
                            className="btn remotive-btn-no-bg p-0 px-2 m-0"
                        >
                            <div className="d-flex flex-column align-items-center">
                                <StraightenRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                <p className="remotive-font-xxs m-0">Measure</p>
                            </div>
                        </button>
                    </div> 
                    */}
                    {/* isLocalOrDevEnvironment() && (
                        <div className="">
                            <button
                                onClick={() => exportSignalData()}
                                disabled={componentState === ComponentState.LOADING}
                                title="Export signal data"
                                className="btn remotive-btn-no-bg p-0 px-2 m-0"
                            >
                                <div className="d-flex flex-column align-items-center">
                                    <UpgradeRounded sx={{ fontSize: ICON_SIZE }} className="" />
                                    <p className="remotive-font-xxs m-0 d-none d-md-inline-block">Export</p>
                                </div>
                            </button>
                        </div>
                    )*/}
                </div>
            </div>
        )
    }

    return (
        <div className="p-2 pb-1">
            <div className="d-flex flex-column">
                <>
                    <div className="d-flex w-100 justify-content-between">
                        <div className="d-flex align-items-start">
                            <div className="d-flex align-items-center border-end">
                                <div className="me-2">{closeButton()}</div>
                                <div className="me-3">
                                    <div className="d-none d-md-flex align-items-center justify-content-center">
                                        <p className="remotive-font-md lh-sm remotive-dark-color m-0">
                                            Trace Window
                                        </p>
                                        <p
                                            style={{ marginLeft: -15, marginTop: -6 }}
                                            className="position-relative remotive-font-xxs lexend-bold remotive-primary-50-color-absolute"
                                        >
                                            BETA
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="">{selectedSignals.length > 0 && toolbar()}</div>
                        </div>

                        <div className="d-flex">{settingsButton()}</div>
                    </div>
                </>

                {/* Lower Component: Main Content */}
                <div className="rounded-3 flex-grow-1 d-flex justify-content-center align-items-center mx-1 mt-1">
                    {isLoadingData ? (
                        <LoadingContainer spinnerSize="sm" />
                    ) : (
                        <FrameTable
                            isExpanded={isFrameRowsExpanded}
                            frames={allFrames}
                            hiddenSignals={[]}
                            sharedExtremes={props.sharedExtremes}
                            addSignalFunc={addSignalToTimeSeries}
                            signalsInTimeseriesChart={plottableSignals}
                        />
                    )}
                </div>
                {/* Upper Component: Header */}
                {plottableSignals && plottableSignals.length > 0 && (
                    <TimeSeriesContainer
                        plottableSignalEntries={plottableSignals}
                        updatePanel={(panel) => console.log('')}
                        availableFrameEntries={props.availableFrameEntries ?? []}
                        panel={
                            {
                                panelKey: { key: 'sd' },
                                selectedSignals: selectedSignalsForGraph,
                                hiddenSignals: [],
                                graphSettings: {} as GraphSettings,
                            } as TimeSeriesPanel
                        }
                        removeThisPanelFunction={props.removeThisPanelFunction}
                        currentUser={props.currentUser}
                        currentProject={props.currentProject}
                        recordingSession={props.currentRecording}
                        availableAnnotations={props.availableAnnotations}
                        safeFetchAndSetAvailableAnnotations={props.safeFetchAndSetAvailableAnnotations}
                        sharedExtremes={props.sharedExtremes}
                        setSharedExtremes={props.setSharedExtremes}
                        selfContained={false}
                    />
                )}
            </div>

            {/*
             */}

            <SelectSignalsModal
                isFramesSelectable={true}
                show={showSelectSignalsModal}
                selectableSignalsWithParentFrame={props.availableFrameEntries}
                selectedSignals={selectedSignals}
                handleCloseFunction={(numberOfSelectedSignals: number) => {
                    if (numberOfSelectedSignals <= 0) {
                        props.removeThisPanelFunction()
                    }
                    setShowSelectSignalsModal(false)
                }}
                selectSignalsFunction={setSelectedSignals}
            />
        </div>
    )
}
