import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { hasPermission, Permission } from 'src/utils/permission'
import { ProductAnalyticsProperties } from 'src/utils/ProductAnalytics'
import MediaFileDropzone from './MediaFileDropzone'
import { MediaFileListItem } from './MedaFileListItem'
import { UserRecordingSessionContext } from 'src/types/Context'

interface MediaFilesSectionProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties
    refreshRecordingSession: Function
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

export default function MediaFilesSection(props: MediaFilesSectionProps) {
    const mediaFileList = () => {
        return props.userRecordingSessionContext.currentRecordingSession.mediaFiles.map((mediaFile) => {
            return (
                <div key={mediaFile.fileName}>
                    <MediaFileListItem
                        mediaFile={mediaFile}
                        userRecordingSessionContext={props.userRecordingSessionContext}
                        productAnalyticsProperties={
                            {
                                currentBillableUnit: props.userRecordingSessionContext.currentBillableUnit,
                                currentUser: props.userRecordingSessionContext.currentUser,
                            } as ProductAnalyticsProperties
                        }
                        openConfirmationDialog={props.openConfirmationDialog}
                        refreshRecordingSession={props.refreshRecordingSession}
                    />
                </div>
            )
        })
    }

    const mediaFilesSection = () => {
        return (
            <>
                <>
                    <p className="fs-6 mb-0">
                        <b>Media files</b>
                    </p>
                    <p className="mb-0 remotive-font-sm remotive-secondary-color">
                        We support images (<b>png</b>, <b>jpg</b>, <b>jpeg</b>, <b>webp</b>), videos (<b>mp4</b>,{' '}
                        <b>mov</b>
                        ), Jupyter notebooks (<b>.ipynb</b>) and zip files (<b>.zip</b>). We recommend using the{' '}
                        <b>mp4</b> file format for videos due to better browser support.
                    </p>
                    {mediaFileList()}
                    <div className="mx-1">
                        <MediaFileDropzone
                            hasPermissionToUpload={hasPermission(
                                Permission.PROJECT_EDITOR_RECORDING,
                                props.userRecordingSessionContext.currentBillableUnit,
                                props.userRecordingSessionContext.currentProject
                            )}
                            userRecordingSessionContext={props.userRecordingSessionContext}
                            productAnalyticsProperties={props.productAnalyticsProperties}
                            onUploadComplete={() => props.refreshRecordingSession()}
                        />
                    </div>
                </>
            </>
        )
    }

    return mediaFilesSection()
}
