import { ReactElement, useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import { CrossIcon, EditIcon, SaveIcon } from '../assets/Icons'

interface EditableLabelProps {
    value: string | undefined
    disabled?: boolean
    placeholder?: string
    icon?: ReactElement
    secondary?: boolean
    onSave: (value: string) => void
}

export default function EditableLabel(props: EditableLabelProps) {
    const { value } = props
    const [isEditTitleActive, setIsEditTitleActive] = useState(false)
    const [editedName, setEditedName] = useState(value)

    useEffect(() => {
        setEditedName(value)
    }, [value])

    const toggleEditMode = () => {
        if (isEditTitleActive) {
            if (editedName && editedName !== props.value) {
                props.onSave(editedName)
            }
        } else {
            setEditedName(props.value)
        }
        setIsEditTitleActive(!isEditTitleActive)
    }

    const cancelEditmode = (e: any) => {
        if (e.currentTarget.parentNode.contains(e.relatedTarget)) {
            return
        }
        setIsEditTitleActive(false)
        setEditedName(props.value)
    }

    const titleText = () => {
        if (!isEditTitleActive) {
            return (
                <div className="d-flex align-items-center remotive-primary-100-color">
                    {props.icon}
                    <p
                        className={`fs-${props.secondary ? 6 : 3} ${
                            props.secondary && 'remotive-secondary-color'
                        } lexend-light mb-0 text-truncate`}
                    >
                        {(props.value || 'Add a description') ?? 'Loading...'}{' '}
                    </p>
                </div>
            )
        } else {
            return (
                <Form.Control
                    className="remotive-font-sm w-50 dark-and-light-placeholder"
                    type="text"
                    placeholder={props.placeholder}
                    value={editedName}
                    onChange={(event) => setEditedName(event.target.value)}
                />
            )
        }
    }

    const editTitleButton = () => {
        if (isEditTitleActive) {
            return (
                <>
                    <button
                        className="p-0 m-0 bg-transparent remotive-primary-70-color border-0 mx-2"
                        onClick={cancelEditmode}
                    >
                        <CrossIcon className="align-top" sx={{ fontSize: 24 }} />
                    </button>
                    <button
                        className="p-0 m-0 bg-transparent remotive-success-60-color border-0"
                        onClick={toggleEditMode}
                    >
                        <SaveIcon className="align-top" sx={{ fontSize: 23 }} />
                    </button>
                </>
            )
        } else {
            return (
                <button
                    className="p-0 m-0 bg-transparent remotive-primary-70-color border-0 mx-2"
                    onClick={() => setIsEditTitleActive(true)}
                    disabled={props.disabled}
                >
                    <EditIcon className="align-top" sx={{ fontSize: 17 }} />
                </button>
            )
        }
    }

    return (
        <div className="text-start d-flex text-truncate">
            {titleText()}
            {editTitleButton()}
        </div>
    )
}
