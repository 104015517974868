import { SvgIconTypeMap } from '@mui/material/SvgIcon'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { Card } from 'react-bootstrap'
import { ProjectRoutes } from '../../Routes'
import { NavLink } from 'react-router-dom'
import { ReactElement } from 'react'
import { ExternalLinkIcon } from '../../assets/Icons'

interface IconAndLinkCardProps {
    icon: ReactElement
    buttonDisabledCondition: () => boolean
    internalRoute?: string
    externalLink?: string
    buttonText: string
    bodyText: string
    className?: string
}

export default function IconAndLinkCard(props: IconAndLinkCardProps) {
    const content = () => {
        return (
            <div className="d-none d-md-block col-4">
                <Card
                    className={`mx-2 remotive-white-background remotive-dark-color border-0 rounded-4 shadow-sm px-1 h-100 ${props.className}`}
                    style={{ minHeight: 130 }}
                >
                    <div className="d-flex flex-column align-items-between h-100 p-3">
                        <div className="d-flex justify-content-between">
                            <>{props.icon}</>
                            <p className="m-0 remotive-font-sm mb-2 text-start" style={{ maxWidth: 300 }}>
                                {props.bodyText}
                            </p>
                        </div>
                        <div className="d-flex justify-content-end h-100">
                            <button
                                disabled={props.buttonDisabledCondition()}
                                className="btn remotive-btn-sm remotive-btn-success align-self-end"
                            >
                                {props.internalRoute !== undefined && (
                                    <NavLink to={props.internalRoute} className={'text-light text-decoration-none'}>
                                        <div className="d-flex align-items-center">
                                            <p className="m-0">{props.buttonText}</p>
                                        </div>
                                    </NavLink>
                                )}
                                {props.externalLink !== undefined && (
                                    <NavLink
                                        to={props.externalLink}
                                        target="blank"
                                        className={'text-light text-decoration-none'}
                                    >
                                        <div className="d-flex align-items-center">
                                            <p className="m-0 me-2">{props.buttonText}</p>
                                            <ExternalLinkIcon sx={{ fontSize: 13 }} />
                                        </div>
                                    </NavLink>
                                )}
                            </button>
                        </div>
                    </div>
                </Card>
            </div>
        )
    }

    return content()
}
