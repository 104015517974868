const ORGANISATION_REPLACEABLE_PATH_PARAM = ':organisationUid'
const PROJECT_REPLACEABLE_PATH_PARAM = ':projectUid'
const RECORDING_REPLACEABLE_PATH_PARAM = ':recordingSessionId'
const BROKER_REPLACEABLE_PATH_PARAM = ':brokerName'
const SIGNAL_REPLACEABLE_PATH_PARAM = ':signalFileName'

export const StaticRouteTemplate = {
    LOGIN: '/',
    AGREEMENT: '/agreement',
    SCHEDULED_FOR_DELETION: '/org-scheduled-for-deletion',
    NOT_FOUND: '/not-found',
    ADMIN: '/admin',
}

export const DynamicRouteTemplate = {
    PROJECT_HOME: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}`,
    PROJECT_FILES: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/files`,
    PROJECT_BROKERS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/brokers`,
    PROJECT_BROKER_DETAILS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/brokers/${BROKER_REPLACEABLE_PATH_PARAM}`,
    PROJECT_SETTINGS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/settings`,
    PROJECT_RECORDINGS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/recordings`,
    PROJECT_RECORDING_DETAILS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/recordings/${RECORDING_REPLACEABLE_PATH_PARAM}`,
    PROJECT_RECORDING_ANALYTICS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/analytics/recordings/${RECORDING_REPLACEABLE_PATH_PARAM}`,
    PROJECT_SIGNAL_DATABASES: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/signal-databases`,
    PROJECT_SIGNAL_DETAILS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/signal-databases/${SIGNAL_REPLACEABLE_PATH_PARAM}`,
    ORGANISATION_HOME: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}`,
    ORGANISATION_LICENSES: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}/licenses`,
    ORGANISATION_USERS: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}/users`,
    ORGANISATION_SETTINGS: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}/settings`,
}

type RequiresProjectRoute = (projectUid: string | undefined) => string
type RequiresProjectBrokerRoute = (projectUid: string | undefined, brokerName: string | undefined) => string
type RequiresProjectRecordingSessionRoute = (
    projectUid: string | undefined,
    recordingSessionId: string | undefined
) => string
type RequiresProjectSignalRoute = (projectUid: string | undefined, signalFileName: string | undefined) => string
export interface ProjectRoutesType {
    toHome: RequiresProjectRoute
    toBrokersList: RequiresProjectRoute
    toSettings: RequiresProjectRoute
    toFiles: RequiresProjectRoute
    toRecordingsList: RequiresProjectRoute
    toSignalDatabases: RequiresProjectRoute
}
export interface ProjectBrokerRoutesType {
    toBrokerDetails: RequiresProjectBrokerRoute
}

export interface ProjectRecordingRoutesType {
    toRecordingDetails: RequiresProjectRecordingSessionRoute
}

export interface ProjectSignalRouteType {
    toSignalDetails: RequiresProjectSignalRoute
}

export const ProjectRoutes: ProjectRoutesType = {
    toHome: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_HOME.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toBrokersList: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_BROKERS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toSettings: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_SETTINGS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toFiles: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_FILES.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toRecordingsList: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_RECORDINGS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toSignalDatabases: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_SIGNAL_DATABASES.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
}

export const ProjectBrokerRoutes: ProjectBrokerRoutesType = {
    toBrokerDetails: (projectUid: string | undefined, brokerName: string | undefined): string => {
        if (projectUid === undefined || brokerName === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_BROKER_DETAILS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid).replace(
            BROKER_REPLACEABLE_PATH_PARAM,
            brokerName
        )
    },
}

export const ProjectSignalRoutes: ProjectSignalRouteType = {
    toSignalDetails: (projectUid: string | undefined, signalFileName: string | undefined): string => {
        if (projectUid === undefined || signalFileName === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_SIGNAL_DETAILS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid).replace(
            SIGNAL_REPLACEABLE_PATH_PARAM,
            signalFileName
        )
    },
}

export const ProjectRecordingRoutes: ProjectRecordingRoutesType = {
    toRecordingDetails: (projectUid: string | undefined, recordingSessionId: string | undefined): string => {
        if (projectUid === undefined || recordingSessionId === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return DynamicRouteTemplate.PROJECT_RECORDING_DETAILS.replace(
            PROJECT_REPLACEABLE_PATH_PARAM,
            projectUid
        ).replace(RECORDING_REPLACEABLE_PATH_PARAM, recordingSessionId)
    },
}

export interface OrganisationRoutesType {
    toHome: (organisationUid: string | undefined) => string
    toLicenses: (organisationUid: string | undefined) => string
    toSettings: (organisationUid: string | undefined) => string
    toUsers: (organisationUid: string | undefined) => string
}

export const OrganisationRoutes: OrganisationRoutesType = {
    toHome: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return (DynamicRouteTemplate.ORGANISATION_HOME as string).replace(
            ORGANISATION_REPLACEABLE_PATH_PARAM,
            organisationUid
        )
    },
    toLicenses: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return (DynamicRouteTemplate.ORGANISATION_LICENSES as string).replace(
            ORGANISATION_REPLACEABLE_PATH_PARAM,
            organisationUid
        )
    },
    toSettings: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return (DynamicRouteTemplate.ORGANISATION_SETTINGS as string).replace(
            ORGANISATION_REPLACEABLE_PATH_PARAM,
            organisationUid
        )
    },
    toUsers: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return StaticRouteTemplate.LOGIN
        }
        return (DynamicRouteTemplate.ORGANISATION_USERS as string).replace(
            ORGANISATION_REPLACEABLE_PATH_PARAM,
            organisationUid
        )
    },
}

export interface StaticPageRouteType {
    toNotFound: () => string
    toLogin: () => string
    toAdmin: () => string
    toAgreement: () => string
    toScheduledForDeletion: () => string
}

export const StaticPageRoutes: StaticPageRouteType = {
    toNotFound: () => StaticRouteTemplate.NOT_FOUND,
    toLogin: () => StaticRouteTemplate.LOGIN,
    toAdmin: () => StaticRouteTemplate.ADMIN,
    toAgreement: () => StaticRouteTemplate.AGREEMENT,
    toScheduledForDeletion: () => StaticRouteTemplate.SCHEDULED_FOR_DELETION,
}
