import CloudApi from 'src/api/CloudApi'
import { Project, RecordingSession, UserBillableUnitInfo } from '../api/CloudApi/types'

export type RecentRecording = {
    recordingSession: RecordingSession
    project: Project
    billableUnit: UserBillableUnitInfo
}

const LOCAL_STORAGE_KEY_RECENT_RECORDINGS = 'LS_RECENT_RECORDINGS'
const MAX_RECENT_RECORDINGS_TO_STORE = 15

const filterNonExistingRecordings = async (recentRecordings: RecentRecording[], project: Project) => {
    const recordingSessionIds = (await CloudApi.listRecordingSessions(project.uid)).data.map(it => it.sessionId)

    recentRecordings.forEach((recording) => {

        if (recording.project.uid === project.uid) {
            const exists = recordingSessionIds.some((id) => id === recording.recordingSession.sessionId)
            if (!exists) {
                removeRecentRecording(recording)
            }
        }
    })
}

export const getRecentRecordingsForProject = async (project: Project) => {
    try {
        const recentRecordingsAsString = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
        if (recentRecordingsAsString !== null) {
            const recentRecordings = (JSON.parse(recentRecordingsAsString) as Array<RecentRecording>).filter(
                (it) => it.project.uid === project.uid
            )
            await filterNonExistingRecordings(recentRecordings, project)
            // At the moment we just want to show 3 items in the list
            return recentRecordings.slice(0, 3)
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent recordings, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
    }
    return undefined
}

export const getRecentRecordings = async (currentBillableUnit?: UserBillableUnitInfo) => {
    try {
        if (currentBillableUnit !== undefined) {
            const recentRecordingsAsString = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
            if (recentRecordingsAsString !== null) {
                const recentRecordings = (JSON.parse(recentRecordingsAsString) as Array<RecentRecording>).filter(
                    (it) => it.billableUnit.organisation.uid === currentBillableUnit.organisation.uid
                )

                await Promise.all(currentBillableUnit.projects.map((it) => filterNonExistingRecordings(recentRecordings, it)))
                // At the moment we just want to show 3 items in the list
                return recentRecordings.slice(0, 3)
            }
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent recordings, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
    }
    return undefined
}

export const storeRecentRecording = (
    recording: RecordingSession,
    project: Project,
    billableUnit: UserBillableUnitInfo
) => {
    try {
        const recentRecordingsAsJSon = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
        const thisRecording = {
            recordingSession: recording,
            project: project,
            billableUnit: billableUnit,
        } as RecentRecording
        if (recentRecordingsAsJSon !== null) {
            const recentRecordings = JSON.parse(recentRecordingsAsJSon) as Array<RecentRecording>
            const recordingIsNotAlreadyInList =
                recentRecordings.find(
                    (it) =>
                        it.billableUnit.organisation.uid === billableUnit.organisation.uid &&
                        it.project.uid === project.uid &&
                        it.recordingSession.sessionId === recording.sessionId
                ) === undefined
            if (recordingIsNotAlreadyInList) {
                recentRecordings.unshift(thisRecording)
                localStorage.setItem(
                    LOCAL_STORAGE_KEY_RECENT_RECORDINGS,
                    JSON.stringify(recentRecordings.slice(0, MAX_RECENT_RECORDINGS_TO_STORE))
                )
            }
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS, JSON.stringify([thisRecording]))
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent recordings, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
    }
}

const removeRecentRecording = (recording: RecentRecording) => {
    try {
        const recentRecordingsAsJSon = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
        if (recentRecordingsAsJSon !== null) {
            const recentRecordings = JSON.parse(recentRecordingsAsJSon) as Array<RecentRecording>
            const indexInList = recentRecordings.findIndex(
                (it) =>
                    it.billableUnit.organisation.uid === recording.billableUnit.organisation.uid &&
                    it.project.uid === recording.project.uid &&
                    it.recordingSession.sessionId === recording.recordingSession.sessionId
            )

            if (indexInList >= 0) {
                recentRecordings.splice(indexInList, 1)
                localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS, JSON.stringify(recentRecordings))
            }
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent recordings, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
    }
}
