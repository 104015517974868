import { useEffect, useState } from 'react'
import { FileWithPath } from 'react-dropzone'
import { toast } from 'react-toastify'
import CloudApi from 'src/api/CloudApi'
import { BrokerConfigurationFolder } from 'src/api/CloudApi/types'
import { ConfirmDialogProperties } from 'src/types/ConfirmDialogProperties'
import { UserRecordingSessionContext } from 'src/types/Context'
import { hasPermission, Permission } from 'src/utils/permission'
import { ProductAnalyticsProperties } from 'src/utils/ProductAnalytics'
import { formattedToastMessage } from 'src/utils/toast'
import TransformationFileDropzone from './TransformationFileDropzone'
import { FileUploadConfirmationModal } from './FileUploadConfirmationModal'
import { TransformationFileListItem } from './TransformationFileListItem'

interface TransformationFilesSectionProps {
    userRecordingSessionContext: UserRecordingSessionContext
    productAnalyticsProperties: ProductAnalyticsProperties
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
    refreshRecordingSession: Function
    recordingRequiresFurtherConfiguration: boolean
}

export function TransformationFilesSection(props: TransformationFilesSectionProps) {
    const [tranformationFilesPreparedForUpload, setTranformationFilesPreparedForUpload] = useState<Array<FileWithPath>>(
        []
    )
    const [transformationFilesToUpload, setTransformationFilesToUpload] = useState<Array<FileWithPath>>([])

    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [isUploadingTransformationFolder, setIsUploadingTransformationFolder] = useState<boolean>(false)
    const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState<boolean>(false)

    useEffect(() => {
        if (tranformationFilesPreparedForUpload.length > 0) {
            setTransformationFilesToUpload(tranformationFilesPreparedForUpload)
            setShowUploadConfirmationModal(true)
        }
    }, [tranformationFilesPreparedForUpload])

    const deleteTransformationFolder = async (folder: BrokerConfigurationFolder, skipToast: boolean = false) => {
        try {
            await CloudApi.deleteBrokerConfigFolder(
                props.userRecordingSessionContext.currentProject.uid,
                props.userRecordingSessionContext.currentRecordingSession.sessionId,
                folder
            )
            await props.refreshRecordingSession()
            if (!skipToast) {
                toast.success(
                    formattedToastMessage('Sucess', `Successfully deleted signal transformation ${folder.name}`)
                )
            }
        } catch (err: any) {
            toast.error(formattedToastMessage('Error', 'Failed to delete signal transformation'))
        }
    }

    const transformationFoldersList = () => {
        return props.userRecordingSessionContext.currentRecordingSession.brokerConfigurations.map((folder, index) => {
            return (
                <div key={folder.name}>
                    <TransformationFileListItem
                        userRecordingSessionContext={props.userRecordingSessionContext}
                        productAnalyticsProperties={props.productAnalyticsProperties}
                        transformationFolder={folder}
                        openConfirmationDialog={props.openConfirmationDialog}
                        recordingRequiresFurtherConfiguration={props.recordingRequiresFurtherConfiguration}
                        refreshRecordingSession={props.refreshRecordingSession}
                    />
                </div>
            )
        })
    }

    const transformationFilesSection = () => {
        return (
            <>
                <p className="fs-6 mb-0">
                    <b>Signal transformations</b>
                </p>
                <p className="mb-0 remotive-font-sm remotive-secondary-color">
                    By default there is no transformation applied to raw signals but you can upload transformations that
                    are applied when playing a recording. Transformations are done using custom broker configurations.
                </p>
                {transformationFoldersList()}
                <div className="mx-1">
                    <TransformationFileDropzone
                        userRecordingSessionContext={props.userRecordingSessionContext}
                        productAnalyticsProperties={props.productAnalyticsProperties}
                        hasPermissionToUpload={
                            hasPermission(
                                Permission.PROJECT_EDITOR_RECORDING,
                                props.userRecordingSessionContext.currentBillableUnit,
                                props.userRecordingSessionContext.currentProject
                            ) === true
                        }
                        isUploading={isUploadingTransformationFolder}
                        uploadPercent={uploadPercent}
                        transformationFilesPreparedForUpload={tranformationFilesPreparedForUpload}
                        setTranformationFilesPreparedForUpload={setTranformationFilesPreparedForUpload}
                    />
                </div>
                <FileUploadConfirmationModal
                    onClose={() => {
                        setShowUploadConfirmationModal(false)
                    }}
                    hasPermissionToUpload={
                        hasPermission(
                            Permission.PROJECT_EDITOR_RECORDING,
                            props.userRecordingSessionContext.currentBillableUnit,
                            props.userRecordingSessionContext.currentProject
                        ) === true
                    }
                    show={showUploadConfirmationModal}
                    userRecordingSessionContext={props.userRecordingSessionContext}
                    productAnalyticsProperties={props.productAnalyticsProperties}
                    transformationFilesPreparedForUpload={tranformationFilesPreparedForUpload}
                    transformationFilesToUpload={transformationFilesToUpload}
                    setTransformationFilesToUpload={setTransformationFilesToUpload}
                    setUploadPercent={setUploadPercent}
                    deleteTransformationFolder={deleteTransformationFolder}
                    onUploadBegin={() => {
                        setIsUploadingTransformationFolder(true)
                        setShowUploadConfirmationModal(false)
                    }}
                    onUploadFailed={() => {
                        toast.error(formattedToastMessage('Upload failed', `Make sure that you are uploading a folder and not a file.`))
                        setIsUploadingTransformationFolder(false)
                    }}
                    onUploadComplete={async () => {
                        await props.refreshRecordingSession()
                        setIsUploadingTransformationFolder(false)
                        setTranformationFilesPreparedForUpload([])
                    }}
                />
            </>
        )
    }

    return transformationFilesSection()
}
