import { useEffect, useState } from 'react'
import { Modal, Button, Form, InputGroup } from 'react-bootstrap'

const MODAL_THEME_BACKGROUND = 'remotive-modal-header-bg'
const MODAL_THEME_COLOR = 'text-light'

interface CreateFolderModalProperties {
    show: boolean
    text: string
    title: string
    handleCloseFunction: () => void
    createFolderFunction: (folderName: string) => void
}

function CreateFolderModal(props: CreateFolderModalProperties) {
    const [folderName, setFolderName] = useState('')

    const closeModal = () => {
        props.handleCloseFunction()
    }

    useEffect(() => {
        if (props.show) {
            setFolderName('')
        }
    }, [props.show])

    const createFolder = () => {
        props.handleCloseFunction()
        props.createFolderFunction(folderName)
    }

    return (
        <>
            <Modal show={props.show} onHide={closeModal} className='lexend-regular'>
                <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`} closeButton closeVariant='white'>
                    <Modal.Title className="">Create folder</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <InputGroup size="sm" hasValidation={false}>
                                <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                    Name
                                </InputGroup.Text>
                                <Form.Control
                                    className="remotive-font-sm w-75 dark-and-light-placeholder"
                                    type="text"
                                    placeholder="Enter folder name"
                                    value={folderName}
                                    onChange={(e) => setFolderName(e.target.value)}
                                />
                            </InputGroup>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <Button
                        className={'btn remotive-btn remotive-btn-success lexend-regular'}
                        onClick={() => createFolder()}
                    >
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateFolderModal
