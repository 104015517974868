import { useState, useEffect } from 'react'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login'
import Home from './pages/Home'
import LicenseList from './pages/LicenseList'
import UserList from './pages/UserList'
import BrokerList from './pages/BrokerList'
import ProjectManagement from './pages/ProjectManagement'
import RecordingDetails from './pages/RecordingDetails'
import RecordingsList from './pages/RecordingsList'
import SignalDatabaseList from './pages/SignalDatabaseList'
import NotFound from './pages/NotFound'
import { AuthenticatedUser, Project, UserBillableUnitInfo } from './api/CloudApi/types'
import CloudApi from './api/CloudApi'
import BrokerDetails from './pages/BrokerDetails'
import Admin from './pages/Admin'
import useScript from './hooks/useScript'
import { isDemo } from './utils/demo'
import { PageDetails } from './utils/pageDetails'
import Agreement from './pages/Agreement'
import OrganisationSettings from './pages/OrganisationSettings'
import OrganisationToBeDeleted from './pages/OrganisationToBeDeleted'
import { DynamicRouteTemplate, StaticPageRoutes } from './Routes'
import { formattedToastMessage } from './utils/toast'
import useInterval from './hooks/useInterval'
import useIsTabActive from './hooks/useIsTabActive'
import { NOTIFY_USER_HAS_ACCOUNT_PARAM } from './utils/queryParams'
import { useProductAnalyticsClient } from './utils/ProductAnalytics'
import Files from './pages/Storage'
import ConfirmDialog from './components/modals/ConfirmDialog'
import { ConfirmDialogProperties } from './types/ConfirmDialogProperties'
import { UserProjectContext } from './types/Context'
import SignalDetails from './pages/SignalDetails'
import ProjectHome from './pages/ProjectHome'
import { DarkModeProvider } from './hooks/useDarkMode'
import { RemotiveToastContainer } from './components/RemotiveToastContainer'

function App() {
    const [billableUnits, setBillableUnits] = useState<Array<UserBillableUnitInfo>>([])
    const [currentBillableUnit, setCurrentBillableUnit] = useState<UserBillableUnitInfo>()
    const [currentProject, setCurrentProject] = useState<Project>()
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser>()
    const [currentPageDetails, setCurrentPageDetails] = useState<PageDetails>()

    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
    const [confirmDialogProperties, setConfirmDialogProperties] = useState<ConfirmDialogProperties>({
        handleCancelFunction: () => console.log('Confirm dialog has not been initialized properly'),
        handleConfirmFunction: () => console.log('Confirm dialog has not been initialized properly'),
    })

    const productAnalyticsClient = useProductAnalyticsClient({
        user: currentUser,
        billableUnit: currentBillableUnit,
    })

    useEffect(() => {
        document.title = currentPageDetails?.documentTitle || 'RemotiveCloud'
        if (currentPageDetails?.productAnalyticsEventName) {
            productAnalyticsClient.page(currentPageDetails?.productAnalyticsEventName)
        }
    }, [currentPageDetails])

    useEffect(() => {
        if (currentUser) {
            productAnalyticsClient.identify(currentUser)
        }
    }, [currentUser])

    const updateCurrentPageDetails = (pageDetails: PageDetails) => {
        setCurrentPageDetails(pageDetails)
    }

    const openConfirmationDialog = (confirmDialogProperties: ConfirmDialogProperties) => {
        setConfirmDialogProperties(confirmDialogProperties)
        setShowConfirmDialog(true)
    }

    const [searchParams] = useSearchParams()
    const notifyUserAlreadyHasAccount = searchParams.get(NOTIFY_USER_HAS_ACCOUNT_PARAM)

    const urlPath = window.location.pathname

    const isTabActive = useIsTabActive()

    useEffect(() => {
        if (isTabActive) {
            userTokenTtlCheck()
        }
    }, [isTabActive])

    useInterval(() => {
        userTokenTtlCheck()
    }, 15 * 60 * 1_000) //every 15 minutes

    const userTokenTtlCheck = async () => {
        if (currentUser !== undefined) {
            const ttlResponse = await CloudApi.getTokenTtl()
            console.debug(
                `This session will end in ${ttlResponse.data.ttl} seconds and return the user to the login page`
            )
        }
    }

    const isAdminPage = () => {
        return urlPath === StaticPageRoutes.toAdmin()
    }

    const isNotFoundPage = () => {
        return urlPath === StaticPageRoutes.toNotFound()
    }

    const isLoginPage = () => {
        return urlPath === StaticPageRoutes.toLogin()
    }

    const isAgreementPage = () => {
        return urlPath === StaticPageRoutes.toAgreement()
    }

    const isScheduledForDeletionPage = () => {
        return urlPath === StaticPageRoutes.toScheduledForDeletion()
    }

    // Feedback form
    useScript(
        '//cdn.jsdelivr.net/npm/@betahuhn/feedback-js/dist/feedback-js.min.js',
        true,
        new Map([
            [
                'data-feedback-opts',
                '{ "endpoint": "https://remotive-feedback-handler-sglnqbpwoa-ez.a.run.app/feedback", "id": "RemotiveCloud", "emailField": true,"emailPlaceholder": "Email (optional)","inputPlaceholder": "Your feedback here... \\n\\nAdd your email if you don\'t want to be anonymous.","failedMessage": "Please try submitting your feedback again. If this keeps happening, contact us at support@remotivelabs.com","position": "right", "background": "white", "color": "#05315a", "primary": "#05315a","types": {"general": {"text": "Improvement","icon": "&bull;"},"bug": {"text": "Bug","icon": "&bull;"}}}',
            ],
        ]),
        !isDemo() && !isLoginPage() && !isAgreementPage() && !isScheduledForDeletionPage()
    )

    // Hubspot tracking
    useScript(
        '//js-eu1.hs-scripts.com/25376619.js',
        true,
        new Map([
            ['id', 'hs-script-loader'],
            ['type', 'text/javascript'],
        ]),
        isDemo()
    )

    // Cookie consent
    useScript(
        '//consent.cookiebot.com/uc.js',
        true,
        new Map([
            ['id', 'Cookiebot'],
            ['type', 'text/javascript'],
            ['data-cbid', '27dd0904-9072-478e-98db-ce6274935970'],
            ['data-blockingmode', 'auto'],
        ]),
        isDemo()
    )

    useEffect(() => {
        const organisationUid = urlPath.startsWith('/orgs/') ? urlPath.split('/')[2] : undefined
        const projectUid = urlPath.startsWith('/p/') ? urlPath.split('/')[2] : undefined
        if (
            (!isLoginPage() && organisationUid && currentBillableUnit?.organisation.uid !== organisationUid) ||
            (projectUid && currentProject?.uid !== projectUid)
        ) {
            setInitialAppState(organisationUid, projectUid)
        }
    }, [urlPath])

    const setInitialAppState = async (
        initialOrganisationUid: string | undefined,
        initialProjectUid: string | undefined
    ) => {
        forceValidRoute(initialOrganisationUid, initialProjectUid)
        const organisations = (await CloudApi.listOrganisations()).data
        const { currentBillableUnit, currentProject } = getCurrentBuAndProject(
            organisations,
            initialOrganisationUid,
            initialProjectUid
        )
        forceValidRoute(currentBillableUnit?.organisation.uid, currentProject?.uid)

        const user = (await CloudApi.whoami()).data
        setCurrentUser(user)
        setBillableUnits(organisations)
        setCurrentBillableUnit(currentBillableUnit)
        setCurrentProject(currentProject)
        if (notifyUserAlreadyHasAccount) {
            toast.info(
                formattedToastMessage(
                    'You already have an account!',
                    `You tried to sign up with ${user?.email}, but you already have an account on our platform.`
                ),
                { autoClose: 20_000, position: 'top-center', toastId: `${user?.email}` }
            )
        }
    }

    const forceValidRoute = (organisationUid: string | undefined, projectId: string | undefined) => {
        if (
            organisationUid === undefined &&
            projectId === undefined &&
            !isNotFoundPage() &&
            !isAdminPage() &&
            !isAgreementPage() &&
            !isScheduledForDeletionPage()
        ) {
            console.warn(`Could not find a page for path: ${urlPath}, forcing user to ${StaticPageRoutes.toNotFound()}`)
            window.location.replace(StaticPageRoutes.toNotFound())
        }
    }

    const getCurrentBuAndProject = (
        organisations: Array<UserBillableUnitInfo>,
        initialOrganisationUid: string | undefined,
        initialProjectUid: string | undefined
    ): { currentBillableUnit: UserBillableUnitInfo | undefined; currentProject: Project | undefined } => {
        if (initialOrganisationUid === undefined && initialProjectUid !== undefined) {
            // Project is set in URL but no organisation, if the project exists in any organisation we will default to that organisation
            let currentBillableUnit = organisations.find(
                (organisation) =>
                    organisation.projects.filter((project) => project.uid === initialProjectUid).length > 0
            )
            if (currentBillableUnit === undefined) {
                currentBillableUnit = organisations.find(
                    (organisation) =>
                        organisation.openProjects.filter((project) => project.uid === initialProjectUid).length > 0
                )
            }

            let currentProject = currentBillableUnit?.projects.find((project) => project.uid === initialProjectUid)
            if (currentProject === undefined) {
                currentProject = currentBillableUnit?.openProjects.find((project) => project.uid === initialProjectUid)
            }
            if (currentBillableUnit && currentProject) {
                return {
                    currentBillableUnit,
                    currentProject,
                }
            }
        } else if (initialOrganisationUid !== undefined && initialProjectUid === undefined) {
            // Organisation is set in URL but no project, if the organisation exists we default to the first project
            const organisation = organisations.find((org) => org.organisation.uid === initialOrganisationUid)
            if (organisation) {
                return {
                    currentBillableUnit: organisation,
                    currentProject: organisation.projects[0],
                }
            }
        }
        return {
            currentBillableUnit: undefined,
            currentProject: undefined,
        }
    }

    const updateCurrentProjectDisplayName = async (displayName: string) => {
        try {
            await CloudApi.editProject(currentProject!.uid, { displayName })
            const updatedProject = { ...currentProject!, displayName }
            const updatedProjectsArray = currentBillableUnit!.projects.map((project) => {
                if (project.uid === currentProject?.uid) {
                    return updatedProject
                }
                return project
            })
            setCurrentBillableUnit({ ...currentBillableUnit!, projects: updatedProjectsArray })
            setCurrentProject(updatedProject)
        } catch (error) {
            console.error('Failed to update project display name', error)
            toast.error(
                formattedToastMessage('Failed to update project display name', 'Please try again later or contact')
            )
        }
    }

    const updateCurrentProjectDescription = async (description: string) => {
        try {
            await CloudApi.editProject(currentProject!.uid, { description })
            setCurrentProject({ ...currentProject!, description })
        } catch (error) {
            console.error('Failed to update project description', error)
            toast.error(
                formattedToastMessage('Failed to update project description', 'Please try again later or contact')
            )
        }
    }

    return (
        <>
            <DarkModeProvider>
                <div className="App lexend-regular">
                    <Routes>
                        <Route
                            path={StaticPageRoutes.toAdmin()}
                            element={
                                <Admin updateCurrentPageDetails={updateCurrentPageDetails} currentUser={currentUser} />
                            }
                        />
                        <Route
                            path={StaticPageRoutes.toAgreement()}
                            element={<Agreement updateCurrentPageDetails={updateCurrentPageDetails} />}
                        />
                        <Route
                            path={StaticPageRoutes.toScheduledForDeletion()}
                            element={
                                <OrganisationToBeDeleted
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    currentUser={currentUser}
                                />
                            }
                        />

                        <Route
                            path={StaticPageRoutes.toLogin()}
                            element={<Login updateCurrentPageDetails={updateCurrentPageDetails} />}
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_HOME}
                            element={
                                <ProjectHome
                                    currentProject={currentProject}
                                    currentBillableUnit={currentBillableUnit}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    onProjectDisplayNameChange={updateCurrentProjectDisplayName}
                                    onProjectDescriptionChange={updateCurrentProjectDescription}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_BROKERS}
                            element={
                                <BrokerList
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentProject={currentProject}
                                    currentBillableUnit={currentBillableUnit}
                                    projects={currentBillableUnit?.projects || []}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_BROKER_DETAILS}
                            element={
                                <BrokerDetails
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    projects={currentBillableUnit?.projects || []}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_SETTINGS}
                            element={
                                <ProjectManagement
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentProject={currentProject}
                                    currentBillableUnit={currentBillableUnit}
                                    projects={currentBillableUnit?.projects || []}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_FILES}
                            element={
                                <Files
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentProject={currentProject}
                                    currentBillableUnit={currentBillableUnit}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_RECORDINGS}
                            element={
                                <RecordingsList
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentProject={currentProject}
                                    currentBillableUnit={currentBillableUnit}
                                    projects={currentBillableUnit?.projects || []}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_RECORDING_DETAILS}
                            element={
                                <RecordingDetails
                                    userProjectContext={
                                        {
                                            currentUser: currentUser,
                                            currentBillableUnit: currentBillableUnit,
                                            currentProject: currentProject,
                                        } as UserProjectContext
                                    }
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    projects={currentBillableUnit?.projects || []}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_SIGNAL_DATABASES}
                            element={
                                <SignalDatabaseList
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.PROJECT_SIGNAL_DETAILS}
                            element={
                                <SignalDetails
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.ORGANISATION_HOME}
                            element={
                                <Home
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    currentUser={currentUser}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.ORGANISATION_LICENSES}
                            element={
                                <LicenseList
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    currentUser={currentUser}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.ORGANISATION_USERS}
                            element={
                                <UserList
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />
                        <Route
                            path={DynamicRouteTemplate.ORGANISATION_SETTINGS}
                            element={
                                <OrganisationSettings
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    billableUnits={billableUnits}
                                    currentUser={currentUser}
                                    currentBillableUnit={currentBillableUnit}
                                    currentProject={currentProject}
                                    openConfirmationDialog={openConfirmationDialog}
                                />
                            }
                        />

                        {/* This route will catch anything that we haven't defined and send the user to the "not found" page */}
                        <Route
                            path="*"
                            element={
                                <NotFound
                                    updateCurrentPageDetails={updateCurrentPageDetails}
                                    currentBillableUnit={currentBillableUnit || billableUnits[0]}
                                />
                            }
                        />
                    </Routes>

                    <RemotiveToastContainer />
                    <ConfirmDialog
                        show={showConfirmDialog}
                        body={confirmDialogProperties?.body}
                        bodyText={confirmDialogProperties?.bodyText}
                        bodySubtitle={confirmDialogProperties?.bodySubtitle}
                        title={confirmDialogProperties?.dialogTitle}
                        confirmButtonText={confirmDialogProperties?.confirmButtonText}
                        cancelButtonText={confirmDialogProperties?.cancelButtonText}
                        handleConfirmFunction={confirmDialogProperties.handleConfirmFunction}
                        handleCancelFunction={confirmDialogProperties.handleCancelFunction}
                        handleCloseFunction={() => setShowConfirmDialog(false)}
                    />
                </div>
            </DarkModeProvider>
        </>
    )
}

export default App
