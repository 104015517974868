import { useEffect } from 'react'
import { Form, Modal } from 'react-bootstrap'

const MODAL_THEME_BACKGROUND = 'remotive-modal-header-bg'
const MODAL_THEME_COLOR = 'text-light'

interface FilePreviewModalProperties {
    show: boolean
    text: string
    title: string
    handleCloseFunction: () => void
}

export default function FilePreviewModal(props: FilePreviewModalProperties) {
    const closeModal = () => {
        props.handleCloseFunction()
    }

    useEffect(() => {
        if (props.show) {
            console.log('show')
        }
    }, [props.show])

    return (
        <Modal show={props.show} size='lg' onHide={closeModal} className='lexend-regular'>
            <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`} closeButton closeVariant="white">
                <Modal.Title className="text-truncate">{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group controlId="exampleForm.ControlTextarea1">
                        <Form.Control
                            as="textarea"
                            style={{resize: "none"}}
                            readOnly
                            className={'remotive-font-sm dark-and-light-placeholder'}
                            defaultValue={props.text}
                            rows={30}
                        />
                    </Form.Group>
                </Form>
                <Modal.Footer className='border-0'>
                    <p className={'remotive-font-sm'}>[Raw contents - max 10kb preview]</p>
                </Modal.Footer>
            </Modal.Body>
        </Modal>
    )
}
