import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded'
import HelpOutlineRounded from '@mui/icons-material/HelpOutlineRounded'
import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded'
import { useDarkMode } from 'src/hooks/useDarkMode'

interface TextBannerProps {
    className?: string
    type: BannerType
    boldText: string
    bodyTextElement: JSX.Element
}

type TextBannerOptions = {
    icon: JSX.Element
    background: string
    textColor: string
}

export enum BannerType {
    SUCCESS,
    WARNING,
    ERROR,
    INFO,
}

const ICON_SIZE = 20

const getStyle = (type: BannerType, isDarkMode: boolean) => {
    switch (type) {
        case BannerType.SUCCESS:
            return {
                icon: (
                    <>
                        <CheckCircleOutlineRounded sx={{ fontSize: ICON_SIZE }} className="remotive-success-70-color" />
                    </>
                ),
                background: 'remotive-success-30-background',
                textColor: 'remotive-success-90-color',
            }
        case BannerType.WARNING:
            return {
                icon: (
                    <>
                        <WarningAmberRounded sx={{ fontSize: ICON_SIZE }} className="remotive-warning-80-color" />
                    </>
                ),
                background: 'remotive-warning-30-background',
                textColor: 'remotive-warning-90-color',
            }

        case BannerType.ERROR:
            return {
                icon: (
                    <>
                        <ErrorOutlineRounded className={isDarkMode ? 'text-danger-subtle' : 'text-danger'} sx={{ fontSize: ICON_SIZE }} />
                    </>
                ),
                background: isDarkMode ? 'bg-danger' : 'bg-danger-subtle', 
                textColor: isDarkMode ? 'text-danger-subtle' : 'text-danger',
            }
        case BannerType.INFO:
            return {
                icon: (
                    <>
                        <HelpOutlineRounded sx={{ fontSize: ICON_SIZE }} />
                    </>
                ),
                background: isDarkMode ? 'remotive-primary-20-background' : 'remotive-primary-30-background',
                textColor: isDarkMode ? '' : 'remotive-primary-90-color',
            }
    }
}

export default function TextBanner(props: TextBannerProps) {
    const { isDarkMode } = useDarkMode()
    const style = getStyle(props.type, isDarkMode)

    return (
        <div className={`rounded-4 shadow-sm ${style.background} p-2 px-3 ${props.className}`}>
            <div className="d-flex align-items-center">
                <div className="me-3 remotive-font-sm">{style.icon}</div>
                <p className={`${style.textColor} remotive-font-sm lexend-bold m-0 text-nowrap`}>{props.boldText}</p>
                <div className="remotive-font-sm ms-3">{props.bodyTextElement}</div>
            </div>
        </div>
    )
}
