import {
    COMMON_COMPRESSED_FILE_TYPES,
    COMMON_IMAGE_FILE_TYPES,
    COMMON_SOUND_FILE_TYPES,
    COMMON_TEXT_FILE_TYPES,
    COMMON_VIDEO_FILE_TYPES,
} from './FileFormats'

import {
    DirectoryIcon,
    GenericFileIcon,
    ImageFileIcon,
    SoundFileIcon,
    TextFileIcon,
    VideoFileIcon,
    ZippedFileIcon,
} from '../../../assets/Icons'

export const getIconBasedOnFileName = (fileName: string, filePath: string, iconSize: number) => {
    if (filePath.endsWith("/")){
        return <DirectoryIcon sx={{ fontSize: iconSize }} className="remotive-success-100-color" />
    }
    if (COMMON_IMAGE_FILE_TYPES.some((it) => fileName.endsWith(it))) {
        return <ImageFileIcon sx={{ fontSize: iconSize }} className="remotive-primary-60-color" />
    }
    if (COMMON_TEXT_FILE_TYPES.some((it) => fileName.endsWith(it))) {
        return <TextFileIcon sx={{ fontSize: iconSize }} className="remotive-primary-60-color" />
    }
    if (COMMON_VIDEO_FILE_TYPES.some((it) => fileName.endsWith(it))) {
        return <VideoFileIcon sx={{ fontSize: iconSize }} className="remotive-primary-60-color" />
    }
    if (COMMON_SOUND_FILE_TYPES.some((it) => fileName.endsWith(it))) {
        return <SoundFileIcon sx={{ fontSize: iconSize }} className="remotive-primary-60-color" />
    }
    if (COMMON_COMPRESSED_FILE_TYPES.some((it) => fileName.endsWith(it))) {
        return <ZippedFileIcon sx={{ fontSize: iconSize }} className="remotive-primary-60-color" />
    }
    return <GenericFileIcon sx={{ fontSize: iconSize }} className="remotive-primary-60-color" />
}
