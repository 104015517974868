import { NavLink } from "react-router-dom"
import { ClickableArrowIcon, DeleteIcon, DownloadIcon } from "../assets/Icons"
import { Project, SignalDatabase, UserBillableUnitInfo } from "../api/CloudApi/types"
import { ProjectSignalRoutes } from "../Routes"
import { formatAsDatetime } from "../utils/datetime"
import { hasPermission, Permission } from "../utils/permission"
import { storeRecentSignalDatabase } from "../types/RecentSignalDatabase"
import { useCallback } from "react"

interface SignalDatabaseListItemProps {
    sdb: SignalDatabase
    project: Project | undefined
    billableUnit: UserBillableUnitInfo | undefined
    onDownload?: () => void
    onDelete?: () => void
}
export default function SignalDatabaseListItem(props: SignalDatabaseListItemProps) {

    const { sdb, project, billableUnit, onDownload, onDelete } = props

    const storeClickedSignalDatabase = useCallback((signalDatabase: SignalDatabase) => {
        if (project && billableUnit) {
            storeRecentSignalDatabase(
                signalDatabase,
                project,
                billableUnit
            )
        }
    }, [project, billableUnit])

    return (
        <div key={sdb.name} className="rounded remotive-primary-10-background m-1 mx-0 px-1">
            <div className="d-flex mb-1">
                <div className="col-6 col-md-8 p-1 border-end text-start">
                    <NavLink
                        to={ProjectSignalRoutes.toSignalDetails(
                            project?.uid,
                            sdb.name
                        )}
                        className={
                            'remotive-dark-color text-decoration-none remotive-btn-no-bg d-flex align-items-center'
                        }
                        onClick={() => storeClickedSignalDatabase(sdb)}
                    >
                        <div className="d-flex flex-column flex-grow-1 flex-truncate">
                            <p className="m-0 remotive-font-md text-truncate">{sdb.name} </p>
                            <p className="m-0 remotive-font-sm remotive-secondary-color text-truncate">
                                {sdb.type} - {sdb.size}
                            </p>
                        </div>
                        <ClickableArrowIcon className="ms-2" />
                    </NavLink>
                </div>
                <div className="col-4 col-md-3 p-1 px-2 text-start">
                    <p className="remotive-font-md text-truncate mb-0">Uploaded</p>
                    <p className="remotive-font-sm remotive-secondary-color mb-0 text-truncate">
                        {formatAsDatetime(sdb.uploaded)}
                    </p>
                </div>
                <div className="col-2 col-md-1 px-1 d-flex justify-content-center align-center">
                    {onDownload &&
                        <div className="mt-1">
                            <button
                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_VIEWER_CONFIG,
                                        billableUnit,
                                        project
                                    )
                                }
                                onClick={onDownload}
                            >
                                <DownloadIcon sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    }
                    {onDelete &&
                        <div className="mt-1">
                            <button
                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                disabled={
                                    !hasPermission(
                                        Permission.PROJECT_EDITOR_CONFIG,
                                        billableUnit,
                                        project
                                    )
                                }
                                onClick={onDelete}
                            >
                                <DeleteIcon sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}
