import { ReactElement, useState } from 'react'
import { FileObject, Project } from 'src/api/CloudApi/types'
import { LayoutType } from '.'
import { Dropdown, Form } from 'react-bootstrap'
import { DeleteIcon, DownloadIcon, ImportExportIcon, MoreVerticalIcon, ShareIcon, ViewIcon } from 'src/assets/Icons'
import { ALLOWED_RECORDING_FILE_NAME_ENDINGS } from 'src/utils/files'
import { getIconBasedOnFileName } from './utilities/FileIcons'
import FilePreviewModal from './FilePreviewModal'
import CloudApi from 'src/api/CloudApi'
import { toast } from 'react-toastify'
import { formattedToastMessage } from 'src/utils/toast'
import { ComponentState } from 'src/types/ComponentState'

interface FileObjectCardProps {
    title: string
    subtitle: string
    uploaded: string | undefined
    cardClassName: string
    moreButtonClassName: string
    isDirectory: boolean
    onClickFunction: () => void
    onCheckFunction: () => void
    isChecked: boolean
    onDownloadFunction: () => void
    onDeleteFunction: () => void
    onImportFunction: () => void
    onCopyLinkFunction: () => void
    fileObject: FileObject
    project: Project
    currentLayoutType: LayoutType
    ICON_SIZE: number
}

const getFileSuffix = (filename: string): string => {
    const parts = filename.split('.')
    return parts.length > 1 ? parts.pop() || '' : ''
}

const isImportRecordingPossible = (title: string) => {
    const suffix = getFileSuffix(title)
    return ALLOWED_RECORDING_FILE_NAME_ENDINGS.includes(suffix)
}

export default function FileObjectCard(props: FileObjectCardProps) {
    const [showFilePreviewModal, setShowFilePreviewModal] = useState(false)
    const [filePreviewModalText, setFilePreviewModalText] = useState('')
    const [filePreviewModalTitle, setFilePreviewModalTitle] = useState('')
    const [isLoadingPreview, setIsLoadingPreview] = useState(false) // Use this to set "more vert buttons" to loading during preview load

    const closeModal = () => {
        setShowFilePreviewModal(false)
        setFilePreviewModalText('')
    }

    const showPreviewModal = (fileObject: FileObject, project: Project) => {
        const downloadAndShow = async () => {
            try {
                const downloadUrl = await CloudApi.getStorageFileUrl(project, `${fileObject.path}`)
                const response = await CloudApi.downloadPreviewContentsFromSignedUrl(downloadUrl.data)
                setFilePreviewModalText(response.data)
                setShowFilePreviewModal(true)
                setFilePreviewModalTitle(fileObject.name)
            } catch (e: any) {
                console.error(e)
                toast.error(
                    formattedToastMessage('File error', 'Failed to download selected file, please try again later.')
                )
            }
        }

        downloadAndShow()
    }

    return (
        <>
            <div
                className={`rounded-3 ${props.currentLayoutType === LayoutType.LIST ? 'flex-grow-1' : 'p-1 m-1'} ${
                    props.cardClassName
                }`}
                style={{ marginBottom: 3 }}
            >
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <div
                        className={`d-flex flex-row align-items-center flex-truncate ${
                            props.currentLayoutType === LayoutType.LIST ? 'flex-grow-1 py-1' : ''
                        }`}
                    >
                        {/* Select All Checkbox */}
                        <div className="ms-2 me-3  d-flex align-items-center">
                            <Form.Check
                                type="checkbox"
                                checked={props.isChecked}
                                onChange={() => {
                                    props.onCheckFunction()
                                }}
                            />
                        </div>
                        <div className="ms-2 me-3  d-flex align-items-center">
                            {getIconBasedOnFileName(props.fileObject.name, props.fileObject.path, props.ICON_SIZE)}
                        </div>
                        <div
                            title={props.title}
                            className={`d-flex ${
                                props.currentLayoutType === LayoutType.LIST
                                    ? 'flex-row flex-grow-1 align-items-end'
                                    : 'flex-column'
                            } flex-truncate clickable-underlined-on-hover `}
                            onClick={props.onClickFunction}
                        >
                            <p
                                className={`text-truncate text-start m-0 me-2 ${
                                    props.currentLayoutType === LayoutType.LIST
                                        ? 'remotive-font-sm'
                                        : 'remotive-font-md'
                                }`}
                            >
                                {props.title}
                            </p>
                            <p
                                style={{
                                    marginBottom: props.currentLayoutType === LayoutType.LIST ? 1 : 0,
                                    marginRight: props.currentLayoutType === LayoutType.LIST ? 5 : 0,
                                }}
                                className="text-truncate text-start remotive-secondary-color remotive-font-xs"
                            >
                                {props.subtitle}
                            </p>
                            <p
                                style={{ marginBottom: props.currentLayoutType === LayoutType.LIST ? 1 : 0 }}
                                className="text-truncate text-start remotive-secondary-color remotive-font-xs"
                            >
                                {props.uploaded !== undefined && props.uploaded.length > 0 ? (
                                    `${new Date(props.uploaded).toLocaleString()}`
                                ) : (
                                    <wbr />
                                )}
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="pe-2 py-0 d-flex align-items-center">
                            {!props.isDirectory && (
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className={`p-0 bg-transparent border-0 ${props.moreButtonClassName}`}
                                    >
                                        <MoreVerticalIcon sx={{ fontSize: props.ICON_SIZE }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ minWidth: 120 }} className="remotive-font-md">
                                        <Dropdown.Item className="px-2" onClick={props.onDownloadFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Download</p>
                                                <DownloadIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                            className="px-2"
                                            onClick={() => showPreviewModal(props.fileObject, props.project)}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Preview</p>
                                                <ViewIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={props.onDeleteFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Delete</p>
                                                <DeleteIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={props.onCopyLinkFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Share link</p>
                                                <ShareIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        {isImportRecordingPossible(props.title) && (
                                            <Dropdown.Item className="px-2" onClick={props.onImportFunction}>
                                                <div className="d-flex flex-row align-items-center justify-content-between">
                                                    <p className="m-0 remotive-font-sm">
                                                        Import as recording&nbsp;&nbsp;&nbsp;
                                                    </p>
                                                    <ImportExportIcon sx={{ fontSize: 15 }} className="me-2" />
                                                </div>
                                            </Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                            {props.isDirectory && (
                                <Dropdown>
                                    <Dropdown.Toggle
                                        className={`p-0 bg-transparent border-0 ${props.moreButtonClassName}`}
                                    >
                                        <MoreVerticalIcon sx={{ fontSize: props.ICON_SIZE }} />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{ minWidth: 120 }} className="remotive-font-md">
                                        <Dropdown.Item className="px-2" onClick={props.onDeleteFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Delete</p>
                                                <DeleteIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="px-2" onClick={props.onCopyLinkFunction}>
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <p className="m-0 remotive-font-sm">Share link</p>
                                                <ShareIcon sx={{ fontSize: 15 }} className="me-2" />
                                            </div>
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <FilePreviewModal
                show={showFilePreviewModal}
                text={filePreviewModalText}
                title={filePreviewModalTitle}
                handleCloseFunction={() => closeModal()}
            />
        </>
    )
}
