import { useEffect } from 'react'
import { useState } from 'react'
import { Formik } from 'formik'
import { object, string, number, date, InferType } from 'yup'
import { Modal, Form, InputGroup, Spinner, Col, Row, Button, Accordion } from 'react-bootstrap'
import {
    Project,
    ProcessRecordingDataRequest,
    RecordingSession,
    CreateRecordingAnnotationRequest,
} from '../../../../api/CloudApi/types'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../utils/toast'
import LoadingContainer from '../../../../components/LoadingContainer'
import ErrorContainer from '../../../../components/ErrorContainer'
import CloudApi from '../../../../api/CloudApi'
import { SuccessCheckboxIcon, SuccessCheckmarkIcon, SuccessIcon } from '../../../../assets/Icons'
import { formatAsDate, formatAsDatetime, formatAsHHMMSSmmm, millisToZonedDateTime } from '../../../../utils/datetime'

import { DateTimeFormatter, Duration, Instant, ZonedDateTime, ZoneId } from '@js-joda/core'

interface AddAnnotationModalProps {
    currentProject: Project | undefined
    currentRecordingSession: RecordingSession | undefined
    annotationTimestamp: number | undefined
    annotationTimestampEnd: number | undefined
    show: boolean
    handleCloseFunction: () => void
    getAllAnnotations: Function
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

const NOT_STARTED_STATE = 'NOT_STARTED'
const IN_PROGRESS_STATE = 'IN_PROGRESS'
const DONE_STATE = 'DONE'
type RequestState = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE'

export default function AddAnnotationModal(props: AddAnnotationModalProps) {
    const [annotationTitle, setAnnotationTitle] = useState<string>()
    const [annotationComment, setAnnotationComment] = useState<string>()
    const [requestState, setRequestState] = useState<RequestState>(NOT_STARTED_STATE)
    const [knownErrorMessage, setKnownErrorMessage] = useState<string>()

    useEffect(() => {
        console.debug('Mounted add annotation modal!')
    }, [])

    const resetState = () => {
        setRequestState(NOT_STARTED_STATE)
        setKnownErrorMessage(undefined)
    }

    const createAnnotation = async (annotationTitle: string, annotationComment: string | undefined) => {
        if (
            props.currentProject !== undefined &&
            props.annotationTimestamp !== undefined &&
            props.currentRecordingSession !== undefined
        ) {
            setRequestState(IN_PROGRESS_STATE)
            setKnownErrorMessage(undefined)
            let duration_ms = undefined
            if (props.annotationTimestampEnd !== undefined) {
                duration_ms = props.annotationTimestampEnd - props.annotationTimestamp
            }

            try {
                let duration = undefined
                if (duration_ms) {
                    const nanoseconds = Math.round(duration_ms * 1_000_000) // Convert ms to ns
                    // Create a Duration using ofNanos
                    duration = Duration.ofNanos(nanoseconds)
                }

                const recordingAnnotationRequest = {
                    title: annotationTitle,
                    comment:
                        annotationComment === '' || annotationComment === undefined ? undefined : annotationComment,
                    timestamp: millisToZonedDateTime(props.annotationTimestamp),
                    duration: duration,
                } as CreateRecordingAnnotationRequest
                await CloudApi.createRecordingAnnotation(
                    props.currentProject,
                    props.currentRecordingSession,
                    recordingAnnotationRequest
                )
                setRequestState(DONE_STATE)
                await props.getAllAnnotations()
                closeModal()
            } catch (err: any) {
                // Handle known errors here
                console.log(err)
                toast.error(
                    formattedToastMessage(
                        'Error',
                        `${
                            err.response?.data ||
                            'Unknown error when trying to create the annotation. Please refresh the application and try again.'
                        }`
                    )
                )
                setRequestState(NOT_STARTED_STATE)
            }
        } else {
            toast.error(
                formattedToastMessage(
                    'Error',
                    'Could not create the annotation due to a parameter error. Please refresh the application and try again.'
                )
            )
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    {
        /*
      lastName: yup.string().required(),
            username: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
            zip: yup.string().required(),
            terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    */
    }

    const getForm = () => {
        const schema = object().shape({
            comment: string()
                .optional()
                .max(300, "The comment can't exceed 300 characters")
                .min(1, 'The comment has to be at least 1 character'),
            annotationTitle: string()
                .required('Field is required')
                .max(30, "The title can't exceed 30 characters")
                .min(1, 'The title must be at least 1 character long'),
        })

        return (
            <Formik
                // After an error this is used to fake that these values has already been "touched"
                initialTouched={{
                    annotationTitle: annotationTitle !== undefined,
                    annotationComment: annotationComment !== undefined,
                }}
                validationSchema={schema}
                onSubmit={(event: any) => {
                    createAnnotation(event.annotationTitle, event.comment)
                }}
                initialValues={{
                    annotationTitle: annotationTitle ? annotationTitle : '',
                    comment: undefined,
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className={'mb-3'}>
                            <Form.Group as={Row}>
                                <InputGroup size="sm" hasValidation={true} className="d-flex flex-column mb-3">
                                    <div className="d-flex flex-column mb-1">
                                        <InputGroup.Text className={`remotive-font-md bg-transparent border-0 m-0 p-0`}>
                                            Title
                                        </InputGroup.Text>
                                        <p className="remotive-font-xs remotive-secondary-color m-0">
                                            The title we be placed on the annotation in the graph
                                        </p>
                                    </div>
                                    <div className="d-flex flex-column">
                                        <Form.Control
                                            placeholder={'E.g speed anomaly #1...'}
                                            type="text"
                                            className="remotive-font-sm dark-and-light-placeholder"
                                            onBlur={handleBlur} // This apparently
                                            name={'annotationTitle'}
                                            value={values.annotationTitle}
                                            onChange={handleChange}
                                            isValid={
                                                (!errors.annotationTitle as boolean) &&
                                                (touched.annotationTitle as boolean)
                                            }
                                            isInvalid={errors.annotationTitle !== undefined}
                                        />
                                        <p className="remotive-font-xxs text-danger m-0 ms-1">
                                            * required
                                        </p>
                                        <Form.Control.Feedback type="invalid" className="remotive-font-sm ms-1">
                                            <>{errors.annotationTitle}</>
                                        </Form.Control.Feedback>
                                    </div>
                                </InputGroup>
                            </Form.Group>
                            <Form.Group as={Row} controlId={'descValidationId'}>
                                <InputGroup size="sm" hasValidation={true} className="d-flex flex-column">
                                    <div className="d-flex flex-column mb-1">
                                        <InputGroup.Text className={`remotive-font-sm bg-transparent border-0 m-0 p-0`}>
                                            Comment
                                        </InputGroup.Text>
                                        <p className="remotive-font-xs remotive-secondary-color m-0">
                                            An optional initial comment
                                        </p>
                                    </div>
                                    <Form.Control
                                        as="textarea"
                                        style={{ minHeight: 85, resize: 'none' }}
                                        className="remotive-font-sm w-100 dark-and-light-placeholder"
                                        placeholder="E.g We hit a speed bump here, signal ABC123_456 seems to be wrong..."
                                        onBlur={handleBlur} // This apparently
                                        name={'comment'}
                                        value={values.comment}
                                        onChange={handleChange}
                                        isValid={(!errors.comment as boolean) && (touched.comment as boolean)}
                                        isInvalid={errors.comment !== undefined}
                                    />
                                    <Form.Control.Feedback type="invalid" className="remotive-font-sm ms-1">
                                        <>{errors.comment}</>
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>

                        <div className="d-flex justify-content-end">
                            <Button
                                className="btn remotive-btn remotive-btn-success align-center"
                                disabled={!isValid}
                                type={'submit'}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Create annotation</p>
                                </div>
                            </Button>
                        </div>
                        {knownErrorMessage && (
                            <div className={'p-3 remotive-warning-90-color'}>{knownErrorMessage}</div>
                        )}
                    </Form>
                )}
            </Formik>
        )
    }

    const annotationTimestampOrInterval = () => {
        if (props.annotationTimestamp !== undefined && props.annotationTimestampEnd !== undefined)
            return (
                <p className="remotive-font-sm remotive-secondary-color m-0">
                    {`This annotation will be placed between ${formatAsHHMMSSmmm(
                        new Date(props.annotationTimestamp).toISOString()
                    )} and ${formatAsHHMMSSmmm(new Date(props.annotationTimestampEnd).toISOString())}`}
                </p>
            )

        if (props.annotationTimestamp !== undefined)
            return (
                <p className="remotive-font-sm remotive-secondary-color m-0">
                    {`This annotation will be placed on ${formatAsHHMMSSmmm(
                        new Date(props.annotationTimestamp).toISOString()
                    )}`}
                </p>
            )
        return <p className="remotive-font-sm remotive-secondary-color m-0">No timestamp selected</p>
    }

    const getModalContent = () => {
        switch (requestState) {
            case IN_PROGRESS_STATE:
                return (
                    <>
                        <Modal.Header style={{ marginBottom: -30, zIndex: 10 }} className={`border-0`}></Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <LoadingContainer spinnerSize="sm" loadingText="Creating annotation..." />
                        </Modal.Body>
                    </>
                )

            case DONE_STATE:
                return (
                    <>
                        <Modal.Header style={{ marginBottom: -30, zIndex: 10 }} className={`border-0`}></Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <SuccessIcon sx={{ fontSize: 55 }} className="my-2 remotive-success-60-color" />
                                <p className="remotive-font-lg m-1 mb-0">Annotation created!</p>
                                <div className="d-flex justify-content-center m-1 align-items-center">
                                    <Spinner size="sm" style={{ height: 10, width: 10 }} />
                                    <p className="remotive-font-sm remotive-secondary-color mb-0 ms-1">
                                        Fetching all annotations...
                                    </p>
                                </div>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header closeButton className={`border-0 lexend-regular mb-1 pb-1`}>
                            <div className="d-flex flex-column">
                                <p className="m-0 remotive-font-lg">New annotation</p>
                                {annotationTimestampOrInterval()}
                            </div>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">{getForm()}</Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
