import { number } from 'yup'

export type FrameMetadata = {
    frameNsId: number
    name: string
    id: number
    sender: string | undefined
    namespace: string
    cycleTime: number
    signals: Array<FrameSignalMetadata>
}

export type FrameSignalMetadata = {
    frameSignalId: number
    name: string
    min: number
    max: number
    unit: string
    signalNameOptions: Array<SignalNameOption>
}

export type Frame = {
    timestamp: number
    frameNsId: number
    hex: string
    signals: Array<Signal> | undefined
}

export type Signal = {
    frameSignalId: number
    value: number
}

export type SignalMetadata = {
    name: string
    namespace: string
    min: number
    max: number
    unit: string
    description: string
}

export type TwoDimensionalQueryResult = {
    name: string
    annotations: Map<string, number>
    values: Array<Array<number>>
}

export type SignalList = {
    metadata: Array<SignalMetadata>
    signals: Array<SignalListEntry>
}

export type FrameList = {
    metadata: Array<FrameMetadata>
    signals: Array<Frame>
}

export type SignalNameList = {
    signals: Array<SignalNameEntry>
}

export type SignalIdentifier = {
    namespace: string | undefined
    frameName: string
    signalName: string
}

export type SignalNameOption = {
    value: number
    name: string
}
export type SignalEntry = {
    frameName: string
    frameNsId: number
    frameSignalId: number
    name: string
    namespace: string
    start: number | undefined
    length: number | undefined
    byteOrder: string | undefined
    isSigned: boolean | undefined
    min: number | undefined
    max: number | undefined
    unit: string
    comments: string
    signalNameOptions: Array<SignalNameOption>
}

export type RecordingAnnotation = {
    title: string
    id: number
    created: string
    comments: Array<RecordingAnnotationComment>
    createdBy: AnnotationAuthor
    timestamp: string
    duration: string | undefined
}

export type CreateRecordingAnnotationRequest = {
    title: string
    comment: string | undefined
    timestamp: string
    duration: string | undefined
}

export type EditRecordingAnnotationRequest = {
    title: string
}

export type RecordingAnnotationComment = {
    id: string
    author: AnnotationAuthor
    created: string
    text: string
}

export type RecordingAnnotationCommentRequest = {
    text: string
}

export type AnnotationAuthor = {
    uid: string
    displayName: string
}

export type ProcessRecordingDataRequest = {
    deleteCurrentData?: boolean
}

export type VisualizeProcessingTracker = {
    name: string
    step: VisualizeProcessingTrackerStep
    status: VisualizeProcessingTrackerStatus
    errors: Array<ProcessingError>
}

export enum VisualizeProcessingTrackerStep {
    DECODE_DBC = 'DECODE_DBC',
    STORE_DBC = 'STORE_DBC',
    DECODE_RECORDINGS = 'DECODE_RECORDINGS',
    STORE_RECORDINGS = 'STORE_RECORDINGS',
}

export enum VisualizeProcessingTrackerStatus {
    QUEUED = 'QUEUED',
    RUNNING = 'RUNNING',
    FINISHED = 'FINISHED',
    FAILED = 'FAILED',
}

export type FrameEntry = {
    name: string
    namespace: string
    cnt: number
    frameNsId: number
    hasNumericSignals: boolean
    id: number
    signalDb: string
    cycleTime: number
    comments: string
    senders: Array<string>
    receivers: Array<string>
    signals: Array<SignalEntry>
}

export type SignalNameEntry = {
    name: string
    occurrences: number
}

export type SignalListEntry = {
    name: string
    signals: Array<Array<number>>
}

export type UserLicense = {
    creator: string
    licenses: Array<License>
}

export type License = {
    created: string
    expires: string
    creator: string
    hash: string
    renewCount: number
}

export type BrokerContainer = {
    name: string // TODO - Remove this or rename shortName to name
    shortName: string
    url: string | undefined
    labels: Labels
    creator: ContainerCreator
    createTime: string
    status: string
    keys: Array<string>
    tag: string | undefined
}

export type ContainerCreator = {
    uid: string
    email: string
    displayName: string
}

export type Labels = {
    creator: string
    bu: string
    project: string
}

export type ProjectServiceAccountDTO = {
    creator: string
    created: string
    name: string
    roles: Array<string>
}

export type ProjectServiceAccount = {
    creator: string
    created: string
    name: string
    roles: Array<string>
}

export type ServiceAccountKeyCreatedDTO = {
    created: string
    expires: string
    name: string
    project: string
    token: string
    serviceAccount: string
}

export type CreateServiceAccountTokenDTO = {
    daysUntilExpiry: number
}

export type CreateProjectServiceAccountDTO = {
    name: string
    roles: Array<string>
}

export type EditProjectServiceAccountDTO = {
    roles: Array<string>
}

// TODO split to smaller files, TOKEN RELATED STUFF BELOW

export type AccessToken = {
    creator: string
    created: string
    name: string
    expires: string
    revoked: boolean
}

export type PersonalAccessTokenDTO = {
    expires: string
    revoked: boolean
    name: string
    created: string
}

export type ServiceAccountTokenDTO = {
    expires: string
    revoked: boolean
    creator: string
    name: string
    created: string
}

export type UserInfoWithRoles = {
    user: UserInfo
    roles: Array<string>
}

export type UserInfo = {
    uid: string
    displayName: string
    email: string
}

export type CreateUserInfoWithRoles = {
    user: NewUserInfo
    roles: Array<string>
}

export type CreateProjectRequest = {
    uid: string
    displayName: string
    description: string
}

export type ProjectInfo = {
    isOpen?: boolean
    displayName?: string
    description?: string
}

export type CreateBuRequest = {
    displayName: string
}

export type AgreementsAnswersRequest = {
    eulaAccepted: boolean
    privacyPolicyAccepted: boolean
}

export enum FeatureKey {
    VISUALIZE_RECORDING = 'VISUALIZE_RECORDING',
}

export type FeatureEnabledStateRequest = {
    isEnabled: boolean
    featureKey: FeatureKey
}

export type NewUserInfo = {
    displayName: string
    email: string
    uid: string | undefined
}

export type UserBillableUnitInfo = {
    organisation: Organisation
    projects: Array<Project>
    openProjects: Array<Project>
}

export type BillableUnit = {
    displayName: string
    description: string
    uid: string
}

export type ContainerSpec = {
    size: string
}

export type Folder = {
    type: string
    files: Array<File>
}

export type RecordingSession = {
    displayName: string
    description: string | undefined
    sessionId: string
    uploaded: string
    recordings: Array<RecordingFile>
    mediaFiles: Array<MediaFile>
    sourceRecordingFiles: Array<SourceRecordingFile>
    brokerConfigurations: Array<BrokerConfigurationFolder>
}

export type CopiedRecordingSessionDTO = {
    sessionId: string
}

export type RecordingMetaData = {
    namespace: string | undefined
    totalFrameIds: number
    database: string | undefined
    databaseDetails?: {
        name: string
        selection: DatabaseSelectionType
    }
    automatchDetails?: {
        database: string
        matchingFrameIds: number
    }
    error: boolean
    message: string | undefined
    type: string | undefined
}

export type AutomatchDetail = {
    totalFrameIds: number
    matchingFrameIds: number
    databaseName: String
}

export enum DatabaseSelectionType {
    USER = 'USER',
    AUTO = 'AUTO',
    RECORDING = 'RECORDING',
}

export type RecordingFile = {
    fileName: string
    downloadUrl: string
    displayName: string
    metadata: RecordingMetaData | undefined
    type: string | undefined
}

export type ProcessingUploadFile = {
    fileName: string
    uploadId: string
    parentRecordingSessionId: string | undefined
    errors: Array<ProcessingError>
    step: ProcessingUploadFileStep
    status: ProcessingUploadFileStatus
}

export enum ProcessingUploadFileStep {
    REQUESTED = 'REQUESTED',
    VALIDATING = 'VALIDATING',
    CONVERT = 'CONVERT',
    SPLIT = 'SPLIT',
    STORE = 'STORE',
    SIGNALDB_AUTOMATCH = 'SIGNALDB_AUTOMATCH',
    INDEXING = 'INDEXING',
    FINALIZE = 'FINALIZE',
    ZIP = 'ZIP',
    DONE = 'DONE',
    CLEANUP = 'CLEANUP', // This is done after success
}

export enum ProcessingUploadFileStatus {
    READY = 'READY',
    RUNNING = 'RUNNING',
    DONE = 'SUCCESS',
    FAILED = 'FAILED',
}

export type ProcessingError = {
    message: string
    code: number
}

export type MediaFile = {
    fileName: string
    downloadUrl: string
    displayName: string
    mimeType: string
    sizeInBytes: number
    preferDownload: boolean
    startTimeZoned: string
}

export type SourceRecordingFile = {
    fileName: string
    downloadUrl: string
    displayName: string
    mimeType: string
    sizeInBytes: number
}

export type BrokerConfigurationFolder = {
    name: string
    files: Array<BrokerConfigurationFile>
}

export type BrokerConfigurationFile = {
    name: string
}

export type TokenTtl = {
    ttl: number
}

export type AuthenticatedUser = {
    email: string
    firstName: string
    lastName: string
    picture: string | undefined
    uid: string
}

export type Organisation = {
    description: string
    displayName: string
    uid: string
    permissions: Array<string>
}

export interface Project {
    uid: string
    displayName: string
    description: string
    roles: Array<string>
    permissions: Array<string>
    isOpen: boolean
}

export interface SignalDatabase {
    name: string
    uploaded: string
    type: string
    size: string
}

export interface SignalDatabaseFrame {
    frameName: string
    id: number
    fileName: string
    cycleTime: number
    comments: Array<string>
    senders: Array<string>
    receivers: Array<string>
    signals: Array<SignalEntry>
}

export const Status = {
    IDLE: 'IDLE',
    RUNNING: 'RUNNING',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
}

export interface GenericFileDownloadUrlResponse {
    downloadUrl: string
}

export type FileObject = {
    name: string
    type: FileObjectTypeEnum
    size?: number
    path: string
    uploaded: string | undefined
}

export type FileObjectsContainer = FileObject & {
    objects: Array<FileObject> | undefined
}

export enum FileObjectTypeEnum {
    DIRECTORY = 'DIRECTORY',
    FILE = 'FILE',
}

export interface StorageUploadResponse {
    url: string
    headers: Map<string, string>
    contentType: string | undefined
}
