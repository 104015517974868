import React, { useEffect } from 'react'
import { DarkModeRounded, LightModeRounded } from '@mui/icons-material'
import { useDarkMode } from '../hooks/useDarkMode'
import { isRemotiveUser } from 'src/utils/featureToggle'
import { AuthenticatedUser } from 'src/api/CloudApi/types'

interface DarkModeToggleProps {
    user: AuthenticatedUser
}

export default function DarkModeToggle(props: DarkModeToggleProps) {
    const { isDarkMode, setIsDarkMode } = useDarkMode()

    useEffect(() => {
        // Check for the `is_dark_mode` query parameter
        const params = new URLSearchParams(window.location.search)
        const isDarkModeQueryParam = params.get('is_dark_mode')

        if (isDarkModeQueryParam !== null) {
            // If the query parameter is present, override local storage
            setIsDarkMode(isDarkModeQueryParam === 'true')
        }
    }, [setIsDarkMode])

    const toggleDarkMode = () => {
        setIsDarkMode((prevMode) => !prevMode)
    }

    return (
        <>
            {isRemotiveUser(props.user) ? (
                <>
                    <div
                        onClick={toggleDarkMode}
                        className="remotive-primary-30-background"
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            cursor: 'pointer',
                            padding: '5px',
                            borderRadius: '20px',
                            position: 'relative',
                            width: '45px',
                            height: '17px',
                        }}
                    >
                        <div
                            className="remotive-primary-60-background"
                            style={{
                                position: 'absolute',
                                left: isDarkMode ? '20px' : '5px',
                                transition: 'left 0.3s ease',
                                background: '#fff',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            {isDarkMode ? (
                                <DarkModeRounded className="remotive-white-color" sx={{ fontSize: 15 }} />
                            ) : (
                                <LightModeRounded className="remotive-primary-0-color" sx={{ fontSize: 15 }} />
                            )}
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    )
}
