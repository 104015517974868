import { debounce } from 'lodash'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Form, InputGroup, Spinner } from 'react-bootstrap'
import { SearchIcon } from 'src/assets/Icons'

interface SearchBarInterfaceProps {
    autoFocus: boolean
    placeHolder?: string
    handleSearch: (keyToSearchFor: string, signal: AbortSignal) => Promise<void>
}

export default function SearchBar(props: SearchBarInterfaceProps) {

    const [searchValue, setSearchValue] = useState<string>('')
    const [isSearching, setIsSearching] = useState<boolean>(false)
    const abortControllerRef = useRef<AbortController | null>(null)

    const debouncedHandleSearch = useCallback(
        debounce(async (value: string) => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }

            const newAbortController = new AbortController()
            abortControllerRef.current = newAbortController

            try {
                await props.handleSearch(value, newAbortController.signal)
            } catch (err: any) {
                if (err.name !== 'AbortError') {
                    console.error('Search error:', err)
                }
            } finally {
                setIsSearching(false)
            }
        }, 200), // Will only run ONCE even if it is invoked multiple times within 200ms of the last invocation
        [props.handleSearch]
    )

    useEffect(() => {
        if (searchValue !== undefined) {
            setIsSearching(true)
            debouncedHandleSearch(searchValue)
        }
        return () => {
            debouncedHandleSearch.cancel()
            if (abortControllerRef.current) {
                abortControllerRef.current.abort()
            }
        }
    }, [searchValue])

    const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const input = event.target.value
        setSearchValue(input)
    }

    const component = () => {
        return (
            <InputGroup>
                <div className="d-flex flex-row justify-content-start w-100">
                    <InputGroup.Text
                        style={{ zIndex: 0 }}
                        className="rounded-start-5 rounded-0 border-1 border-end-0"
                    >
                        {isSearching ? (
                            <Spinner style={{ height: 15, width: 15, marginLeft: 3, marginRight: 3 }} size="sm" />
                        ) : (
                            <SearchIcon sx={{ fontSize: 21 }} className="px-0" />
                        )}
                    </InputGroup.Text>
                    <Form.Control
                        style={{ zIndex: 1 }}
                        className="rounded-end-5 rounded-0 ps-0 pe-3 border-1 border-start-0 remotive-dark-color dark-and-light-placeholder"
                        autoFocus={props.autoFocus}
                        placeholder={props.placeHolder ?? `Type to search...`}
                        value={searchValue}
                        aria-label="Small"
                        aria-describedby="inputGroup-sizing-sm"
                        onChange={handleInput}
                    />
                </div>
            </InputGroup>
        )
    }
    return component()
}
