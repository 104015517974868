import {
    AuthenticatedUser,
    Project,
    SignalDatabaseFrame,
    SignalEntry,
    SignalNameOption,
    UserBillableUnitInfo,
} from '../../api/CloudApi/types'
import { useParams } from 'react-router-dom'
import CloudApi from '../../api/CloudApi'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ProjectRoutes } from '../../Routes'
import { BreadCrumb, BreadCrumbs, buAndProjectTrail } from '../../types/BreadCrumbs'
import { Card, Container } from 'react-bootstrap'
import NavigationBar from '../../components/navigation/NavigationBar'
import { PageDetails } from '../../utils/pageDetails'
import { ConfirmDialogProperties } from '../../types/ConfirmDialogProperties'
import { isGuidedDemo } from '../../utils/demo'
import { ArrowWestIcon, SignalDatabasesIcon } from '../../assets/Icons'
import HelpOverlayModalModal from '../../components/modals/HelpOverlayModal'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { ColDef, IDetailCellRendererParams, ITextFilterParams } from 'ag-grid-community'
import SearchInput from 'src/components/SearchInput'
import LoadingContainer from 'src/components/LoadingContainer'
import ErrorContainer from 'src/components/ErrorContainer'
import { useDarkMode } from 'src/hooks/useDarkMode'

interface SignalDetailsProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
    openConfirmationDialog: (confirmDialogProperties: ConfirmDialogProperties) => void
}

enum PageState {
    Loading,
    Loaded,
    Error,
}

export default function SignalDetails(props: SignalDetailsProps) {
    const gridRef = useRef<AgGridReact>(null)
    const { signalFileName } = useParams()
    const [signalData, setSignalData] = useState<Array<SignalDatabaseFrame>>([])
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())
    const [pageState, setPageState] = useState(PageState.Loading)
    const { isDarkMode } = useDarkMode()

    const downloadData = useCallback(async () => {
        if (props.currentProject?.uid && signalFileName) {
            try {
                const data = (await CloudApi.getSignalDatabaseFile(props.currentProject.uid, signalFileName)).data
                setSignalData(data)
                setPageState(PageState.Loaded)
            } catch (error) {
                setPageState(PageState.Error)
            }
        }
    }, [props.currentProject, signalFileName])

    useEffect(() => {
        setPageState(PageState.Loading)
        downloadData()
    }, [downloadData, signalFileName])

    const frameFilterParams: ITextFilterParams = {
        filterOptions: ['contains', 'notContains'],
        debounceMs: 200,
        maxNumConditions: 1,
    }

    const StringListRenderer = (props: any) => {
        const items = props.value || [] // The value passed to the cell
        return (
            <div>
                {items.map((item: any, index: number) => (
                    <div key={index}>{item}</div>
                ))}
            </div>
        )
    }

    const SignalNameOptionRenderer = (props: any) => {
        const items = props.value || [] // The value passed to the cell
        return (
            <div>
                {items.map((item: SignalNameOption, index: number) => (
                    <div key={index}>
                        {item.value} {item.name}
                    </div>
                ))}
            </div>
        )
    }

    const colDefs = useMemo(() => {
        return [
            {
                headerName: 'Frame Name',
                field: 'frameName',
                filter: 'agTextColumnFilter',
                filterParams: frameFilterParams,
                cellRenderer: 'agGroupCellRenderer',
            },
            {
                headerName: 'Id',
                field: 'id',
                cellRenderer: (params: any) => `${params.value}  / 0x${params.value.toString(16).toUpperCase()}`,
            },
            { headerName: 'Cycle Time', field: 'cycleTime' },
            { headerName: 'Comments', field: 'comments' },
            { headerName: 'Senders', field: 'senders', autoHeight: true, cellRenderer: StringListRenderer },
            {
                headerName: 'Receivers',
                field: 'receivers',
                autoHeight: true,
                cellRenderer: StringListRenderer,
            },
            {
                headerName: 'Signals',
                field: 'signals',
                hide: true,
                valueGetter: (params) => {
                    return params.data.signals.map((signal: SignalEntry) => signal.name)
                },
            },
        ] as ColDef[]
    }, [])

    const detailCellRendererParams = useMemo(() => {
        return {
            detailGridOptions: {
                columnDefs: [
                    { headerName: 'Name', field: 'name', filter: 'agTextColumnFilter', flex: 2 },
                    { headerName: 'Start', field: 'start', flex: 0.7 },
                    { headerName: 'Length', field: 'length', flex: 0.7 },
                    { headerName: 'Byte Order', field: 'byteOrder' },
                    { headerName: 'Is Signed', field: 'isSigned' },
                    { headerName: 'Min', field: 'min', flex: 0.7 },
                    { headerName: 'Max', field: 'max', flex: 0.7 },
                    { headerName: 'Unit', field: 'unit', flex: 0.7 },
                    { headerName: 'Comments', field: 'comments', flex: 2 },
                    {
                        headerName: 'ValueOptions',
                        autoHeight: true,
                        field: 'signalNameOptions',
                        flex: 2,
                        cellRenderer: SignalNameOptionRenderer,
                    },
                ],
                defaultColDef: {
                    flex: 1,
                },
            },
            getDetailRowData: (params) => {
                params.successCallback(params.data.signals)
            },
        } as IDetailCellRendererParams<SignalDatabaseFrame, SignalEntry>
    }, [isDarkMode])

    const onSearchChanged = useCallback((event: any) => {
        gridRef.current?.api.setGridOption('quickFilterText', event.target.value)
        console.log(gridRef.current?.api.getDisplayedRowCount())
    }, [])

    const getBody = () => {
        switch (pageState) {
            case PageState.Loading:
                return <LoadingContainer spinnerSize="sm" />
            case PageState.Loaded:
                return (
                    <div>
                        <div
                            className={`${isDarkMode ? 'ag-theme-balham-dark' : 'ag-theme-balham'} lexend-regular`}
                            style={{ height: '70vh', width: '100%' }}
                        >
                            <AgGridReact
                                ref={gridRef}
                                masterDetail={true}
                                rowData={signalData}
                                columnDefs={colDefs}
                                detailCellRendererParams={detailCellRendererParams}
                                detailRowAutoHeight={true}
                                sideBar={{ toolPanels: ['filters', 'columns'], defaultToolPanel: '' }}
                                includeHiddenColumnsInQuickFilter={true}
                            />
                        </div>
                    </div>
                )
            default:
                return <ErrorContainer errorText="Failed to load signal database data" />
        }
    }

    const demoTextGoBack = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showDemoModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -10, marginTop: 100 }}
                >
                    <div className="d-flex align-items-center">
                        <ArrowWestIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0 fs-6">1. Go here to choose a recording to play</p>
                    </div>
                </div>
            </>
        )
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="d-flex align-items-center remotive-primary-100-color">
                        <SignalDatabasesIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 lexend-light text-truncate m-0">{signalFileName}</p>
                    </div>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        const organisation = props.currentBillableUnit?.organisation
        const project = props.currentProject
        const trail = buAndProjectTrail(organisation, project)
        trail.push({
            title: 'Signal databases /',
            route: ProjectRoutes.toSignalDatabases(props.currentProject?.uid),
        })
        return {
            trail: trail,
            current: {
                title: 'Signal Details',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                zIndexOnTop={showDemoModal}
                isDemoGuideActive={showDemoModal}
                openConfirmationDialog={props.openConfirmationDialog}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                {demoTextGoBack()}
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <SearchInput searchOnChange={onSearchChanged} />
                    <Card
                        className="shadow-sm border-0 remotive-white-background rounded-4 text-start mb-4"
                        style={{ minHeight: '400px' }}
                    >
                        <Card.Body className="pb-3 pt-3">{getBody()}</Card.Body>
                    </Card>
                </div>
            </Container>

            {/* Modals below */}
            <HelpOverlayModalModal
                show={showHelpModal || showDemoModal}
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
            />
        </div>
    )
}
